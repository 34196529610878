/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Result } from '../../../apex';
import {
  addSearchFilter,
  removeSearchFilter
} from '../../../searchFilters/actions';

export default function Results({ results, openModal, profileEndpoint }) {
  function createNumberOfPublicationsLabel(count, adj) {
    const unit = count > 1 ? 'Publications' : 'Publication';
    return `${count} ${adj} ${unit}`;
  }

  const dispatch = useDispatch();

  const selectedFilterObjects = useSelector(
    state => state.searchFilters.filters
  );
  const selectedFilterData = selectedFilterObjects.map(
    element => element.data_value
  );

  const onAddToSearchClick = id => {
    const alreadySelected = selectedFilterData.includes(id);

    const filterItem = {
      data_value: id,
      filter_type: profileEndpoint
    };

    if (!alreadySelected) {
      return dispatch(addSearchFilter(filterItem));
    }
    return dispatch(removeSearchFilter(filterItem));
  };

  const buttonText = id => {
    const alreadySelected = selectedFilterData.includes(id);

    if (!alreadySelected) {
      return 'Add to Search';
    }
    return 'Remove from Search';
  };

  function formatMetadata(result) {
    return (
      <div>
        <span>
          {moment(result.earliest_date).format('MMM YYYY')} -
          {moment(result.latest_date).format('MMM YYYY')}
        </span>
        <span className="results-template__meta-divider" />
        <span>
          {createNumberOfPublicationsLabel(result.num_publications, 'Total')}
        </span>
        <span className="results-template__meta-divider" />
        <span>
          {createNumberOfPublicationsLabel(
            result.highlighted_publications,
            'Matching'
          )}
        </span>
        <div className="results-template__summary-container">
          {result?.description?.map(s => (
            <div key={s}>{s}</div>
          ))}
        </div>
      </div>
    );
  }

  const resultElements =
    results?.map(result => (
      <Result
        onClick={() => openModal(result.id)}
        title={result.title}
        metadata={formatMetadata(result)}
        onAddToSearchClick={() => onAddToSearchClick(result.id)}
        profileEndpoint={profileEndpoint}
        addToSearchButtonText={buttonText(result.id)}
        key={result.id}
      />
    )) || null;

  return resultElements;
}
