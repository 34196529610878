/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function topicsReducer(state = initialState, action) {
  return produce(state, nextState => {
    if (action.error) {
      return nextState;
    }

    switch (action.type) {
      case 'searchId/fetchFulfilled':
      case 'searchId/updateFulfilled': {
        const { response } = action.payload;
        nextState.topics = (response || {}).topics || initialState.topics;
        return nextState;
      }
      case 'searchTopics/add': {
        nextState.topics.push({ id: action.payload.topic });
        return nextState;
      }
      case 'searchTopics/remove': {
        nextState.topics = nextState.topics.filter(
          element => element.id !== action.payload.topic
        );
        return nextState;
      }
      case 'searchTopics/set': {
        const topics = action.payload.topics.map(topic => {
          if (typeof topic === 'string') {
            return { id: topic };
          }
          return topic;
        });
        nextState.topics = topics;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
