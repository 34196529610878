import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowLeftIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M15.14,17.49,9.65,12l5.49-5.49a.37.37,0,0,0,0-.53.39.39,0,0,0-.53,0L8.86,11.73a.38.38,0,0,0,0,.54L14.61,18a.39.39,0,0,0,.27.11.38.38,0,0,0,.26-.11A.37.37,0,0,0,15.14,17.49Z" />
      </svg>
    </div>
  );
}

ArrowLeftIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

ArrowLeftIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
