import base64 from 'crypto-js/enc-base64';
import utf8 from 'crypto-js/enc-utf8';
import initialState from './initialState';

function makeBase64WebSafe(string) {
  return (
    string
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f')
      // eslint-disable-next-line no-control-regex
      .replace(/[\u0000-\u0019]+/g, '')
  );
}

export default function userInfo(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'auth/tokenResponse': {
      // eslint-disable-next-line camelcase
      if (!action.payload.token?.id_token) return state;
      const userPayload = action.payload.token.id_token.split('.');
      if (userPayload.length < 2) return state;
      const wordArray = base64.parse(userPayload[1]);
      if (!wordArray) return state;
      const decodedString = wordArray.toString(utf8);
      if (!decodedString) return state;
      try {
        const tokenData = JSON.parse(makeBase64WebSafe(decodedString));
        const { sub, name, nickname } = tokenData;
        newState = { ...state, name, nickname, userId: sub };
        return newState;
      } catch (e) {
        return state;
      }
    }
    default:
      return state;
  }
}
