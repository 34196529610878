import { Menu as HeadlessUIMenu } from '@headlessui/react';
import React from 'react';
import './menuItem.css';

function MenuItem({ children, onClick }) {
  return (
    <HeadlessUIMenu.Item onClick={onClick}>
      {({ active }) => (
        <li className={`menu-item ${active ? 'menu-item--active' : ''}`}>
          {children}
        </li>
      )}
    </HeadlessUIMenu.Item>
  );
}

export default MenuItem;
