/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { jsx, useTheme } from '@emotion/react';
import { Copy, Paper } from '@artemisintelligence/aperture-component-library';
import savedSearchImage from '../../../resources/img/saved-search.png';

function NoBookmarksDisplay() {
  const theme = useTheme();

  const jss = {
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 28px',
      gap: '48px',
      [theme.breakPoints[1]]: {
        flexDirection: 'column',
        margin: '0px 28px'
      }
    },
    flexWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      gap: '48px',
      padding: '12px 88px 68px 88px',
      [theme.breakPoints[1]]: {
        padding: '20px 40px',
        flexDirection: 'column'
      }
    },
    title: {
      marginBottom: '48px'
    },
    subText: {
      marginBottom: '20px'
    }
  };

  return (
    <Paper borderTop={1} css={jss.flexWrapper}>
      <div>
        <Copy typographyStyle="XL" css={jss.title}>
          You haven’t saved any searches yet
        </Copy>
        <Copy typographyStyle="M" css={jss.subText}>
          Want to make sure you don’t lose track of a valuable search?
        </Copy>
        <Copy typographyStyle="M" css={jss.subText}>
          Click on Save above your entered searches and filters.
        </Copy>
        <Copy typographyStyle="M">
          Then come back here to see your saved searches, and search with them
          again!
        </Copy>
      </div>
      <img src={savedSearchImage} alt="saved search" />
    </Paper>
  );
}

export default NoBookmarksDisplay;
