import React from 'react';
import PropTypes from 'prop-types';

export default function CloseIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M23.67 0.348477C23.4409 0.136506 23.1402 0.0187471 22.828 0.0187471C22.5158 0.0187471 22.2151 0.136506 21.986 0.348477L12.0094 10.317L2.03272 0.348477C1.92215 0.237997 1.79088 0.15036 1.64642 0.0905686C1.50195 0.0307769 1.34711 0 1.19074 0C1.03437 0 0.879531 0.0307769 0.735064 0.0905686C0.590597 0.15036 0.459331 0.237997 0.34876 0.348477C0.23819 0.458958 0.150482 0.590117 0.0906421 0.734467C0.0308019 0.878817 0 1.03353 0 1.18977C0 1.34602 0.0308019 1.50073 0.0906421 1.64508C0.150482 1.78943 0.23819 1.92059 0.34876 2.03107L10.3254 11.9996L0.34876 21.9682C0.136617 22.1971 0.0187623 22.4975 0.0187623 22.8095C0.0187623 23.1214 0.136617 23.4219 0.34876 23.6508C0.580564 23.8709 0.886887 23.9956 1.20663 24C1.51666 23.9939 1.81245 23.8689 2.03272 23.6508L12.0094 13.6822L21.986 23.6508C22.2063 23.8689 22.5021 23.9939 22.8121 24C23.1319 23.9956 23.4382 23.8709 23.67 23.6508C23.8821 23.4219 24 23.1214 24 22.8095C24 22.4975 23.8821 22.1971 23.67 21.9682L13.6933 11.9996L23.67 2.03107C23.8821 1.80219 24 1.50173 24 1.18977C24 0.877817 23.8821 0.577356 23.67 0.348477V0.348477Z" />
      </svg>
    </div>
  );
}

CloseIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

CloseIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
