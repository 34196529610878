import PropTypes from 'prop-types';
import React from 'react';
import './link.css';

const Link = ({ href, target, children, className, ...other }) => (
  <a
    href={href}
    target={target}
    rel="noreferrer"
    className={`link ${className}`}
    {...other}
  >
    {children}
  </a>
);

Link.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

Link.defaultProps = {
  href: undefined,
  target: '_blank',
  children: undefined,
  className: undefined
};

export default Link;
