import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function Meta({ id }) {
  const profile = useSelector(state => state.resultProfile.byId?.[id]);
  const unit = profile?.total > 1 ? 'Publications' : 'Publication';

  const formattedDate = (earliestDate, latestDate) => {
    const formattedEarliestDate = moment(earliestDate).format('MMM YYYY');
    const formattedLatestDate = moment(latestDate).format('MMM YYYY');

    if (!earliestDate) {
      return formattedLatestDate;
    }

    return `${formattedEarliestDate} - ${formattedLatestDate}`;
  };

  return (
    <div className="result-profile__meta">
      <div>{`${profile?.total} Total ${unit}`}</div>
      <span className="results-template__meta-divider" />
      <div>{formattedDate(profile?.earliest_date, profile?.latest_date)}</div>
    </div>
  );
}
