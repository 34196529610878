import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Filter,
  BrowsedFilter
} from '@artemisintelligence/aperture-component-library';
import {
  addSearchFilter,
  removeSearchFilter
} from '../../../searchFilters/actions';
// We're using a recursive technique here, the import is cyclical but the use
// is conditional.
// eslint-disable-next-line import/no-cycle
import TopicFilterElements from './topicFilterElements';

export default function FilterElements({ filters, topLevelId }) {
  const selectedFilterObjects = useSelector(
    state => state.searchFilters.filters
  );
  const selectedFilterData = selectedFilterObjects.map(
    element => element.data_value
  );
  const [browsedId, setBrowsedId] = useState(null);

  const dispatch = useDispatch();

  const browsedFilter = useSelector(state => state.topics.byId[browsedId]);

  if (browsedId) {
    return (
      <>
        <BrowsedFilter
          count={browsedFilter?.doc_count}
          label={browsedFilter?.humanKey}
          onArrowClick={() => setBrowsedId(null)}
        />
        <TopicFilterElements topicId={browsedId} topLevelId={topLevelId} />
      </>
    );
  }

  const onCheck = (alreadyChecked, dataValue, filterType) => {
    const filterItem = {
      data_value: dataValue,
      filter_type: filterType
    };
    if (!alreadyChecked) {
      return dispatch(addSearchFilter(filterItem));
    }
    return dispatch(removeSearchFilter(filterItem));
  };

  const filterElements = filters.map(filter => {
    const checked = selectedFilterData.includes(filter.id);
    return (
      <Filter
        key={filter.id}
        percentageFilled={filter.doc_count * 100}
        label={filter.humanKey}
        onArrowClick={() => setBrowsedId(filter.id)}
        onHoverZoneClick={() => onCheck(checked, filter.id, topLevelId)}
        checkboxChecked={checked}
        browsable={filter.hasChildren}
        style={{ margin: '0px 5px 10px 5px' }}
      />
    );
  });

  return filterElements;
}
