import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '../../../apex';

export default function Organizations({ id }) {
  const organizations = useSelector(
    state => state.resultProfile.byId?.[id]?.organizations
  );

  if (organizations === undefined) {
    return null;
  }

  const organizationsSorted = [...organizations].sort(function(a, b) {
    return b.count - a.count;
  });

  const organizationNames = organizationsSorted
    ?.map(function(organization) {
      return organization.name;
    })
    .slice(0, 25);

  const organizationCounts = organizationsSorted
    ?.map(function(organization) {
      return organization.count;
    })
    .slice(0, 25);

  return (
    <>
      <div className="result-profile__bar-chart-title">Organizations</div>
      <div className="result-profile__bar-chart-subtitle">
        By Number of Mentions
      </div>
      <BarChart
        seriesName="# of publications"
        seriesColorPalette="status3/light"
        xAxisCategories={organizationNames}
        data={organizationCounts}
      />
    </>
  );
}
