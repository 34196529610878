import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeSearchTopic,
  setSearchTopics
} from '../../../searchTopics/actions';
import {
  Tag,
  Badge,
  Button,
  Accordian,
  AccordianDetails,
  AccordianSummary
} from '../../../apex';
import '../css/appliedSearchDisplay.css';

function AppliedSearchDisplay() {
  const dispatch = useDispatch();

  const searchTopics = useSelector(state => state.searchTopics?.topics);

  if (!searchTopics) {
    return null;
  }

  const totalSearchTopicsCount = searchTopics?.length || 0;

  const conditions = [
    { label: 'All', value: 'and', tagColor: 'green' },
    { label: 'Any', value: 'or', tagColor: 'yellow' },
    { label: 'Not', value: 'exclude', tagColor: 'red' }
  ];

  function getSearchTopicsByCondition(condition) {
    return searchTopics.filter(e => e.condition === condition.value);
  }

  function renderTagsByCondition(condition) {
    const searchTopicsByCondition = getSearchTopicsByCondition(condition);
    return searchTopicsByCondition.slice(0, 6).map(searchTopic => {
      const { humanKey } = searchTopic;
      if (!humanKey) {
        return null;
      }

      return (
        <Tag
          key={searchTopic.id}
          color={condition.tagColor}
          type="filled"
          onClose={() => dispatch(removeSearchTopic(searchTopic.id))}
        >
          {humanKey}
        </Tag>
      );
    });
  }

  function renderShowMoreLessTagsByCondition(condition) {
    const searchTopicsByCondition = getSearchTopicsByCondition(condition);
    return searchTopicsByCondition.slice(6).map(searchTopic => {
      const { humanKey } = searchTopic;
      if (!humanKey) {
        return null;
      }

      return (
        <Tag
          key={searchTopic.id}
          color={condition.tagColor}
          type="filled"
          onClose={() => dispatch(removeSearchTopic(searchTopic.id))}
        >
          {humanKey}
        </Tag>
      );
    });
  }

  if (totalSearchTopicsCount === 0) {
    return null;
  }
  return (
    <div className="applied-search-display__outer-container">
      <div className="applied-search-display__title-container ">
        <div className="applied-search-display__title">Search</div>
        <div className="applied-search-display__badge-and-button">
          <Button
            size="small"
            type="text"
            onClick={() => dispatch(setSearchTopics([]))}
          >
            Clear
          </Button>
          <Badge type="filled">{totalSearchTopicsCount}</Badge>
        </div>
      </div>
      <>
        {conditions.map(condition => {
          const searchTopicsByCondition = getSearchTopicsByCondition(condition);

          return searchTopicsByCondition.length > 0 ? (
            <div className="applied-search-display__content-container">
              <div className="applied-search-display__condition-label">
                {condition.label}
              </div>
              <div className="applied-search-display__tag-container">
                {renderTagsByCondition(condition)}
              </div>
              {searchTopicsByCondition.length > 6 ? (
                <Accordian defaultOpen={false}>
                  {({ open }) => (
                    <>
                      <AccordianDetails>
                        <div className="applied-search-display__tag-container">
                          {renderShowMoreLessTagsByCondition(condition)}
                        </div>
                      </AccordianDetails>
                      <AccordianSummary
                        open={open}
                        className="applied-search-display__show-hide"
                      >
                        {open
                          ? 'Show fewer search topics'
                          : `Show ${searchTopicsByCondition.length - 6} more ${
                              searchTopicsByCondition.length - 6 > 1
                                ? 'search topics'
                                : 'search topic'
                            }`}
                      </AccordianSummary>
                    </>
                  )}
                </Accordian>
              ) : null}
            </div>
          ) : null;
        })}
      </>
    </div>
  );
}

export default AppliedSearchDisplay;
