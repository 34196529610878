import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Badge,
  Menu,
  MobileMenu,
  Icon
} from '@artemisintelligence/aperture-component-library';
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useSearchParams
} from 'react-router-dom';
import moment from 'moment';
import FiltersGroup from './organisms/filtersGroup';
import AppliedFiltersDisplay from './organisms/appliedFiltersDisplay';
import AppliedSearchDisplay from './organisms/appliedSearchDisplay';
import Spaces from './organisms/spaces';
import './css/resultsTemplate.css';
import PrimaryTabs from './organisms/primaryTabs';
import { Modal, Button as NewButton } from '../../apex';
import { getResults } from '../../resultsDisplay/actions';
import {
  addBookmark,
  deleteBookmark,
  fetchBookmarks
} from '../../bookmarks/actions';
import { fetchAvaliableSpaces } from '../../spaces/actions';
import { getSearchId } from '../../searchId/actions';
import SearchIdNavigation from '../../searchId/searchIdNavigation';
import NoResultsDisplay from './organisms/noResultsDisplay';
import ListResults from './organisms/listResults';
import NonPublicationResults from './organisms/nonPublicationResults';
import DateFilter from './organisms/dateFilter';
import Explore from './organisms/explore';
import ListResultsSort from './organisms/listResultsSort';
import ListResultsPaginationBar from './organisms/listResultsPaginationBar';
import { resetSearchFilters } from '../../searchFilters/actions';
import { setSearchTopics } from '../../searchTopics/actions';
import { getBubbleVisualizationOptions } from '../../bubbleVisualizationOptions/actions';

export default function ResultsTemplate() {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeletModalVisible] = useState(false);

  const [bookmarkName, setBookmarkName] = useState();

  const dispatch = useDispatch();

  const { searchId } = useParams();

  useEffect(() => {
    dispatch(fetchBookmarks());
    dispatch(fetchAvaliableSpaces());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSearchId(searchId));
  }, [dispatch, searchId]);

  const [searchParams] = useSearchParams();
  const pageId = searchParams.get('page');
  const page = parseInt(pageId, 10) || 1;

  const problemsTotal = useSelector(
    state => state.results.bySearch[searchId]?.problems?.total
  );

  const mentionsStatus = useSelector(
    state =>
      state.results.bySearch[searchId]?.publications?.pages?.[page]?._status
  );

  const mentionsTotal = useSelector(
    state => state.results.bySearch[searchId]?.publications?.total
  );

  useEffect(() => {
    if (!mentionsStatus || mentionsStatus === 'initial') {
      dispatch(getResults({ searchId, page, resultsType: 'publications' }));
    }
  }, [dispatch, mentionsStatus, page, searchId]);

  const noResults = problemsTotal === 0 && mentionsTotal === 0;

  const savedBookmarks = useSelector(
    state => state.bookmarksReducer.savedBookmarks
  );

  const isBookmarked =
    savedBookmarks.filter(bookmark => bookmark.searchId === searchId).length >
    0;

  useEffect(() => {
    const bookMarkName = savedBookmarks?.find(
      bookmark => bookmark.searchId === searchId
    )?.name;

    setBookmarkName(bookMarkName);
  }, [savedBookmarks]);

  const filters = useSelector(state => {
    return state.searchFilters?.filters;
  });

  const filtersCount = filters?.length || 0;

  const searchTopics = useSelector(state => state.searchTopics?.topics);

  const searchTopicsCount = searchTopics?.length || 0;

  function clearAll() {
    dispatch(resetSearchFilters());
    dispatch(setSearchTopics([]));
  }

  function onOkayClick() {
    setSaveModalVisible(false);
  }

  function onDeleteBookmarkCancel() {
    setDeletModalVisible(false);
  }
  function onConfirmDeleteBookmarkClick() {
    const bookmarkKey = savedBookmarks.find(
      bookmark => bookmark.searchId === searchId
    ).key;
    dispatch(deleteBookmark({ bookmarkKey }));
    setDeletModalVisible(false);
  }

  function toggleBookmark(useModal) {
    if (isBookmarked) {
      if (useModal) {
        setDeletModalVisible(true);
      } else {
        onConfirmDeleteBookmarkClick();
      }
    } else {
      const zone = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      const name = moment().format('DD MMM YYYY - HH:MM:SS ') + zone;
      dispatch(addBookmark({ searchId, name }));
      if (useModal) {
        setSaveModalVisible(true);
      }
    }
  }

  const topLevelFilters = [
    // 'industries',
    // 'megatrends',
    // 'microtrends',
    // 'product_types',
    'applications',
    'painpoints',
    'organizations',
    'corpus'
  ];

  useEffect(() => {
    dispatch(getBubbleVisualizationOptions());
  }, [dispatch]);

  function renderResults() {
    if (noResults) {
      return <NoResultsDisplay />;
    }
    return (
      <>
        <Routes>
          <Route path="/" element={<Navigate to="explore" replace />} />
          <Route path="explore*" element={<Explore />} />
          {/* <Route
            path="results*"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <NonPublicationResults />
              </div>
            }
          /> */}
          <Route
            path="mentions*"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <ListResultsSort resultsType="publications" />
                <ListResultsPaginationBar
                  position="top"
                  resultsType="publications"
                />

                <ListResults resultsType="publications" />
                <ListResultsPaginationBar
                  position="bottom"
                  resultsType="publications"
                />
              </div>
            }
          />
          <Route
            path="topics*"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <NonPublicationResults
                  defaultResultSubTypeId="products"
                  resultType={{
                    name: 'Topics',
                    value: 'applications'
                  }}
                  subTypeOptions={[
                    { name: 'Products', value: 'products' },
                    {
                      name: 'Materials',
                      value: 'materials'
                    },
                    {
                      name: 'Processes',
                      value: 'processes'
                    },
                    { name: 'Pain Points', value: 'Pain Points' },
                    { name: 'Other', value: 'others' }
                  ]}
                />
              </div>
            }
          />

          <Route
            path="organizations*"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <NonPublicationResults
                  resultType={{
                    name: 'Organizations',
                    value: 'organizations'
                  }}
                  resultTypeId="organizations"
                  defaultResultSubTypeId="Industry"
                  subTypeOptions={[
                    { name: 'Industry', value: 'Industry' },
                    {
                      name: 'Academic',
                      value: 'Academic'
                    },
                    { name: 'Other', value: 'others' }
                  ]}
                />
              </div>
            }
          />

          <Route
            path="opportunities*"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <NonPublicationResults
                  resultType={{
                    name: 'Opportunities',
                    value: 'problems'
                  }}
                  resultTypeId="problems"
                  defaultResultSubTypeId={null}
                  subTypeOptions={[]}
                  subTypeIdFilteringEnabled={false}
                />
              </div>
            }
          />

          <Route
            path="painpoints"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="results-template__inner-results-container">
                <NonPublicationResults
                  resultType={{
                    name: 'Pain Points',
                    value: 'painpoints'
                  }}
                  resultTypeId="painpoints"
                  defaultResultSubTypeId={null}
                  subTypeOptions={[]}
                  subTypeIdFilteringEnabled={false}
                />
              </div>
            }
          />
        </Routes>
      </>
    );
  }

  return (
    <>
      <Modal
        title="Search Saved"
        width={450}
        visible={saveModalVisible}
        footer={
          <NewButton type="primary" onClick={onOkayClick}>
            Okay
          </NewButton>
        }
        onMaskClick={() => setSaveModalVisible(false)}
      >
        <div>
          <div>Your search has been saved as:</div>
          <div className="results-template__bookmark-name">{bookmarkName}</div>
        </div>
      </Modal>
      <Modal
        title="Delete Save"
        width={450}
        visible={deleteModalVisible}
        footer={
          <>
            <NewButton type="secondary" onClick={onDeleteBookmarkCancel}>
              Cancel
            </NewButton>
            <NewButton type="primary" onClick={onConfirmDeleteBookmarkClick}>
              Delete
            </NewButton>
          </>
        }
        onMaskClick={() => setSaveModalVisible(false)}
      >
        Are you sure you want to delete this saved search?
      </Modal>
      <SearchIdNavigation />
      <div className="results-template__outer-container">
        <Menu
          collapsed={menuCollapsed}
          className="results-template__desktop-menu"
          data-id="results-menu"
        >
          {menuCollapsed ? (
            <div className="results-template__desktop-menu-inner-container">
              <Button
                label=""
                startIconType="filter"
                type="text"
                iconColor={menuCollapsed ? 'primary/regular' : 'secondary/900'}
                onClick={() => setMenuCollapsed(!menuCollapsed)}
                className="results-template__desktop-menu-collapsed-toggle-button "
              />
              {searchTopicsCount > 0 ? (
                <Badge
                  label={searchTopicsCount}
                  className="results-template__collapsed-menu-badge"
                  onClick={() => setMenuCollapsed(!menuCollapsed)}
                  style={{ margin: 'auto' }}
                />
              ) : null}

              {filtersCount > 0 ? (
                <Badge
                  label={filtersCount}
                  className="results-template__collapsed-menu-badge"
                  onClick={() => setMenuCollapsed(!menuCollapsed)}
                  color="background/regular"
                  type="outlined"
                  style={{ margin: 'auto' }}
                />
              ) : null}
            </div>
          ) : null}

          {!menuCollapsed ? (
            <div>
              <div
                className="results-template__desktop-menu-expanded-header-container"
                color="background/regular"
              >
                <Button
                  startIconType="filter"
                  typographyStyle="MC"
                  type="text"
                  iconColor={
                    menuCollapsed ? 'primary/regular' : 'secondary/900'
                  }
                  onClick={() => setMenuCollapsed(!menuCollapsed)}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {filtersCount + searchTopicsCount > 0 ? (
                    <>
                      <Button
                        startIconType="star"
                        iconSize="small"
                        typographyStyle="XXS"
                        type="text"
                        label={isBookmarked ? 'Saved' : 'Save'}
                        textColor={
                          isBookmarked ? 'secondary/900' : 'primary/dark'
                        }
                        iconColor={
                          isBookmarked ? 'secondary/900' : 'primary/dark'
                        }
                        onClick={() => toggleBookmark(true)}
                      />
                      <div className="results-template__divider" />
                      <Button
                        label="Clear All"
                        type="text"
                        typographyStyle="XXS"
                        iconColor="primary/dark"
                        textColor="primary/dark"
                        tIconType="close"
                        iconSize="small"
                        onClick={clearAll}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              <Spaces />
              <AppliedSearchDisplay />
              <AppliedFiltersDisplay />
              {topLevelFilters.map(element => (
                <FiltersGroup
                  topicId={element}
                  key={element}
                  topLevelId={element}
                />
              ))}
              <DateFilter topicId="date" />
            </div>
          ) : null}
        </Menu>
        {!menuCollapsed ? (
          <MobileMenu
            className="results-template__mobile-menu"
            collapsed={menuCollapsed}
            onBackgroundClick={() => setMenuCollapsed(true)}
          >
            <div className="results-template__mobile-menu-scroll-container">
              <div className="results-template__mobile-menu-header-container">
                <Icon
                  type="closeNoCircle"
                  color="primary/dark"
                  clickable
                  size="large"
                  className="results-template__mobile-menu-close-icon"
                  onClick={() => setMenuCollapsed(true)}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {filtersCount + searchTopicsCount > 0 ? (
                    <>
                      <Button
                        startIconType="star"
                        iconSize="small"
                        typographyStyle="XXS"
                        type="text"
                        label={isBookmarked ? 'Saved' : 'Save'}
                        textColor={
                          isBookmarked ? 'secondary/900' : 'primary/dark'
                        }
                        iconColor={
                          isBookmarked ? 'secondary/900' : 'primary/dark'
                        }
                        onClick={() => toggleBookmark(false)}
                      />
                      <div className="results-template__divider" />
                      <Button
                        label="Clear All"
                        type="text"
                        typographyStyle="XXS"
                        iconColor="primary/regular"
                        textColor="primary/regular"
                        startIconType="close"
                        iconSize="small"
                        onClick={clearAll}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              <Spaces />
              <AppliedSearchDisplay />
              <AppliedFiltersDisplay />
              {topLevelFilters.map(element => (
                <FiltersGroup
                  topicId={element}
                  key={element}
                  topLevelId={element}
                />
              ))}
              <DateFilter topicId="date" />
            </div>
            {problemsTotal ? (
              <div className="results-template__see-results-button-container">
                <Button
                  onClick={() => setMenuCollapsed(true)}
                  textColor="primary/light"
                  label={`Show ${problemsTotal} Results`}
                />
              </div>
            ) : null}
          </MobileMenu>
        ) : null}
        <div className="results-template__container">
          <div className="results-template__mobile-menu-controls">
            <Button
              label=""
              startIconType="filter"
              type="text"
              iconColor={menuCollapsed ? 'primary/regular' : 'secondary/900'}
              onClick={() => setMenuCollapsed(!menuCollapsed)}
              className="results-template__desktop-mobile-collapsed-toggle-button "
            />
            {filtersCount > 0 ? (
              <Badge
                label={filtersCount}
                className="results-template__collapsed-menu-badge"
                style={{ marginRight: '10px' }}
                onClick={() => setMenuCollapsed(!menuCollapsed)}
                color="background/regular"
                type="outlined"
              />
            ) : null}
            {searchTopicsCount > 0 ? (
              <Badge
                onClick={() => setMenuCollapsed(!menuCollapsed)}
                label={searchTopicsCount}
                className="results-template__collapsed-menu-badge"
              />
            ) : null}
          </div>
          <PrimaryTabs />
          <div className="results-template__shadow-box" />
          {renderResults()}
        </div>
      </div>
    </>
  );
}
