import { combineEpics, ofType } from 'redux-observable';
import { interval } from 'rxjs';
import { debounce, filter, switchMap } from 'rxjs/operators';
import { apiRequestObservable } from '../api/observable';

const fetchTopicAutoCompleteOptionsEpic = (action$, state$) =>
  action$.pipe(
    ofType('topicAutoComplete/fetchOptions'),
    debounce(() => interval(250)),
    filter(({ payload }) => payload.searchTerm.length >= 3),
    switchMap(({ payload }) => {
      const { searchTerm } = payload;
      return apiRequestObservable(
        {
          path: `/autocomplete/ontology?query=${searchTerm}`,
          fulfilledType: 'topicAutoComplete/fetchOptionsFulfilled',
          metadata: { sourcePayload: payload }
        },
        state$
      );
    })
  );

export default combineEpics(fetchTopicAutoCompleteOptionsEpic);
