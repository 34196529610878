import React from 'react';
import { useSelector } from 'react-redux';

export default function Title({ id }) {
  const title = useSelector(state => state.resultProfile.byId?.[id]?.title);

  return (
    <h1 className="result-profile__title">
      {title?.text ? title?.text : 'No title available'}
    </h1>
  );
}
