import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';
// eslint-disable-next-line import/prefer-default-export
export const fetchResultsEpic = action$ =>
  action$.pipe(
    ofType('results/fetch'),
    map(({ payload }) => {
      const { searchId, start, size, resultsType } = payload;
      return apiRequest({
        path: `/results/${searchId}/${resultsType}?start=${start}&size=${size}`,
        fulfilledType: 'results/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );
