import React from 'react';
import PropTypes from 'prop-types';

export default function PrintIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M6.542 18.415H.98V7.5h22.042v10.915h-5.564M6.542 7.395V1.938h10.915v5.457"
        />
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M17.404 14.227H6.542v6.888h10.862v-6.888Zm-1.801 2.23H8.397m7.206 2.433H8.397"
        />
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M20.106 11.686a1.113 1.113 0 1 0 0-2.225 1.113 1.113 0 0 0 0 2.225Z"
        />
      </svg>
    </div>
  );
}

PrintIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

PrintIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
