/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function searchReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchId/updateFulfilled': {
        if (action.error) {
          return nextState;
        }

        const { response } = action.payload;
        nextState.transitionId =
          (response || {}).id || initialState.transitionId;
        return nextState;
      }
      case 'searchId/transitionUrl': {
        const { id } = action.payload;

        if (id?.toString() !== state.transitionId?.toString()) {
          return nextState;
        }

        nextState.transitionId = null;

        return nextState;
      }

      case 'searchId/setSearchId': {
        const { id } = action.payload;
        nextState.id = id;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
