/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function bookmarksReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'bookmarks/add': {
        nextState._status = 'loading';
        return nextState;
      }
      case 'bookmarks/addFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          return nextState;
        }
        const status = action.error ? 'failure' : 'success';
        nextState._status = status;
        return nextState;
      }
      case 'bookmarks/delete': {
        nextState._status = 'loading';
        return nextState;
      }
      case 'bookmarks/deleteFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          return nextState;
        }
        const status = action.error ? 'failure' : 'success';
        nextState._status = status;
        return nextState;
      }
      case 'bookmarks/fetch': {
        nextState._status = 'loading';
        return nextState;
      }
      case 'bookmarks/fetchFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          return nextState;
        }

        const { response } = action.payload;
        const status = action.error ? 'failure' : 'success';
        nextState.savedBookmarks = response?.documents?.map(x => {
          return { key: x._key, name: x.name, searchId: x.search.id };
        });
        nextState._status = status;
        return nextState;
      }
      case 'bookmarks/fetchPaginatedBookmarks': {
        const { page } = action.payload;

        nextState.savedBookmarksPaginated.pages[page] ??= {};
        nextState.savedBookmarksPaginated.pages[page]._status = 'loading';
        return nextState;
      }
      case 'bookmarks/fetchPaginatedBookmarksFulfilled': {
        const { page } = action.payload.metadata.sourcePayload;

        nextState.savedBookmarksPaginated.pages[page] ??= {};
        nextState.savedBookmarksPaginated.pages[page]._status = 'loading';

        if (action.error) {
          nextState.savedBookmarksPaginated.pages[page]._status = 'failure';
          return nextState;
        }

        const { total, start, documents, size } = action.payload.response;
        const status = action.error ? 'failure' : 'success';

        nextState.savedBookmarksPaginated.total = total;
        nextState.savedBookmarksPaginated.pages[page].size = size;
        nextState.savedBookmarksPaginated.pages[page].start = start;
        nextState.savedBookmarksPaginated.pages[page].documents = documents;
        nextState.savedBookmarksPaginated.pages[page]._status = status;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
