/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { jsx, useTheme } from '@emotion/react';
import {
  Button,
  Copy,
  ExpandCollapse
} from '@artemisintelligence/aperture-component-library';
import { useNavigate } from 'react-router-dom';

function BookmarkDisplay({
  name,
  all,
  any,
  not,
  filters,
  startDate,
  endDate,
  sort,
  searchId,
  deleteBookmark
}) {
  const theme = useTheme();

  const jss = {
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 28px',
      gap: '48px',
      [theme.breakPoints[1]]: {
        flexDirection: 'column',
        margin: '0px 28px'
      }
    },
    category: {
      flex: '1'
    },
    categoryDetails: {
      marginTop: '8px',
      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap'
    },
    buttonCategoryDetails: {
      marginTop: '8px',
      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    flexWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '48px',
      flexDirection: 'column',
      margin: '28px 0'
    }
  };

  const navigate = useNavigate();

  function renderList(list) {
    if (list.length === 0) return '-';

    return list.map(a => (
      <Copy
        element="div"
        typographyStyle="MM"
        color="copy/regular"
        css={jss.noWrap}
        key={a.id}
      >
        {a.humanKey}
      </Copy>
    ));
  }
  return (
    <ExpandCollapse
      expandedTitle={name}
      collapsedTitle={name}
      defaultCollapsed={false}
      size="large"
    >
      <div css={jss.flexWrapper}>
        <div css={jss.row}>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              All
            </Copy>
            <div css={jss.categoryDetails}>{renderList(all)}</div>
          </div>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              Filters
            </Copy>
            <div css={jss.categoryDetails}>{renderList(filters)}</div>
          </div>
          <div css={jss.category}>
            <div css={jss.buttonCategoryDetails}>
              <Button
                startIconType="search"
                type="text"
                label="Search"
                iconColor="primary/regular"
                onClick={() => navigate(`/${searchId}`)}
              />
              <Button
                startIconType="delete"
                type="text"
                label="Delete"
                iconColor="primary/regular"
                onClick={deleteBookmark}
              />
            </div>
          </div>
        </div>
        <div css={jss.row}>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              Any
            </Copy>
            <div css={jss.categoryDetails}>{renderList(any)}</div>
          </div>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              Dates
            </Copy>
            <div css={jss.categoryDetails}>
              <Copy element="div" typographyStyle="MM" color="copy/regular">
                {startDate && endDate ? `${startDate}-${endDate}` : '-'}
              </Copy>
            </div>
          </div>
          <div css={jss.category} />
        </div>
        <div css={jss.row}>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              Not
            </Copy>
            <div css={jss.categoryDetails}>{renderList(not)}</div>
          </div>
          <div css={jss.category}>
            <Copy element="div" typographyStyle="S" color="copy/light">
              Sort
            </Copy>
            <div css={jss.categoryDetails}>
              <Copy element="div" typographyStyle="MM" color="copy/regular">
                {sort || '-'}
              </Copy>
            </div>
          </div>
          <div css={jss.category} />
        </div>
      </div>
    </ExpandCollapse>
  );
}

export default BookmarkDisplay;
