// eslint-disable-next-line import/prefer-default-export
export function getResults({ searchId, page, size = 20, resultsType }) {
  let start = (page - 1) * size;
  start = start < 0 ? 0 : start;
  return {
    type: 'results/fetch',
    payload: { searchId, start, size, page, resultsType }
  };
}

export function updatedResultsViewId(viewId) {
  return {
    type: 'results/updatedResultsViewId',
    payload: { viewId }
  };
}
