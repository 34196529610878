/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import cloneDeep from 'clone-deep';
import {
  Paper,
  Copy,
  Pagination
} from '@artemisintelligence/aperture-component-library';
import { jsx, useTheme } from '@emotion/react';

export default function PaginationBlock({ position }) {
  const theme = useTheme();

  const jss = {
    paginationBar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '50px',
      height: '50px',
      [theme.breakPoints[0]]: {
        justifyContent: 'flex-end'
      }
    },
    resultCountLabel: {
      textTransform: 'capitalize',
      margin: 'auto 0px',
      [theme.breakPoints[0]]: {
        display: 'none'
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page'), 10) || 1;

  const total = useSelector(
    state => state.bookmarksReducer.savedBookmarksPaginated.total
  );

  const start = useSelector(
    state =>
      state.bookmarksReducer.savedBookmarksPaginated?.pages?.[page]?.start
  );

  const pageSize = useSelector(
    state => state.bookmarksReducer.savedBookmarksPaginated?.pages?.[page]?.size
  );

  const documents = useSelector(
    state =>
      state.bookmarksReducer?.savedBookmarksPaginated?.pages?.[page]?.documents
  );

  const numberOfResultsDisplayed = documents?.length;

  const getTotalLabel = () => {
    const startNumber = parseInt(start, 10);

    if (total === 0 && numberOfResultsDisplayed === 0) {
      return `0 - 0 of 0 Saved Searches`;
    }

    if (
      !((startNumber || startNumber === 0) && total && numberOfResultsDisplayed)
    ) {
      return null;
    }

    return `${startNumber + 1} - ${startNumber + numberOfResultsDisplayed} of
        ${total} Saved Searches`;
  };

  const [componentPage, setComponentPage] = useState(page);

  useEffect(() => {
    setComponentPage(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [componentPage]);

  const totalIntegerResults = parseInt(total, 10);

  const integerSize = parseInt(pageSize, 10);

  const totalPages =
    Math.floor(totalIntegerResults / integerSize) +
    (totalIntegerResults % integerSize ? 1 : 0);

  const setPage = newPage => {
    const newParams = cloneDeep(searchParams);
    newParams.set('page', newPage);
    setSearchParams(newParams);
    setComponentPage(newPage);
  };

  function onNextPageButtonClick() {
    setPage(page + 1);
  }

  function onPreviousPageButtonClick() {
    setPage(page - 1);
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function onPageTextBlur(e) {
    const newPage = e.target.value;
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    } else {
      setComponentPage(page);
    }
  }

  function onPaginationInputChange(e) {
    const newPage = e.target.value;
    setComponentPage(newPage);
  }

  const dataId = `bookmarks-pagination-bar-${position}`;

  if (numberOfResultsDisplayed === 0) {
    return null;
  }

  return (
    <Paper
      borderTop={1}
      borderBottom={1}
      css={jss.paginationBar}
      data-id={dataId}
    >
      <Copy
        typographyStyle="S"
        element="span"
        color="copy/light"
        css={jss.resultCountLabel}
      >
        {getTotalLabel()}
      </Copy>
      {totalPages >= 0 ? (
        <Pagination
          total={totalPages}
          inputValue={componentPage}
          onInputKeyDown={onKeyDown}
          onInputChange={onPaginationInputChange}
          onInputBlur={onPageTextBlur}
          onPreviousClick={onPreviousPageButtonClick}
          onNextClick={onNextPageButtonClick}
          nextDisabled={componentPage === totalPages}
          previousDisabled={componentPage === 1}
        />
      ) : null}
    </Paper>
  );
}
