import PropTypes from 'prop-types';
import React from 'react';
import './input.css';

const Input = ({ value, onChange, onBlur, className, ...other }) => (
  <input
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    className={`input ${className}`}
    {...other}
  />
);

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string
};

Input.defaultProps = {
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  className: undefined
};

export default Input;
