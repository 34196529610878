import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { transitionUrlFromSearchId, setSearchId } from './actions';

// Note: This entire component is a workaround to react-router not picking up
// url changes that take place out of the React App

function TransitionIdNavigation() {
  const transitionSearchId = useSelector(state => state.searchId?.transitionId);
  const { searchId } = useParams();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    if (transitionSearchId?.toString() === searchId.toString()) {
      dispatch(transitionUrlFromSearchId(transitionSearchId));
    }
  }, [dispatch, searchId, transitionSearchId]);

  if (!transitionSearchId) {
    return null;
  }

  if (transitionSearchId.toString() === searchId.toString()) {
    return null;
  }

  const regexp = RegExp(`^/${searchId}`);
  const newPath = pathname.replace(regexp, `/${transitionSearchId}`);
  return <Navigate to={newPath} />;
}

function UrlSearchIdStateUpdate() {
  const id = useSelector(state => state.searchId?.id);
  const { searchId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (id?.toString() !== searchId.toString()) {
      dispatch(setSearchId(searchId));
    }
  }, [dispatch, searchId, id]);

  return null;
}

export default function SearchIdNavigation() {
  return (
    <>
      <UrlSearchIdStateUpdate />
      <TransitionIdNavigation />
    </>
  );
}
