import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

// eslint-disable-next-line import/prefer-default-export
export const fetchAvaliableSpacesEpic = action$ =>
  action$.pipe(
    ofType('spaces/fetch'),
    map(({ payload }) => {
      return apiRequest({
        path: `/permissions/datasets`,
        fulfilledType: 'spaces/fetchFulfilled',
        metadata: { sourcePayload: payload },
        method: 'GET'
      });
    })
  );
