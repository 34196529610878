import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete } from '../../../apex';
import {
  resetTopicAutoCompleteOptions,
  getTopicAutoCompleteOptions
} from '../../../topicAutoComplete/actions';
import { setSearchTopics } from '../../../searchTopics/actions';
import { setSearchId } from '../../../searchId/actions';

export default function TopicAutocomplete() {
  const dispatch = useDispatch();

  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectValue, setSelectValue] = useState('and');

  const [isOpen, setIsOpen] = useState(false);

  const transitionId = useSelector(state => state.searchId?.transitionId);
  const searchId = useSelector(state => state.searchId?.id);
  const location = useLocation();
  const navigate = useNavigate();

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  useEffect(() => {
    if (
      transitionId &&
      !location.pathname.includes(transitionId) &&
      !location.pathname.includes(searchId)
    ) {
      navigate(`/${transitionId}`);
    }
  }, [transitionId, location, navigate, searchId]);

  const options = useSelector(state => state.topicAutoComplete.options);
  const status = useSelector(state => state.topicAutoComplete._status);

  const searchTopics = useSelector(state => state.searchTopics?.topics);

  const getOptions = () => {
    const filteredOptions = options.filter(
      x => !searchTopics.filter(y => y.id === x.id).length
    );
    const formattedOptions = filteredOptions.map(option => ({
      label: option.humanKey,
      value: option.id,
      id: option.id
    }));
    return formattedOptions;
  };

  const onOptionClicked = option => {
    setAutocompleteValue('');
    setIsOpen(false);
    const newSearchTopics = [
      ...searchTopics,
      {
        humanKey: option.label,
        id: option.value,
        condition: selectValue,
        weight: option.weight,
        topic_type: option.topic_type
      }
    ];
    dispatch(setSearchTopics(newSearchTopics));
    dispatch(resetTopicAutoCompleteOptions());
  };

  const onClear = () => {
    setAutocompleteValue('');
    dispatch(resetTopicAutoCompleteOptions());
  };

  const onClickOff = () => {
    setAutocompleteValue('');
    dispatch(resetTopicAutoCompleteOptions());
    setIsOpen(false);
  };

  const onFocus = () => {
    setIsOpen(true);
  };

  const onShowAllButtonClick = () => {
    setIsOpen(false);
    dispatch(setSearchId('default'));
    dispatch(setSearchTopics([]));
    navigate('/default/results');
  };

  function onTextFieldChange(newValue) {
    setIsOpen(true);
    setAutocompleteValue(newValue);
    debounce(dispatch(getTopicAutoCompleteOptions(newValue)));
  }

  return (
    <Autocomplete
      inputPlaceholderText="Type to select keywords or phrases"
      onOptionClicked={onOptionClicked}
      onClear={onClear}
      onInputChange={e => onTextFieldChange(e.target.value.toLowerCase())}
      inputValue={autocompleteValue}
      isOpen={isOpen}
      options={getOptions()}
      onInputFocus={onFocus}
      onClickOff={onClickOff}
      onShowAllButtonClick={onShowAllButtonClick}
      toggleFlyoutOptions={[
        { name: 'All', value: 'and' },
        { name: 'Any', value: 'or' },
        { name: 'Not', value: 'exclude' }
      ]}
      toggleFlyoutValue={selectValue}
      toggleFlyoutOnOptionClicked={e => setSelectValue(e)}
      optionsLabel="Include"
      isLoading={status === 'loading' && autocompleteValue.length >= 3}
      data-id="topic-autocomplete"
    />
  );
}
