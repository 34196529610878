/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function resultsReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'bubbleVisualization/fetch': {
        const { topicId, x, y, z } = action.payload;
        nextState.x = x;
        nextState.y = y;
        nextState.z = z;
        nextState.topicId = topicId;
        nextState._status = 'loading';
        return nextState;
      }
      case 'bubbleVisualization/fetchFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          nextState.errorMessage = action.payload?.message;
          nextState.x = initialState.x;
          nextState.y = initialState.y;
          nextState.z = initialState.z;
          nextState.topicId = initialState.topicId;
          return nextState;
        }
        const { response } = action.payload;
        nextState.data = response.data;
        const status = action.error ? 'failure' : 'success';
        nextState._status = status;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
