import { ofType, combineEpics } from 'redux-observable';
import { mapTo } from 'rxjs/operators';

export const queueSearchUpdate = action$ =>
  action$.pipe(
    ofType('searchFilters/add', 'searchFilters/remove', 'searchFilters/reset'),
    mapTo({ type: 'search/update' })
  );

export default combineEpics(queueSearchUpdate);
