import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, BackIcon, Button } from '../../../apex';
import {
  addSearchFilter,
  removeSearchFilter
} from '../../../searchFilters/actions';

export default function Subtitle({ id, profileType, profileEndpoint }) {
  const internalOrigin = useLocation().state?.internalOrigin;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedFilterObjects = useSelector(
    state => state.searchFilters.filters
  );
  const selectedFilterData = selectedFilterObjects.map(
    element => element.data_value
  );

  const alreadySelected = selectedFilterData.includes(id);

  const onClick = () => {
    const filterItem = {
      data_value: id,
      filter_type: profileEndpoint
    };

    if (!alreadySelected) {
      return dispatch(addSearchFilter(filterItem));
    }
    return dispatch(removeSearchFilter(filterItem));
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="result-profile__title-button">
        <div className="result-profile__subtitle-container">
          {internalOrigin ? (
            <IconButton onClick={() => navigate(-1)}>
              <BackIcon />
            </IconButton>
          ) : null}
          <h2 className="result-profile__subtitle">{profileType}</h2>
        </div>
        <div>
          <Button onClick={onClick}>
            {!alreadySelected ? 'Add to Search' : 'Remove from Search'}
          </Button>
        </div>
      </div>
    </div>
  );
}
