import React from 'react';
import PropTypes from 'prop-types';

export default function DownIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z" />
      </svg>
    </div>
  );
}

DownIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

DownIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
