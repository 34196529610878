export function addSearchFilter(filterObj) {
  return {
    type: 'searchFilters/add',
    payload: filterObj
  };
}

export function removeSearchFilter(filterObj) {
  return {
    type: 'searchFilters/remove',
    payload: filterObj
  };
}

export function resetSearchFilters() {
  return { type: 'searchFilters/reset' };
}
