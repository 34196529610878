export function tokenRequest() {
  return { type: 'auth/tokenRequest' };
}

export function tokenResponse(token) {
  return {
    type: 'auth/tokenResponse',
    payload: { token }
  };
}
