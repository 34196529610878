import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  AccordianDetails,
  Accordian,
  AccordianSummary,
  Link
} from '../../../apex';
import '../css/publicationTagsBreakdown.css';

export default function PublicationTagsBreakdown({ id, corpus }) {
  const _document = useSelector(state => {
    return state.documentReducer.byCorpus[corpus]?.[id];
  });

  const formattedDate = date => {
    return moment(date).format('D MMM YYYY');
  };

  if (!_document) {
    return null;
  }

  return (
    <Accordian defaultOpen className="publication-tags-breakdown__container">
      {({ open }) => (
        <>
          <AccordianSummary open={open}>
            <h2 className="publication-tags-breakdown__title">Metadata</h2>
          </AccordianSummary>
          <AccordianDetails>
            <div className="publication-tags-breakdown__metadata-container">
              {_document?.external_link && (
                <div>
                  <div className="publication-tags-breakdown__metadata-label">
                    Full Text
                  </div>
                  <div className="publication-tags-breakdown__metadata-link">
                    <Link target="_blank" href={_document.external_link}>
                      {_document.external_link}
                    </Link>
                  </div>
                </div>
              )}
              {_document?.id && (
                <div>
                  <div className="publication-tags-breakdown__metadata-label">
                    Publication Id
                  </div>
                  <div className="publication-tags-breakdown__metadata-data">
                    {_document.id}
                  </div>
                </div>
              )}
              {_document?.date && (
                <div>
                  <div className="publication-tags-breakdown__metadata-label">
                    Dates
                  </div>
                  <div className="publication-tags-breakdown__metadata-data">
                    {`Publication: ${formattedDate(_document.date)}`}
                  </div>
                </div>
              )}
              {_document?.organization_tags?.length > 0 && (
                <div>
                  <div className="publication-tags-breakdown__metadata-label">
                    Organizations
                  </div>
                  <div className="publication-tags-breakdown__metadata-data">
                    {_document?.organization_tags?.map(org => (
                      <div key={org}>{org}</div>
                    ))}
                  </div>
                </div>
              )}
              {_document?.people?.length > 0 && (
                <div>
                  <div className="publication-tags-breakdown__metadata-label">
                    People
                  </div>
                  <div className="publication-tags-breakdown__metadata-data">
                    {_document?.people?.map(person => (
                      <div key={person}>{person}</div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </AccordianDetails>
        </>
      )}
    </Accordian>
  );
}
