/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function autoCompleteReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'topicAutoComplete/fetchOptions': {
        nextState._status = 'loading';
        nextState.options = [];

        const { searchTerm } = action.payload;
        nextState.searchTerm = searchTerm;

        if (searchTerm.length < 3) {
          nextState.options = initialState.options;
        }

        return nextState;
      }
      case 'topicAutoComplete/fetchOptionsFulfilled': {
        nextState._status = action.error ? 'failure' : 'success';
        if (nextState._status !== 'success') {
          return nextState;
        }

        const { response } = action.payload;
        nextState.options = (response || {}).data || initialState.options;
        return nextState;
      }
      case 'topicAutoComplete/resetOptions': {
        nextState.options = initialState.options;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
