import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingIndicator } from '@artemisintelligence/aperture-component-library';
import { getFilters } from '../../../filters/actions';
import { ErrorMessage } from '../../../apex';
// See FilterElements for more information on the cyclical import
// eslint-disable-next-line import/no-cycle
import FilterElements from './filterElements';

export default function TopicFilterElements({ topicId, topLevelId }) {
  const searchId = useSelector(state => state.searchId.id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilters({ searchId, topicId }));
  }, [dispatch, searchId, topicId]);

  const status = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?._status
  );

  const error = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?.error
  );

  const filters = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?.topics
  );

  if (status === undefined || status === 'loading') {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <FilterElements
      filters={filters}
      topicId={topicId}
      topLevelId={topLevelId}
    />
  );
}
