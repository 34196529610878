import React from 'react';
import { useSelector } from 'react-redux';
import { AccordianDetails, Accordian, AccordianSummary } from '../../../apex';
import '../css/publicationProblems.css';

export default function PublicationProblems({ id, corpus }) {
  const problems = useSelector(state => {
    return state.documentReducer.byCorpus[corpus]?.[id]?.problems?.slice(0, 20);
  });

  if (!problems) {
    return null;
  }

  return (
    <Accordian defaultOpen>
      {({ open }) => (
        <>
          <AccordianSummary open={open}>
            <h2 className="publication-problems__title">Problem Mentions</h2>
          </AccordianSummary>
          <AccordianDetails>
            <div className="publication-problems__details-container">
              {problems.map(tagsAndText => (
                <div
                  key={tagsAndText.toString()}
                  className="publication-problems__tags-text-container"
                >
                  <div className="publication-problems__tags">
                    {tagsAndText.tags.map(tag => (
                      <div
                        key={tag}
                        dangerouslySetInnerHTML={{ __html: tag }}
                      />
                    ))}
                  </div>

                  <div
                    className="publication-problems__text"
                    dangerouslySetInnerHTML={{ __html: tagsAndText.text }}
                  />
                </div>
              ))}
            </div>
          </AccordianDetails>
        </>
      )}
    </Accordian>
  );
}
