import { Tab as HeadlessUITab } from '@headlessui/react';
import './tab.css';
import React, { Fragment } from 'react';

function Tab({ label, icon, disabled, className }) {
  return (
    <HeadlessUITab as={Fragment}>
      {({ selected }) => (
        <button
          type="submit"
          disabled={disabled}
          className={`tab ${selected ? 'tab--active' : 'tab--inactive'}  ${
            disabled ? 'tab--disabled' : ''
          }
               ${className}`}
        >
          <span
            className={`tab__icon ${
              selected ? 'tab__icon--active' : 'tab__icon--inactive'
            }`}
          >
            {icon}
          </span>
          {label}
        </button>
      )}
    </HeadlessUITab>
  );
}

export default Tab;
