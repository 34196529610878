function buildUrl({ path, host = null }) {
  const parsedHost = (() => {
    if (host) return host;

    if (
      process.env.REACT_APP_AUTH_AUDIENCE !== 'undefined' &&
      process.env.REACT_APP_AUTH_AUDIENCE
    ) {
      return process.env.REACT_APP_AUTH_AUDIENCE;
    }

    return process.env.REACT_APP_MOCK_API_BASE;
  })();

  if (!parsedHost || parsedHost === 'undefined') {
    throw Error('No valid API URL.');
  }

  return new URL(path, parsedHost).toString();
}

export function apiRequestParams({
  path,
  host = null,
  method = 'GET',
  body = null
}) {
  const url = buildUrl({ path, host });

  return {
    url,
    method,
    body
  };
}

export function apiRequest({
  path,
  fulfilledType,
  metadata = undefined,
  host = null,
  method = 'GET',
  body = null
}) {
  const payload = {
    ...apiRequestParams({ path, host, method, body }),
    fulfilledType
  };

  if (metadata !== undefined) {
    Object.assign(payload, { metadata });
  }

  return {
    type: 'api/request',
    payload
  };
}
