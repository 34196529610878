/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function filtersReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'filters/fetch': {
        const { searchId, topicId } = action.payload;
        nextState.bySearchId[searchId] = nextState.bySearchId[searchId] ?? {};

        nextState.bySearchId[searchId][topicId] = nextState.bySearchId[
          searchId
        ][topicId] ?? {
          _status: 'loading',
          topics: []
        };

        return nextState;
      }
      case 'filters/fetchFulfilled': {
        const { searchId, topicId } = action.payload.metadata.sourcePayload;
        nextState.bySearchId[searchId] = nextState.bySearchId[searchId] ?? {};

        nextState.bySearchId[searchId][topicId] =
          nextState.bySearchId[searchId][topicId] ?? {};

        const topicObject = nextState.bySearchId[searchId][topicId];

        const { response } = action.payload;

        if (action.error) {
          topicObject._status = 'failure';
          topicObject.message = response;
          return nextState;
        }

        topicObject._status = 'success';
        topicObject.topics = response.documents;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
