import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { buildApiObservable } from './observable';

const apiRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType('api/request'),
    mergeMap(action => buildApiObservable({ params: action.payload }, state$))
  );
export default apiRequestEpic;
