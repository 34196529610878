/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function spacesReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'spaces/fetch': {
        nextState._status = 'loading';
        return nextState;
      }
      case 'spaces/fetchFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          return nextState;
        }

        const { response } = action.payload;
        const status = action.error ? 'failure' : 'success';
        nextState.availableSpaces = response?.data?.map(x => {
          return { id: x.id, humanKey: x.human_key, default: x.default };
        });

        nextState._status = status;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
