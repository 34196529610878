/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './resources/css/master.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { Theme } from '@artemisintelligence/aperture-component-library';
import configuredStore from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

const store = configuredStore;
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <EmotionThemeProvider theme={Theme}>
        <App />
      </EmotionThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
