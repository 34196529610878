/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function resultsReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'results/fetch': {
        const { searchId, page, size, resultsType } = action.payload;
        if (resultsType !== 'publications') {
          nextState.nonPublicationSelectedResultTypeId = resultsType;
        }
        nextState.bySearch[searchId] = nextState.bySearch[searchId] ?? {};
        nextState.bySearch[searchId][resultsType] = nextState.bySearch[
          searchId
        ][resultsType] ?? { pages: {} };

        const searchIdObject = nextState.bySearch[searchId][resultsType];

        searchIdObject.pages = searchIdObject.pages ?? {};

        if (searchIdObject.pages[page]?.requestSize !== size) {
          searchIdObject.pages[page] = {
            _status: 'loading',
            results: [],
            requestSize: size
          };
        }

        return nextState;
      }

      case 'results/fetchFulfilled': {
        const {
          searchId,
          page,
          size,
          resultsType
        } = action.payload.metadata.sourcePayload;

        nextState.bySearch[searchId] = nextState.bySearch[searchId] ?? {};
        nextState.bySearch[searchId][resultsType] = nextState.bySearch[
          searchId
        ][resultsType] ?? { pages: {} };

        const searchIdObject = nextState.bySearch[searchId][resultsType];

        searchIdObject.pages = searchIdObject.pages ?? {};

        searchIdObject.pages[page] = searchIdObject.pages?.[page] ?? {};

        searchIdObject.pages[page].requestSize = size;

        if (action.error) {
          nextState.nonPublicationSelectedResultTypeId =
            initialState.nonPublicationSelectedResultTypeId;

          searchIdObject.pages[page]._status = 'failure';

          searchIdObject.pages[page].error = action.payload.message;

          return nextState;
        }

        const { total, start, documents, sort } = action.payload.response;
        const responseSize = action.payload.response.size;

        searchIdObject.sort = sort;
        searchIdObject.total = total;
        searchIdObject.pages[page].size = responseSize;
        searchIdObject.pages[page].start = start;
        searchIdObject.pages[page]._status = 'success';
        const documentsWithType = documents.map(doc => ({
          ...doc,
          type: resultsType
        }));

        searchIdObject.pages[page].results = documentsWithType;
        return nextState;
      }

      case 'results/updatedResultsViewId': {
        const { viewId } = action.payload;
        nextState.viewId = viewId;
        return nextState;
      }

      default:
        return nextState;
    }
  });
}
