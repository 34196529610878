/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable react/no-unescaped-entities
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Paper, Copy } from '@artemisintelligence/aperture-component-library';
import { jsx, useTheme } from '@emotion/react';
import noResultsIllustration from '../../../resources/img/noResults.svg';

export default function NoResultsDisplay() {
  const theme = useTheme();
  const jss = {
    noResultsContainer: {
      flexDirection: 'row',
      paddingTop: '120px',
      paddingBottom: '200px',
      padding: '120px 0px 200px 40px',
      justifyContent: 'space-evenly',
      display: 'flex',
      [theme.breakPoints[0]]: {
        padding: '50px 35px',
        flexDirection: 'column'
      }
    },
    noResultsTextContainer: {
      [theme.breakPoints[0]]: {
        marginBottom: '50px'
      }
    },
    noResultsTitle: {
      marginBottom: '25px'
    }
  };

  return (
    <Paper
      shadow={1}
      borderTop={0}
      borderRight={1}
      borderBottom={1}
      borderLeft={1}
      css={jss.noResultsContainer}
    >
      <div css={jss.noResultsTextContainer}>
        <Copy element="h1" typographyStyle="XL" css={jss.noResultsTitle}>
          No Results Found
        </Copy>
        <Copy element="p" typographyStyle="S">
          Try different terms, filters, selections, or combinations.
        </Copy>
        <Copy element="p" typographyStyle="S">
          For example, multiple search terms yield more results, like 'waxes'
          and 'resins'.
        </Copy>
      </div>
      <img
        src={noResultsIllustration}
        alt="no results"
        css={jss.noResultsImage}
      />
    </Paper>
  );
}
