import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

function DateTimeAreaChart({ yLabel, data }) {
  const options = {
    chart: {
      type: 'areaspline',
      spacingLeft: 0,
      spacingTop: 1,
      spacingBottom: 0,
      spacingRight: 2
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      tickInterval: data?.length / 3,
      lineColor: 'transparent',
      lineWidth: 0,
      minorGridLineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      allowDecimals: false,
      labels: {
        style: {
          cursor: 'default',
          color: '#80868C'
        },
        formatter() {
          // eslint-disable-next-line
          return this.value;
        }
      }
    },
    yAxis: {
      lineColor: 'transparent',
      tickAmount: 4,
      tickWidth: 0.5,
      tickColor: '#E9EDEF',
      title: {
        text: null
      },
      labels: {
        enabled: false
      }
    },
    tooltip: {
      useHTML: true,
      zIndex: 20,
      headerFormat: `<div style='padding:8px 10px'>`,
      shadow: true,
      backgroundColor: '#FFFFFF',
      borderRadius: 0,
      borderWidth: 0,
      pointFormat: `
            <div><span style='color:#000000; font:12px Poppins'>{point.x}</span>: <span style='color:#80868C; font:12px Poppins'>{point.y} ${yLabel}</span></div>
            `,
      footerFormat: '</div>'
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        states: {
          hover: {
            // enabled: false,
          }
        },
        color: '#C6C9F8',
        fillOpacity: 1,
        data
      }
    ]
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%' } }}
      options={options}
    />
  );
}

DateTimeAreaChart.propTypes = {
  /**
   * The data for the chart
   */
  data: PropTypes.instanceOf(Array),
  /**
   * The name for what is being measured on the y-axis
   */
  yLabel: PropTypes.string
};

DateTimeAreaChart.defaultProps = {
  data: [],
  yLabel: ''
};

export default DateTimeAreaChart;
