import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';
import { apiRequestObservable } from '../api/observable';

const fetchSearchIdEpic = action$ =>
  action$.pipe(
    ofType('searchId/fetch'),
    map(({ payload }) => {
      const { searchId } = payload;
      return apiRequest({
        path: `/search/${searchId}`,
        method: 'GET',
        fulfilledType: 'searchId/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

const updateSearchIdEpic = (action$, state$) =>
  action$.pipe(
    ofType('searchId/update'),
    switchMap(({ payload }) => {
      return apiRequestObservable(
        {
          path: `/search/${payload.searchId}`,
          body: payload.body,
          method: 'PATCH',
          fulfilledType: 'searchId/updateFulfilled',
          metadata: { sourcePayload: payload }
        },
        state$
      );
    })
  );

export default combineEpics(fetchSearchIdEpic, updateSearchIdEpic);
