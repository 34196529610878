import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '../Icons/CloseIcon';
import './modal.css';

function Modal({
  children,
  footer,
  title,
  hasCloseButton,
  onCloseButtonClick,
  onCancel,
  onMaskClick,
  visible,
  initialFocusRef,
  width,
  className,
  ...other
}) {
  return (
    <Transition
      show={visible}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        onClose={onMaskClick}
        initialFocus={initialFocusRef}
        className={`modal ${className}`}
        {...other}
      >
        <Dialog.Overlay className="modal__mask" />
        <div className="modal__alignmentBox">
          <div className="modal__box" style={{ width }}>
            <div className="modal__heading">
              <Dialog.Title className="modal__title">{title}</Dialog.Title>
              {onCloseButtonClick && (
                <CloseIcon
                  className="modal__close-icon"
                  onClick={onCloseButtonClick}
                />
              )}
            </div>
            <div className="modal__body">{children}</div>
            {footer ? <div className="modal__footer">{footer}</div> : null}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.node,
  hasCloseButton: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  onCancel: PropTypes.func,
  onMaskClick: PropTypes.func,
  visible: PropTypes.bool,
  initialFocusRef: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

Modal.defaultProps = {
  children: undefined,
  footer: undefined,
  title: undefined,
  hasCloseButton: true,
  onCloseButtonClick: undefined,
  onCancel: undefined,
  onMaskClick: () => {},
  visible: true,
  initialFocusRef: undefined,
  width: 'auto',
  className: undefined
};

export default Modal;
