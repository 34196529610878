import React from 'react';
import PropTypes from 'prop-types';

export default function CircleMinusIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M12,3.12A8.88,8.88,0,1,0,20.88,12,8.89,8.89,0,0,0,12,3.12Zm0,17A8.13,8.13,0,1,1,20.13,12,8.14,8.14,0,0,1,12,20.13Z" />
        <path d="M16.81,11.63H7.19a.37.37,0,0,0-.38.37.38.38,0,0,0,.38.38h9.62a.38.38,0,0,0,.38-.38A.37.37,0,0,0,16.81,11.63Z" />
      </svg>
    </div>
  );
}

CircleMinusIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

CircleMinusIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
