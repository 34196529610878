// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingIndicator } from '@artemisintelligence/aperture-component-library';
import cloneDeep from 'clone-deep';
import { BubbleChart, ErrorMessage, Modal } from '../../../apex';
import { getBubbleVisualization } from '../../../bubbleVisualization/actions';
import '../css/explore.css';
import ResultProfileTemplate from '../../resultProfile/resultProfileTemplate';
import {
  addSearchFilter,
  removeSearchFilter
} from '../../../searchFilters/actions';

function Explore({ resultType }) {
  const { searchId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resultModalVisible, setResultModalVisible] = useState(false);

  const resultID = searchParams.get('resultId');

  useEffect(() => {
    if (resultID === null) {
      setResultModalVisible(false);
    } else {
      setResultModalVisible(true);
    }
  }, [resultID]);

  const bubbleVisualizationStore = useSelector(
    state => state.bubbleVisualizationReducer
  );

  const bubbleVisualizationOptionsStore = useSelector(
    state => state.bubbleVisualizationOptionsReducer
  );

  const selectedFilterObjects = useSelector(
    state => state.searchFilters.filters
  );
  const selectedFilterData = selectedFilterObjects.map(
    element => element.data_value
  );

  const status = bubbleVisualizationStore._status;

  const chartData = bubbleVisualizationStore?.data;
  const dispatch = useDispatch();

  const queryParams = cloneDeep(searchParams);

  // const topicId =
  //   queryParams.get('topic_id') || bubbleVisualizationStore.topicId;

  const resultTypeId = resultType?.value;
  const resultTypeName = resultType?.name;

  let x = queryParams.get('x') || bubbleVisualizationStore.x;
  const y = queryParams.get('y') || bubbleVisualizationStore.y;
  const z = queryParams.get('z') || bubbleVisualizationStore.z;

  const bc = queryParams.get('bubbleCount') || bubbleVisualizationStore.bc;

  useEffect(() => {
    console.log(resultTypeId);
    if (bubbleVisualizationOptionsStore._status === 'success') {
      // if (!queryParams.has('topic_id')) {
      //   queryParams.set('topic_id', topicId);
      // }
      if (!queryParams.has('x')) {
        if (
          bubbleVisualizationOptionsStore.data.some(
            e => e.id === 'company_fit'
          ) &&
          bubbleVisualizationStore._status === 'initial'
        ) {
          x = 'company_fit';
        }
        queryParams.set('x', x);
      }
      if (!queryParams.has('y')) {
        queryParams.set('y', y);
      }
      if (!queryParams.has('z')) {
        queryParams.set('z', z);
      }

      queryParams.sort();
      setSearchParams(queryParams);
      dispatch(
        getBubbleVisualization({
          searchId,
          topicId: resultTypeId,
          x,
          y,
          z
        })
      );
    }
  }, [resultTypeId, searchId, bubbleVisualizationOptionsStore._status]);

  if (status === 'failure') {
    return <ErrorMessage />;
  }

  const axisOptions = bubbleVisualizationOptionsStore.data.map(q => ({
    name: q.humanKey,
    value: q.id
  }));

  const openModal = resultId => {
    setResultModalVisible(true);
    const newParams = cloneDeep(searchParams);
    newParams.set('resultId', resultId);
    newParams.set('search', searchId);
    setSearchParams(newParams);
  };

  const closeModal = () => {
    setResultModalVisible(false);
    const newParams = cloneDeep(searchParams);
    newParams.delete('resultId');
    newParams.delete('search');
    setSearchParams(newParams);
  };

  const onAddToSearchClick = id => {
    const alreadySelected = selectedFilterData.includes(id);

    const filterItem = {
      data_value: id,
      filter_type: resultTypeId
    };

    if (!alreadySelected) {
      return dispatch(addSearchFilter(filterItem));
    }
    return dispatch(removeSearchFilter(filterItem));
  };

  function renderChart() {
    switch (status) {
      case 'failure':
        return <ErrorMessage />;
      case 'loading':
        return <LoadingIndicator />;
      default:
        return (
          <BubbleChart
            limitor={bc}
            data={cloneDeep(chartData)}
            xLabel={axisOptions.find(o => o.value === x)?.name}
            yLabel={axisOptions.find(o => o.value === y)?.name}
            zLabel={axisOptions.find(o => o.value === z)?.name}
            seriesName={resultTypeName}
            onBubbleClick={e => openModal(e.point.id)}
            onAddToSearchClick={e => onAddToSearchClick(e)}
            limiter={bc}
            universalSizing={z === 'none'}
          />
        );
    }
  }

  return (
    <>
      <Modal
        width="75%"
        visible={resultModalVisible}
        onMaskClick={closeModal}
        onCloseButtonClick={closeModal}
      >
        <ResultProfileTemplate
          profileType={resultTypeName}
          profileEndpoint={resultTypeId}
        />
      </Modal>
      <div className="explore">
        <div className="explore__chart-container" data-id="explore-chart">
          {renderChart()}
        </div>
      </div>
    </>
  );
}

export default Explore;
