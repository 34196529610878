import React from 'react';
import './badge.css';

const Badge = ({ type, children, className, ...other }) => {
  const classMap = {
    type: {
      filled: 'badge--filled',
      outlined: 'badge--outlined'
    }
  };

  return (
    <div className={`badge ${classMap.type[type]} ${className}}`} {...other}>
      {children}
    </div>
  );
};

export default Badge;
