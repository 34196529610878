import { ofType, combineEpics } from 'redux-observable';
import { mapTo } from 'rxjs/operators';

export const queueSearchUpdate = action$ =>
  action$.pipe(
    ofType('searchTopics/add', 'searchTopics/remove', 'searchTopics/set'),
    mapTo({ type: 'search/update' })
  );

export default combineEpics(queueSearchUpdate);
