import PropTypes from 'prop-types';
import React from 'react';
import './button.css';

function Button({
  children,
  disabled,
  size,
  shape,
  context,
  type,
  htmlType,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  className,
  ...other
}) {
  const classMap = {
    size: {
      small: 'button--small',
      medium: 'button--medium',
      large: 'button--large'
    },
    type: {
      primary: 'button--primary',
      secondary: 'button--secondary',
      text: 'button--text'
    }
  };

  return (
    <button
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`button ${classMap.size[size]} ${classMap.type[type]} ${className}`}
      {...other}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'text']),
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string
};

Button.defaultProps = {
  children: undefined,
  disabled: false,
  size: 'medium',
  type: 'primary',
  htmlType: 'button',
  onClick: undefined,
  onMouseDown: undefined,
  onMouseUp: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  onFocus: undefined,
  onBlur: undefined,
  className: undefined
};

export default Button;
