import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

Highcharts.AST.allowedAttributes.push('onclick');

/* eslint-disable react/no-this-in-sfc */

function BubbleChart({
  filename,
  data,
  xLabel,
  yLabel,
  zLabel,
  seriesName,
  title,
  onBubbleClick,
  xMin,
  xMax,
  yMin,
  yMax,
  limiter,
  universalSizing,
  onAddToSearchClick,
  ...props
}) {
  window.onTooltipButtonClick = function(id) {
    onAddToSearchClick(id);
  };

  const cutData = data.slice(0, limiter - 1);
  const pointCategoryConf = {
    products: {
      data: [],
      color: '#f4f5ff',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#f8f9ff',
        lineColor: '#80868c'
      }
    },
    'Pain Points': {
      data: [],
      color: '#e0e2ff',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#ebecff',
        lineColor: '#80868c'
      }
    },
    materials: {
      data: [],
      color: '#ccd0ff',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#dee0ff',
        lineColor: '#80868c'
      }
    },
    processes: {
      data: [],
      color: '#abb1ff',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#c8ccff',
        lineColor: '#80868c'
      }
    },
    Industry: {
      data: [],
      color: '#A9D08E',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#b6d79f',
        lineColor: '#80868c'
      }
    },
    Academic: {
      data: [],
      color: '#DBEBCF',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#e1efd8',
        lineColor: '#80868c'
      }
    },
    // technologies: {
    //   data: [],
    //   color: #3d4cff,
    //   borderWidth: '0.75',
    //   borderColor:  '#80868c',
    //   hover: {
    //     lineWidth: 0.75,
    //     fillColor: '#818bff',
    //     lineColor:   '#80868c',
    //   },
    //
    // },

    other: {
      data: [],
      color: '#ebe4da',
      borderWidth: '0.5',
      borderColor: '#80868c',
      hover: {
        lineWidth: 0.5,
        fillColor: '#f2eee7',
        lineColor: '#80868c'
      }
    }
  };
  cutData.forEach(o => {
    let pointCategory = 'other';
    if (o.categories && o.categories[0] in pointCategoryConf) {
      /* eslint-disable-next-line prefer-destructuring */
      pointCategory = o.categories[0];
    }
    pointCategoryConf[pointCategory].data.push({
      id: o.id,
      filterType: o.filter_type,
      x: o.x,
      y: o.y,
      z: o.z,
      name: o.name,
      category: pointCategory,
      marker: {
        lineWidth: 0.5,
        fillColor: pointCategoryConf[pointCategory].color,
        lineColor: pointCategoryConf[pointCategory].borderColor,
        states: {
          hover: pointCategoryConf[pointCategory].hover
        }
      }
    });
  });

  const pointSeries = [];
  Object.keys(pointCategoryConf).forEach(category => {
    if (pointCategoryConf[category].data.length > 0) {
      pointSeries.push({
        keys: ['x', 'y', 'z', 'category'],
        data: pointCategoryConf[category].data,
        name: category,
        cursor: 'pointer',
        color: pointCategoryConf[category].color
      });
    }
  });

  const options = {
    navigation: {
      menuStyle: {
        background: '#ffffff',
        boxShadow: `0px 0px 20px #e9edef`,
        border: 'none',
        paddingBottom: '0px',
        paddingTop: '0px'
      },
      menuItemStyle: {
        color: '#000000',
        borderBottom: `0.5px solid ${'#dae1e5'}`,
        background: 'transparent',
        padding: '12px 20px 12px 20px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '15px'
      },
      menuItemHoverStyle: {
        color: '#000000',
        background: 'transparent'
      }
    },
    exporting: {
      sourceWidth: 750,
      filename,
      useMultiLevelHeaders: false,
      enabled: true,
      buttons: {
        contextButton: {
          text: 'Export Options',
          theme: {
            states: {
              hover: {
                fontFamily: 'Poppins',
                stroke: 'transparent',
                fill: '#f4f5ff'
              },
              select: {
                fontFamily: 'Poppins',
                stroke: 'transparent',
                fill: '#f4f5ff'
              }
            }
          },
          symbolFill: '#000000',
          symbolStrokeWidth: '1',
          menuItems: [
            {
              text: 'Download JPEG',
              onclick() {
                this.exportChart({
                  type: 'image/jpeg'
                });
              }
            },
            {
              text: 'Download PDF',
              onclick() {
                this.exportChart({
                  type: 'application/pdf'
                });
              }
            },
            {
              text: 'Download XLS',
              onclick() {
                this.downloadXLS();
              }
            },
            {
              text: 'Download CSV',
              onclick() {
                this.downloadCSV();
              }
            },
            'viewFullscreen',
            {
              text: 'Print chart',
              onclick() {
                this.print();
              }
            }
          ]
        }
      },
      csv: {
        columnHeaderFormatter(item, key) {
          if (item.isXAxis) {
            return seriesName;
          }
          if (key === 'x') {
            return xLabel;
          }
          if (key === 'y') {
            return yLabel;
          }
          if (key === 'z') {
            return zLabel;
          }
          if (key === 'category') {
            return 'Category';
          }
          return null;
        }
      }
    },
    chart: {
      type: 'bubble',
      plotBorderWidth: 0.5,
      plotBorderColor: '#dae1e5',
      marginTop: 50,
      spacingTop: 17.5,
      //  spacingBottom: 35,

      zoomType: 'xy',
      events: {
        load() {
          // 85 230 267
          this.renderer
            .text('Click & Drag To Zoom', 185, 15)
            .attr({
              zIndex: 5
            })
            .css({
              color: '#80868c',
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px'
            })
            .add();
          this.renderer
            .text('----', 85, 15)
            .attr({
              zIndex: 5
            })
            .css({
              color: '#000000',
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px'
            })
            .add();

          this.renderer
            .text('Average', 118, 15)
            .attr({
              zIndex: 5
            })
            .css({
              color: '#80868c',
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px'
            })
            .add();
        }
      }
    },
    legend: {
      useHTML: true,
      symbolHeight: 16,
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      x: 65,
      margin: 5,
      itemHoverStyle: {
        cursor: 'default',
        color: '#80868c',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px'
      },
      itemStyle: {
        color: '#80868c',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
        textTransform: 'capitalize'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      endOnTick: false,
      lineColor: 'transparent',
      minorGridLineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0.75,
      gridLineColor: '#e9edef',
      title: {
        margin: 12,
        text: xLabel,
        style: {
          color: '#000000',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '12px'
        }
      },
      labels: {
        format: '{value:.2f}',
        style: {
          color: '#000000',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '10px'
        }
      },
      plotLines: [
        {
          color: '#000000',
          dashStyle: 'dash',
          width: 0.75,
          value: cutData.reduce((a, b) => a + b.x, 0) / cutData.length,
          zIndex: 5
        }
      ]
    },

    yAxis: {
      endOnTick: false,
      min: yMin,
      max: yMax,
      lineColor: 'transparent',
      gridLineWidth: 0.75,
      gridLineColor: '#e9edef',
      title: {
        margin: 12,
        text: yLabel,
        style: {
          color: '#000000',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '12px'
        }
      },
      labels: {
        style: {
          color: '#000000',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '10px'
        },
        format: '{value:.2f}'
      },
      plotLines: [
        {
          color: '#000000',
          dashStyle: 'dash',
          width: 0.75,
          value: cutData.reduce((a, b) => a + b.y, 0) / cutData.length,
          zIndex: 5
        }
      ]
    },
    tooltip: {
      hideDelay: 1200,
      useHTML: true,
      zIndex: 200,
      headerFormat: `<div style='padding:16px 20px'>`,
      shadow: true,
      backgroundColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      pointFormat: `
        <div><span style='color:${'#000000'}; font:12px Poppins;'>{point.name}</span></div>
            <div><span style='text-transform: capitalize; color:${'#80868c'}; font:10px Poppins'>{series.name}</span></div>
            <div><span  style='color:${'#80868c'}; font:10px Poppins; padding-right:5px'>${xLabel}</span><span style='color:${'#000000'}; font:10px Poppins'>{point.x:.2f}</span></div>
            <div><span  style='color:${'#80868c'}; font:10px Poppins; padding-right:5px'>${yLabel}</span><span style='color:${'#000000'}; font:10px Poppins'>{point.y:.2f}</span></div>
            <div><span  style='color:${'#80868c'}; font:10px Poppins; padding-right:5px;'>${zLabel}</span><span style='color:${'#000000'}; font:10px Poppins'>{point.z}</span></div>
            <button type="button" onmouseover="this.style.background='orange'" style=' all: unset; margin-top: 8px; cursor:pointer; text-transform: uppercase; background:#000000;
            color:#dae1e5; border-radius:5px; padding:4px 28px; font-family:'Poppins'; font-weight:500; font-size:12px; letter-spacing:0.6px;' onclick="onTooltipButtonClick('{point.id}')">Add to Search</button>
            `,
      footerFormat: '</div>',
      style: {
        pointerEvents: 'auto'
      }
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        dataLabels: {
          //  inside:true,
          //  allowOverlap: true,
          enabled: true,
          formatter() {
            // console.log(this.point.name.toString()?.length);
            // let s =
            //   this.point.name.length > 10
            //     ? this.point.name.substring(0, 9) + '...'
            //     : this.point.name;

            const ogLength = this.point.name.split(/\s+/).length;
            let s = `${this.point.name
              .split(/\s+/)
              .slice(0, 5)
              .join(' ')}`;

            if (ogLength > 5) {
              s += '...';
            }

            return s;
          },
          style: {
            color: '#404346',
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '10px',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            textOutline: 0
          }
          // // filter: {
          // //     property: 'z',
          // //     operator: '>',
          // //     value: 209
          // // },
          // formatter: function() {
          //   if (this.point.z > 209) {
          //   return this.point.name
          // }
          // return null
          // }
        },
        states: {
          hover: {
            halo: false
          }
        }
      },
      bubble: {
        minSize: universalSizing ? 15 : undefined,
        maxSize: universalSizing ? 15 : undefined,
        events: {
          // legendItemClick() {
          //   return false;
          // },
          click(event) {
            onBubbleClick(event);
          }
        },
        marker: {
          lineColor: '#f4f5ff',
          fillOpacity: 1,
          states: {
            hover: {
              fillColor: '#818bff',
              fillOpacity: 1,
              lineColor: '#f8f9ff'
            }
          }
        }
      }
    },
    series: pointSeries,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 576
          },
          chartOptions: {
            chart: {
              marginTop: 85,
              spacingTop: 20
            }
          }
        }
      ]
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%' } }}
      options={options}
      // callback={function(chart) {
      //   const { series } = chart;
      //   $.each(series, function(i, ser) {
      //     $(ser.legendSymbol.element).attr('stroke-width', '1');
      //     $(ser.legendSymbol.element).attr('stroke', '#80868c');
      //   });
      // }}
      {...props}
    />
  );
}

BubbleChart.propTypes = {
  universalSizing: PropTypes.bool,
  filename: PropTypes.string,
  xMin: PropTypes.number,
  xMax: PropTypes.number,
  yMin: PropTypes.number,
  yMax: PropTypes.number,
  title: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  zLabel: PropTypes.string,
  seriesName: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  limiter: PropTypes.number,
  onBubbleClick: PropTypes.func
};

BubbleChart.defaultProps = {
  universalSizing: false,
  filename: 'explore',
  xMin: undefined,
  xMax: undefined,
  yMin: undefined,
  yMax: undefined,
  title: '',
  xLabel: '',
  yLabel: '',
  zLabel: '',
  seriesName: '',
  data: [],
  limiter: [],
  onBubbleClick: () => {}
};

export default BubbleChart;
