/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function filtersReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchId/fetchFulfilled':
      case 'searchId/updateFulfilled': {
        if (action.error) {
          return nextState;
        }
        const { response } = action.payload;

        const filtersSansDateFilters = (response || {}).filters?.filter(
          function(x) {
            return x.filter_type !== 'date';
          }
        );
        nextState.filters = filtersSansDateFilters || initialState.filters;
        return nextState;
      }
      case 'searchFilters/add': {
        nextState.filters.push(action.payload);
        return nextState;
      }
      case 'searchFilters/remove': {
        nextState.filters = nextState.filters.filter(
          element => element.data_value !== action.payload.data_value
        );
        return nextState;
      }
      case 'searchFilters/reset': {
        nextState.filters = [];
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
