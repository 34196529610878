import { Menu as HeadlessUIMenu } from '@headlessui/react';
import React from 'react';
import './menu.css';

function Menu({ children, items, className }) {
  return (
    <HeadlessUIMenu className={`menu ${className}`} as="div">
      <HeadlessUIMenu.Button as="span">{children}</HeadlessUIMenu.Button>
      <HeadlessUIMenu.Items className="menu__menu-items" as="ul">
        {items}
      </HeadlessUIMenu.Items>
    </HeadlessUIMenu>
  );
}

export default Menu;
