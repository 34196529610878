import initialState from './initialState';

export default function auth(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'auth/tokenRequest':
      newState = { ...state };
      newState.loading = true;
      return newState;
    case 'auth/tokenResponse':
      newState = {
        ...state,
        token: action.payload.token.access_token,
        id_token: action.payload.token.id_token
      };
      newState.loading = false;
      return newState;
    default:
      return state;
  }
}
