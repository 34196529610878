import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { apiRequest } from './actions';

export const buildRequestObservable = params => {
  const { url, method, body, authToken } = params;
  const request = {
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    crossDomain: true,
    body
  };

  return ajax(request);
};

export const buildApiObservable = ({ params }, state$) => {
  const { fulfilledType, metadata } = params;

  return buildRequestObservable({
    ...params,
    authToken: state$.value.auth.token
  }).pipe(
    map(({ response }) => {
      const payload = { response };
      if (metadata) {
        Object.assign(payload, {
          metadata
        });
      }
      return { type: fulfilledType, payload };
    }),
    catchError(error => {
      const payload = {
        status: error.status,
        response: error.response,
        message: error.message,
        metadata
      };
      return of({ type: fulfilledType, error: true, payload });
    })
  );
};

export const apiRequestObservable = (
  {
    path,
    fulfilledType,
    metadata = undefined,
    host = null,
    method = 'GET',
    body = null
  },
  state$
) => {
  const { payload } = apiRequest({
    path,
    fulfilledType,
    metadata,
    host,
    method,
    body
  });
  return buildApiObservable({ params: payload }, state$);
};
