import React from 'react';
import PropTypes from 'prop-types';

export default function SearchIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M23.06,20.05a1.67,1.67,0,0,0-.68-1.14l-5.13-3.65a8.7,8.7,0,0,0,1.22-4.44,8.8,8.8,0,1,0-1.63,5.07l5.1,3.63a.94.94,0,0,1,.18,1.33A.94.94,0,0,1,20.8,21l-2.44-2.45a.37.37,0,0,0-.53,0,.38.38,0,0,0,0,.53l2.47,2.47a1.68,1.68,0,0,0,1.1.42h.15a1.68,1.68,0,0,0,1.52-1.89ZM9.7,18.84a8,8,0,1,1,8-8A8,8,0,0,1,9.7,18.84Z" />
      </svg>
    </div>
  );
}

SearchIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

SearchIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
