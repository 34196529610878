/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import cloneDeep from 'clone-deep';
import { Spinner, ErrorMessage, Modal } from '../../../apex';
import Mentions from './mentions';
import Results from './results';

import ResultProfileTemplate from '../../resultProfile/resultProfileTemplate';
import PublicationProfileTemplate from '../../publicationProfile/publicationProfileTemplate';

export default function ListResults({ resultsType }) {
  const { searchId } = useParams();
  const [resultModalVisible, setResultModalVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const pageId = searchParams.get('page');

  const resultID = searchParams.get('resultId');

  useEffect(() => {
    if (resultID === null) {
      setResultModalVisible(false);
    } else {
      setResultModalVisible(true);
    }
  }, [resultID]);

  const page = parseInt(pageId, 10) || 1;

  const status = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultsType]?.pages?.[page]?._status
  );

  const results = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultsType]?.pages?.[page]?.results
  );

  const openModal = resultId => {
    setResultModalVisible(true);
    const newParams = cloneDeep(searchParams);
    newParams.set('resultId', resultId);
    newParams.set('search', searchId);
    setSearchParams(newParams);
  };

  const closeModal = () => {
    setResultModalVisible(false);
    const newParams = cloneDeep(searchParams);
    newParams.delete('resultId');
    newParams.delete('search');
    setSearchParams(newParams);
  };

  let listOfResults;
  let modalContent;

  if (status === 'failure') {
    listOfResults = <ErrorMessage />;
  }
  if (status === 'loading') {
    listOfResults = <Spinner width={80} />;
  }
  if (resultsType === 'problems') {
    listOfResults = (
      <Results
        results={results}
        openModal={openModal}
        profileEndpoint="problems"
      />
    );
    modalContent = (
      <ResultProfileTemplate profileType="problem" profileEndpoint="problems" />
    );
  }
  if (resultsType === 'publications') {
    listOfResults = <Mentions results={results} openModal={openModal} />;
    modalContent = <PublicationProfileTemplate />;
  }

  return (
    <>
      {listOfResults}
      <Modal
        width="75%"
        visible={resultModalVisible}
        onMaskClick={closeModal}
        onCloseButtonClick={closeModal}
      >
        {modalContent}
      </Modal>
    </>
  );
}
