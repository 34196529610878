import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchBubbleVisualizationEpic = action$ =>
  action$.pipe(
    ofType('bubbleVisualization/fetch'),
    map(({ payload }) => {
      const { searchId, topicId, x, y, z } = payload;
      return apiRequest({
        path: `/visualizations/bubble?size=1000&search_id=${searchId}&topic_id=${topicId}&x=${x}&y=${y}&z=${z}`,
        fulfilledType: 'bubbleVisualization/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default fetchBubbleVisualizationEpic;
