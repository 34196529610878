import React from 'react';
import PropTypes from 'prop-types';

export default function PublicationIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M18.9,17.5a.37.37,0,0,0,.37-.38V2.8a.37.37,0,0,0-.37-.37H5.1a.37.37,0,0,0-.37.37V21.2a.37.37,0,0,0,.37.37H18.9a.38.38,0,0,0,0-.75H5.48V3.18h13V17.12A.38.38,0,0,0,18.9,17.5Z" />
        <path d="M7.49,7.54H13a.37.37,0,0,0,.37-.37A.38.38,0,0,0,13,6.79H7.49a.38.38,0,0,0-.37.38A.37.37,0,0,0,7.49,7.54Z" />
        <path d="M16.44,8.52h-9a.38.38,0,0,0,0,.75h9a.38.38,0,1,0,0-.75Z" />
        <path d="M16.44,10.25h-9a.38.38,0,0,0,0,.75h9a.38.38,0,1,0,0-.75Z" />
      </svg>
    </div>
  );
}

PublicationIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

PublicationIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
