import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchBubbleVisualizationOptionsEpic = action$ =>
  action$.pipe(
    ofType('bubbleVisualizationOptions/fetch'),
    map(({ payload }) => {
      return apiRequest({
        path: `/visualizations/options`,
        fulfilledType: 'bubbleVisualizationOptions/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default fetchBubbleVisualizationOptionsEpic;
