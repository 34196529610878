/** @jsxRuntime classic /
/** @jsx jsx */
/* eslint-disable */
import PropTypes from 'prop-types';
import { jsx, useTheme } from '@emotion/react';
import React, { useRef, createRef, useEffect, useState } from 'react';
import SearchIcon from '../Icons/SearchIcon';
import DownIcon from '../Icons/DownIcon';
import Select from '../Select/Select';
import SelectOption from '../SelectOption/SelectOption';
import './autocomplete.css';

//import Paper from '../paper/paper';
import Button from '../Button/Button';
// import ToggleFlyout from '../toggleFlyout/toggleFlyout';

const useKeyPress = function(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

function Autocomplete({
  onShowAllButtonClick,
  onOptionClicked,
  options,
  onInputFocus,
  onInputBlur,
  onClickOff,
  isOpen,
  inputPlaceholderText,
  onClear,
  onInputChange,
  inputValue,
  toggleFlyoutOptions,
  toggleFlyoutValue,
  toggleFlyoutOnOptionClicked,
  isLoading,
  ...props
}) {
  const theme = useTheme();
  const [isSeeAllButtonFresh, setIsSeeAllButtonFresh] = useState(true);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(-1);
  const [hovered, setHovered] = useState(undefined);
  const [numberOfOptionsToDisplay, setNumberOfOptionsToDisplay] = useState(20);

  const jss = {
    container: {
      position: 'relative',
      border: `0.75px solid ${
        isOpen
          ? theme.palette.borders.loud.default
          : theme.palette.borders.regular.default
      }`,
      borderRadius:
        isOpen && showAllResultsButton() === false
          ? '17px 17px 0px 0px'
          : '17px'
      //  height: isOpen && !showAllResultsButton() ? '50px' : 'auto',
      //  marginTop: isOpen && !showAllResultsButton() ? '16px' : '0px',
    },
    inputContainer: {
      display: 'flex',
      margin: '0 8px'
    },
    suffixAdornment: {
      display: 'flex',
      alignItems: 'center',
      visibility: inputValue.length > 0 ? 'vissible' : 'hidden'
    },
    prefixAdornment: {
      borderRight: `0.5px solid ${theme.palette.borders.regular.default}`,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    toggleFlyout: {
      marginLeft: '10px'
    },
    optionPaper: {
      cursor: 'pointer',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '107px',
      paddingRight: '15px',
      [theme.breakPoints[0]]: {
        paddingLeft: '15px'
      },
      color: theme.palette.copy.regular.default
    },
    optionPaperActive: {
      cursor: 'pointer',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '107px',
      paddingRight: '15px',

      [theme.breakPoints[0]]: {
        paddingLeft: '15px'
      },
      color: theme.palette.copy.regular.hover,
      backgroundColor: theme.palette.background.light.hover,

      ':active': {
        color: theme.palette.copy.regular.active,
        backgroundColor: theme.palette.background.light.active
      }
    },
    optionName: {
      ...theme.typography.XSM,
      marginRight: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    messagePaper: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '107px',
      paddingRight: '15px',
      [theme.breakPoints[0]]: {
        paddingLeft: '15px'
      },
      color: theme.palette.copy.light.default
    },
    messageText: {
      ...theme.typography.XSM,
      marginRight: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    optionCategory: {
      ...theme.typography.XXS
    },
    innerOptionContainer: {
      maxHeight: '302px',
      overflowY: 'scroll'
    },
    optionContainer: {
      borderBottomRightRadius: '17px',
      borderBottomLeftRadius: '17px',
      overflow: 'hidden',
      width: '100%',
      position: 'absolute',
      background: 'white',
      zIndex: '999',
      marginTop: '-20px',
      marginLeft: '-1px',
      transform: 'translate3d(0px, 15px, 0px)',
      border: `0.75px solid ${theme.palette.borders.loud.default}`,
      borderTop: 'none'
    },
    seeAllPaper: {
      position: 'absolute',
      display: 'flex',
      padding: '7px 46px',
      [theme.breakPoints[0]]: {
        padding: '7px 14px'
      },
      zIndex: '999',
      marginTop: '10px',
      borderRadius: '17px'
    },
    input: {
      all: 'unset',
      boxSizing: 'border-box',
      borderRadius: '20px',
      height: '32px',
      width: '100%',
      background: theme.palette.background.light.default,
      color: theme.palette.primary.dark.default,
      border: 'none',
      paddingTop: '7px',
      paddingBottom: '6px',
      ...theme.typography.S,
      paddingLeft: '15px',
      '::placeholder': {
        opacity: '1',
        color: theme.palette.primary.regular.default,
        whiteSpace: 'nowrap'
      },
      ':hover': {
        '::placeholder': {
          opacity: '1',
          color: theme.palette.primary.regular.hover
        }
      },
      ':active': {
        '::placeholder': {
          opacity: '1',
          color: theme.palette.primary.regular.active
        }
      },
      ':focus': {
        '::placeholder': {
          opacity: '0'
        }
      },
      ':disabled': {
        '::placeholder': {
          opacity: '1',
          color: theme.palette.primary.regular.disabled
        }
      }
    }
  };

  const optionsContainer = useRef(null);
  const container = useRef(null);
  const refs = options.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (options.length && downPress) {
      setCursor(prevState =>
        prevState < options.length - 2 ? prevState + 1 : prevState
      );
      if (refs[options[cursor + 1]?.id]?.current) {
        refs[options[cursor + 1]?.id]?.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [downPress]);

  useEffect(() => {
    if (options.length && upPress) {
      setCursor(prevState => (prevState > -1 ? prevState - 1 : prevState));
      if (refs[options[cursor - 1]?.id]?.current) {
        refs[options[cursor - 1]?.id]?.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [upPress]);

  useEffect(() => {
    if (enterPress) {
      if (cursor > -1 && options.length) {
        onOptionClicked(options[cursor]);
      } else if (inputValue?.length > 0) {
        onOptionClicked({
          label: inputValue,
          value: inputValue,
          id: inputValue,
          weight: 1,
          topic_type: 'text'
        });
      }
    }
  }, [cursor, enterPress, inputValue]);

  useEffect(() => {
    if (options.length && hovered) {
      setCursor(options.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function handleClickOutside(e) {
    if (container.current && container.current.contains(e.target)) {
      return;
    }
    onClickOff();
  }

  function onOptionsContainerScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setNumberOfOptionsToDisplay(numberOfOptionsToDisplay + 50);
    }
  }

  function lightenMatchedSubstring(str, substr) {
    return str.replace(
      new RegExp(substr, 'i'),
      str =>
        `<span style="color:${theme.palette.copy.light.default}">${str}</span>`
    );
  }

  function showAllResultsButton() {
    return (
      options.length === 0 &&
      isOpen &&
      inputValue.length < 3 &&
      !isLoading &&
      isSeeAllButtonFresh
    );
  }

  function showNoResultsText() {
    return (
      options.length === 0 && isOpen && inputValue.length >= 3 && !isLoading
    );
  }

  function _onShowAllButtonClick() {
    setIsSeeAllButtonFresh(false);
    onShowAllButtonClick();
  }

  function _onInputFocus() {
    setIsSeeAllButtonFresh(true);
    onInputFocus();
  }

  return (
    <div css={jss.container} {...props} ref={container}>
      <div css={jss.inputContainer}>
        <div css={jss.prefixAdornment}>
          <div>
            <SearchIcon onClick={onClear} />
          </div>

          {/* <ToggleFlyout
            css={jss.toggleFlyout}
            options={toggleFlyoutOptions}
            optionsLabel="Include"
            value={toggleFlyoutValue}
            onOptionClicked={toggleFlyoutOnOptionClicked}
            data-id="topic-autocomplete-toggle"
          /> */}
          <div style={{ marginBottom: '4px' }}>
            <Select
              selected={toggleFlyoutOptions.find(
                p => p.value === toggleFlyoutValue
              )}
              onChange={e => toggleFlyoutOnOptionClicked(e)}
              className="autocomplete__select"
            >
              {toggleFlyoutOptions.map(ct => (
                <SelectOption value={ct.value} key={ct.value}>
                  {ct.name}
                </SelectOption>
              ))}
            </Select>
          </div>
        </div>

        <input
          type="text"
          css={jss.input}
          value={inputValue}
          placeholder={inputPlaceholderText}
          onChange={onInputChange}
          onFocus={_onInputFocus}
          onBlur={onInputBlur}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
        <div css={jss.suffixAdornment}></div>
      </div>
      {isOpen && !showAllResultsButton() ? (
        <div css={jss.optionContainer}>
          <div
            css={jss.innerOptionContainer}
            ref={optionsContainer}
            onScroll={e => onOptionsContainerScroll(e)}
          >
            <span>
              <div
                tabIndex="0"
                css={-1 === cursor ? jss.optionPaperActive : jss.optionPaper}
                onClick={() =>
                  onOptionClicked({
                    label: inputValue,
                    value: inputValue,
                    id: inputValue,
                    weight: 1,
                    topic_type: 'text'
                  })
                }
                onMouseEnter={() =>
                  setHovered({
                    label: inputValue,
                    value: inputValue,
                    id: inputValue
                  })
                }
                onMouseLeave={() => setHovered(undefined)}
              >
                <span
                  css={jss.optionName}
                >{`${inputValue} (as plain text search)`}</span>
              </div>
            </span>

            {options.slice(0, numberOfOptionsToDisplay).map((option, index) => {
              return (
                <span
                  ref={refs[option.id]}
                  key={Math.random()
                    .toString(36)
                    .substr(2, 5)}
                >
                  <div
                    tabIndex="0"
                    clickable={false}
                    css={
                      index === cursor ? jss.optionPaperActive : jss.optionPaper
                    }
                    onClick={() => onOptionClicked(option)}
                    onMouseEnter={() => setHovered(option)}
                    onMouseLeave={() => setHovered(undefined)}
                  >
                    <span
                      css={jss.optionName}
                      dangerouslySetInnerHTML={{
                        __html: lightenMatchedSubstring(
                          option.label,
                          inputValue
                        )
                      }}
                    />
                    <span css={jss.optionCategory}>{option.category}</span>
                  </div>
                </span>
              );
            })}

            {isLoading ? (
              <div css={jss.messagePaper}>
                <span css={jss.messageText}>Loading...</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {showAllResultsButton() ? (
        <div
          css={jss.seeAllPaper}
          borderTop={1}
          borderBottom={1}
          borderLeft={1}
          borderRight={1}
          shadow="1"
        >
          <Button
            onClick={_onShowAllButtonClick}
            type="text"
            endIconType="next"
            iconColor="primary/dark"
            label="See All Results"
          />
        </div>
      ) : null}
    </div>
  );
}

Autocomplete.propTypes = {
  /**
   * The suggested options displayed in the Autocomplete
   */
  options: PropTypes.instanceOf(Array),
  /**
   * The event handler for when the show all button is clicked
   */
  onShowAllButtonClick: PropTypes.func,
  /**
   * The event handler for when one of the options is clicked
   */
  onOptionClicked: PropTypes.func,
  /**
   * The placeholder text for the Autocomplete's text area
   */
  inputPlaceholderText: PropTypes.string,
  /**
   * The value for the Autocomplete's text area
   */
  inputValue: PropTypes.string,
  /**
   * The event handler for when the Autocomplete's text area changes
   */
  onInputChange: PropTypes.func,
  /**
   * The event handler for when the Autocomplete's text area is focused
   */
  onInputFocus: PropTypes.func,
  /**
   * The event handler for when the Autocomplete's text area is blured
   */
  onInputBlur: PropTypes.func,
  /**
   * The event handler for when the Autocomplete is clicked off
   */
  onClickOff: PropTypes.func,
  /**
   * The event handler for when the 'X' IconButton is clicked
   */
  onClear: PropTypes.func,
  /**
   * The options displayed in the Autocomplete's ToggleFlyout
   */
  toggleFlyoutOptions: PropTypes.instanceOf(Array),
  /**
   * The value used to determine the currently selected option of the Autocomplete's ToggleFlyout
   */
  toggleFlyoutValue: PropTypes.string,
  /**
   * The event handler for when one of the Autocomplete's ToggleFlyout's options is clicked
   */
  toggleFlyoutOnOptionClicked: PropTypes.func,
  /**
   * Whether or not to show the Autocomplete in a loading state
   */
  isLoading: PropTypes.bool
};

Autocomplete.defaultProps = {
  onClickOff: () => {},
  onOptionClicked: () => {},
  onShowAllButtonClick: () => {},
  options: [],
  inputPlaceholderText: '',
  onClear: () => {},
  onInputChange: () => {},
  onInputFocus: () => {},
  inputValue: '',
  isOpen: false,
  toggleFlyoutOptions: [],
  toggleFlyoutValue: null,
  toggleFlyoutOnOptionClicked: () => {},
  isLoading: false
};

export default Autocomplete;
