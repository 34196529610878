/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { jsx } from '@emotion/react';
import { Sort, Paper } from '@artemisintelligence/aperture-component-library';
import { setProblemGroupOptions } from '../../../searchProblemGroupOptions/actions';
import { setPublicationOptions } from '../../../searchPublicationOptions/actions';
import { setApplicationOptions } from '../../../searchApplicationOptions/actions';
import { setOrganizationOptions } from '../../../searchOrganizationOptions/actions';
import { setCharacteristicOptions } from '../../../searchCharacteristicOptions/actions';

export default function ListResultsSort({ resultsType }) {
  const jss = {
    sortContainer: {
      height: '25px',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '20px'
    }
  };

  const { searchId } = useParams();

  const dispatch = useDispatch();

  const sort = useSelector(
    state => state.results.bySearch[searchId]?.[resultsType]?.sort
  );

  const sortSelectOptions = () => {
    return sort?.options?.map(x => {
      return { label: x.humanKey, value: x.id };
    });
  };

  let setOptions;
  if (resultsType === 'problems') {
    setOptions = setProblemGroupOptions;
  } else if (resultsType === 'publications') {
    setOptions = setPublicationOptions;
  } else if (resultsType === 'applications') {
    setOptions = setApplicationOptions;
  } else if (resultsType === 'organizations') {
    setOptions = setOrganizationOptions;
  } else if (resultsType === 'painpoints') {
    setOptions = setCharacteristicOptions;
  }

  const sortSelectValue = () => {
    const currentSortWithHumanKey = sort?.options?.find(
      x => x.id === sort?.currentSort.id
    );
    return currentSortWithHumanKey?.id;
  };

  const sortRadioGroupValue = () => {
    return sort?.currentOrder;
  };

  const onSortRadioGroupValueChange = selectedValue => {
    const currentSortObj = (({ id }) => ({ id }))(sort?.currentSort);
    const newSearchOptions = {
      currentSort: currentSortObj,
      currentOrder: selectedValue.value
    };
    dispatch(setOptions(newSearchOptions));
  };

  const onSortSelectValueClicked = selectedValue => {
    const newSearchOptions = {
      currentSort: {
        id: selectedValue.value
      },
      currentOrder: sort.currentOrder
    };

    dispatch(setOptions(newSearchOptions));
  };

  return (
    <Paper css={jss.sortContainer} color="background/light">
      <Sort
        sortOptions={sortSelectOptions()}
        sortOptionsValue={sortSelectValue()}
        orderOptions={[
          { label: 'Ascending', value: 'asc' },
          { label: 'Descending', value: 'desc' }
        ]}
        orderValue={sortRadioGroupValue()}
        onOrderOptionClicked={onSortRadioGroupValueChange}
        onSortOptionClicked={onSortSelectValueClicked}
        data-id="sort-select"
      />
    </Paper>
  );
}
