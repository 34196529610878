/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function problemGroupsReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'problemGroups/fetch': {
        const { id } = action.payload;

        nextState.byId[id] ??= {};
        nextState.byId[id]._status = 'loading';
        return nextState;
      }
      case 'problemGroups/fetchFulfilled': {
        const { id } = action.payload.metadata.sourcePayload;
        nextState.byId[id] ??= {};

        if (action.error) {
          nextState.byId[id]._status = 'failure';
          return nextState;
        }

        nextState.byId[id]._status = 'success';

        const { response } = action.payload;

        nextState.byId[id].title = response.title?.text;
        nextState.byId[id].markets = response.markets;
        nextState.byId[id].organizations = response.organizations;
        nextState.byId[id].corpus = response.corpus;
        nextState.byId[id].allDates = response.all_dates;

        return nextState;
      }
      case 'problemGroups/fetchRelatedProblemGroups': {
        const { id } = action.payload;

        nextState.byId[id] ??= {};
        nextState.byId[id].relatedProblemGroups ??= {};
        const relatedProblemGroupsObject =
          nextState.byId[id].relatedProblemGroups;

        relatedProblemGroupsObject._status = 'loading';

        return nextState;
      }
      case 'problemGroups/fetchRelatedProblemGroupsFulfilled': {
        const { id } = action.payload.metadata.sourcePayload;

        nextState.byId[id] ??= {};
        nextState.byId[id].relatedProblemGroups ??= {};
        const relatedProblemGroupsObject =
          nextState.byId[id].relatedProblemGroups;

        if (action.error) {
          relatedProblemGroupsObject._status = 'failure';
          return nextState;
        }

        const { response } = action.payload;

        relatedProblemGroupsObject._status = 'success';
        relatedProblemGroupsObject.total = response.total;
        relatedProblemGroupsObject.problemGroups = response.documents;

        return nextState;
      }
      case 'problemGroups/fetchAssociatedDocuments': {
        const { id, page } = action.payload;

        nextState.byId[id] ??= {};
        nextState.byId[id].associatedDocuments ??= {};
        nextState.byId[id].associatedDocuments.pages ??= {};
        nextState.byId[id].associatedDocuments.pages[page] ??= {};
        const associatedDocumentsObject =
          nextState.byId[id].associatedDocuments.pages[page];

        associatedDocumentsObject._status = 'loading';

        return nextState;
      }
      case 'problemGroups/fetchAssociatedDocumentsFulfilled': {
        const { id, page } = action.payload.metadata.sourcePayload;
        const { response } = action.payload;

        if (action.error) {
          return nextState;
        }

        let associatedDocumentsObject = {};

        nextState.byId[id] ??= {};
        nextState.byId[id].associatedDocuments ??= {};

        if (nextState.byId[id].associatedDocuments.size !== response.size) {
          nextState.byId[id].associatedDocuments = {};
        }

        nextState.byId[id].associatedDocuments.pages ??= {};
        nextState.byId[id].associatedDocuments.pages[page] ??= {};
        associatedDocumentsObject =
          nextState.byId[id].associatedDocuments.pages[page];

        nextState.byId[id].associatedDocuments.total = response.total;
        nextState.byId[id].associatedDocuments.size = response.size;

        associatedDocumentsObject._status = 'success';
        associatedDocumentsObject.start = response.start;
        associatedDocumentsObject.associatedDocuments = response.data;

        return nextState;
      }
      default: {
        return nextState;
      }
    }
  });
}
