/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { jsx } from '@emotion/react';
import {
  Paper,
  Copy,
  IconButton
} from '@artemisintelligence/aperture-component-library';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PaginationBlock from './organisms/paginationBlock';
import BookmarksList from './organisms/bookmarksList';

import { fetchPaginatedBookmarks } from '../../bookmarks/actions';

function SavedSearchesTemplate() {
  const jss = {
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '28px 36px'
    },
    paper: {
      borderRadius: '20px',
      width: '100%',
      margin: '24px'
    }
  };

  const [searchParams] = useSearchParams();
  const pageId = searchParams.get('page');
  const page = parseInt(pageId, 10) || 1;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaginatedBookmarks({ page }));
  }, [dispatch, page]);

  const navigate = useNavigate();

  return (
    <Paper
      shadow="1"
      rounded
      borderRight="1"
      borderLeft="1"
      borderTop="1"
      borderBottom="1"
      css={jss.paper}
    >
      <div css={jss.titleContainer}>
        <IconButton iconType="back" onClick={() => navigate(-1)} />
        <Copy element="h1" typographyStyle="XXL" color="copy/light">
          Saved Searches
        </Copy>
      </div>
      <PaginationBlock position="top" />
      <BookmarksList />
      <PaginationBlock position="bottom" />
    </Paper>
  );
}

export default SavedSearchesTemplate;
