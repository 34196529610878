import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import authEpic from './auth/epic';
import fetchDocumentEpic from './documentDisplay/epic';
import fetchBubbleVisualizationOptionsEpic from './bubbleVisualizationOptions/epic';
import fetchBubbleVisualizationEpic from './bubbleVisualization/epic';
import fetchFiltersEpic from './filters/epic';
import problemGroupEpic from './problemGroups/epic';
import resultProfileEpic from './resultProfile/epic';
import topicAutoCompleteEpic from './topicAutoComplete/epic';
import searchEpic from './search/epic';
import dateFiltersEpic from './dateFilters/epic';
import searchFiltersEpic from './searchFilters/epic';
import searchSpacesEpic from './searchSpaces/epic';
import searchTopicsEpic from './searchTopics/epic';
import {
  addBookmarkEpic,
  deleteBookmarkEpic,
  fetchBookmarksEpic,
  fetchPaginatedBookmarksEpic
} from './bookmarks/epic';
import { fetchAvaliableSpacesEpic } from './spaces/epic';
import searchPublicationOptionsEpic from './searchPublicationOptions/epic';
import searchProblemGroupOptionsEpic from './searchProblemGroupOptions/epic';
import searchOrganizationOptionsEpic from './searchOrganizationOptions/epic';
import searchApplicationOptionsEpic from './searchApplicationOptions/epic';
import searchCharacteristicOptionsEpic from './searchCharacteristicOptions/epic';
import { fetchResultsEpic } from './resultsDisplay/epic';
import searchIdEpic from './searchId/epic';
import apiEpic from './api/epic';

const rootEpic = (action$, store$) =>
  combineEpics(
    authEpic,
    apiEpic,
    fetchBookmarksEpic,
    fetchPaginatedBookmarksEpic,
    addBookmarkEpic,
    deleteBookmarkEpic,
    fetchFiltersEpic,
    fetchDocumentEpic,
    fetchResultsEpic,
    fetchAvaliableSpacesEpic,
    problemGroupEpic,
    resultProfileEpic,
    searchEpic,
    searchIdEpic,
    searchFiltersEpic,
    dateFiltersEpic,
    searchTopicsEpic,
    searchSpacesEpic,
    searchPublicationOptionsEpic,
    searchProblemGroupOptionsEpic,
    searchOrganizationOptionsEpic,
    searchCharacteristicOptionsEpic,
    searchApplicationOptionsEpic,
    topicAutoCompleteEpic,
    fetchBubbleVisualizationEpic,
    fetchBubbleVisualizationOptionsEpic
  )(action$, store$).pipe(
    catchError((error, source) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return source;
    })
  );

export default rootEpic;
