export function addBookmark({ searchId, name }) {
  return {
    type: 'bookmarks/add',
    payload: { searchId, name }
  };
}

export function deleteBookmark({ bookmarkKey }) {
  return {
    type: 'bookmarks/delete',
    payload: { bookmarkKey }
  };
}

export function fetchBookmarks() {
  return {
    type: 'bookmarks/fetch'
  };
}

export function fetchPaginatedBookmarks({ page, size = 20 }) {
  let start = (page - 1) * size;
  start = start < 0 ? 0 : start;
  return {
    type: 'bookmarks/fetchPaginatedBookmarks',
    payload: { page, start, size }
  };
}
