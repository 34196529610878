import { ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchFiltersEpic = (action$, state$) =>
  action$.pipe(
    ofType('filters/fetch'),
    filter(({ payload }) => {
      const { topicId, searchId } = payload;
      return (
        state$.value?.filters.bySearchId[searchId]?.[topicId]?._status !==
        'success'
      );
    }),
    map(({ payload }) => {
      const { topicId, searchId } = payload;
      return apiRequest({
        path: `/filters/?search_id=${searchId}&topic_id=${topicId}`,
        fulfilledType: 'filters/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default fetchFiltersEpic;
