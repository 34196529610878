import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Subtitle from './organisms/subtitle';
import Title from './organisms/title';
import Meta from './organisms/meta';

// import Description from './organisms/description';
import Dates from './organisms/dates';
import Organizations from './organisms/organizations';
import PainPoints from './organisms/painPoints';
import Summary from './organisms/summary';

import Topics from './organisms/topics';
import { getResultProfile } from '../../resultProfile/actions';
import {
  ErrorMessage,
  Spinner,
  Accordian,
  AccordianDetails,
  AccordianSummary
} from '../../apex';
import './css/resultProfileTemplate.css';

export default function Template({ profileType, profileEndpoint }) {
  const [searchParams] = useSearchParams();

  const resultId = searchParams.get('resultId');
  const id = resultId;

  const filtered = searchParams.get('filtered') === 'true';
  const searchIdFromURL = searchParams.get('search') || 'default';
  const dispatch = useDispatch();

  const status = useSelector(state => state.resultProfile.byId?.[id]?._status);

  useEffect(() => {
    const searchId = searchIdFromURL;
    dispatch(getResultProfile(id, searchId, profileEndpoint));
  }, [dispatch, id, filtered, searchIdFromURL]);

  if (status === 'failure') {
    return <ErrorMessage />;
  }
  if (status === 'loading') {
    return <Spinner width="72px" summary="true" />;
  }

  return (
    <div className="result-profile__outer-container">
      <Subtitle
        id={id}
        profileType={profileType}
        profileEndpoint={profileEndpoint}
      />
      <div className="result-profile__header">
        <div>
          <Title id={id} />
          <Meta id={id} />
        </div>
        <div className="result-profile__dates-container ">
          <Dates id={id} />
        </div>
      </div>
      {/* <Accordian defaultOpen>
        {({ open }) => (
          <>
            <AccordianSummary open={open}>
              <h2 className="result-profile__section-title">Description</h2>
            </AccordianSummary>
            <AccordianDetails>
              <div>
                <Description id={id} />
              </div>
            </AccordianDetails>
          </>
        )}
      </Accordian> */}
      <Accordian defaultOpen>
        {({ open }) => (
          <>
            <AccordianSummary open={open}>
              <h2 className="result-profile__section-title">Summary</h2>
            </AccordianSummary>
            <AccordianDetails>
              <div>
                <Summary id={id} />
              </div>
            </AccordianDetails>
          </>
        )}
      </Accordian>
      <Accordian defaultOpen>
        {({ open }) => (
          <>
            <AccordianSummary open={open}>
              <h2 className="result-profile__section-title">Related Items</h2>
            </AccordianSummary>
            <AccordianDetails>
              <div>
                <div className="result-profile__chart-outer-container">
                  <div className="result-profile__bar-chart-container">
                    <Organizations id={id} />
                  </div>
                  <div className="result-profile__bar-chart-container">
                    <PainPoints id={id} />
                  </div>
                  <div className="result-profile__bar-chart-container">
                    <Topics id={id} />
                  </div>
                </div>
              </div>
            </AccordianDetails>
          </>
        )}
      </Accordian>
    </div>
  );
}
