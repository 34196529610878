/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function searchSpacesReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchId/fetchFulfilled':
      case 'searchId/updateFulfilled': {
        if (action.error) {
          return nextState;
        }
        return nextState;
      }
      case 'searchSpaces/add': {
        nextState.spaces.push(action.payload);
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
