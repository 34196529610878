import React from 'react';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';

function RadioGroup({ children, onChange, value, className }) {
  return (
    <HeadlessRadioGroup
      onChange={onChange}
      value={value}
      as="div"
      className={className}
    >
      {children}
    </HeadlessRadioGroup>
  );
}

export default RadioGroup;
