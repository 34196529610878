import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import { getDocument } from '../../documentDisplay/actions';
import PublicationTitle from './organisms/publicationTitle';
import PublicationAbstract from './organisms/publicationAbstract';
import PublicationProblems from './organisms/publicationProblems';
import PublicationTagsBreakdown from './organisms/publicationTagsBreakdown';
import './css/publicationProfileTemplate.css';
import { Logo, ErrorMessage, Spinner } from '../../apex';

export default function PublicationProfileTemplate() {
  const corpus = 'patents';
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get('search');

  const resultId = searchParams.get('resultId');
  const id = resultId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDocument({ corpus, id, searchId }));
  }, [dispatch, corpus, id]);

  const printViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    documentTitle: `Aperture_${id}`
  });

  const status = useSelector(
    state => state.documentReducer.byCorpus[corpus]?.[id]?._status
  );

  if (status === 'failure') {
    return <ErrorMessage />;
  }
  if (status === 'loading') {
    return <Spinner width="72px" />;
  }

  return (
    <div className="publication-profile-template__outer-container">
      <div style={{ display: 'none' }}>
        <div
          ref={printViewRef}
          className="publication-profile-template__print-view"
        >
          <Logo
            width={160}
            className="publication-profile-template__print-logo"
          />
          <PublicationTitle
            id={id}
            corpus={corpus}
            handlePrint={handlePrint}
            hideIcons
          />
          <PublicationAbstract id={id} corpus={corpus} />
          <PublicationProblems id={id} corpus={corpus} />
          <PublicationTagsBreakdown id={id} corpus={corpus} />
        </div>
      </div>
      <div className="publication-profile-template__container">
        <div className="publication-profile-template__main-content-area">
          <PublicationTitle id={id} corpus={corpus} handlePrint={handlePrint} />
          <PublicationAbstract id={id} corpus={corpus} />
          <PublicationProblems id={id} corpus={corpus} />
        </div>
        <div className="publication-profile-template__side-content-area">
          <PublicationTagsBreakdown id={id} corpus={corpus} />
        </div>
      </div>
    </div>
  );
}
