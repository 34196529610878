import { ofType } from 'redux-observable';
import { throwError, defer, of } from 'rxjs';
import { exhaustMap, map, concatMap, retryWhen, delay } from 'rxjs/operators';
import { authTokenSilently, authLoginRedirect } from './authenticator';

export default action$ =>
  action$.pipe(
    ofType('auth/tokenRequest'),
    exhaustMap(() =>
      defer(() => authTokenSilently()).pipe(
        map(token => {
          return { type: 'auth/tokenResponse', payload: { token } };
        }),
        retryWhen(errors =>
          errors.pipe(
            concatMap((error, i) => {
              if (error.error === 'login_required') {
                authLoginRedirect();
                return throwError(error);
              }
              if (i >= 3) {
                return throwError(error);
              }
              return of(delay(i * Math.ceil(Math.random() * 100)));
            })
          )
        )
      )
    )
  );
