import React from 'react';
import { useSelector } from 'react-redux';
import { AccordianDetails, Accordian, AccordianSummary } from '../../../apex';
import '../css/publicationAbstract.css';

export default function PublicationAbstract({ id, corpus }) {
  const abstract = useSelector(state => {
    return state.documentReducer.byCorpus[corpus]?.[id]?.abstract;
  });

  if (!abstract) {
    return null;
  }

  return (
    <Accordian defaultOpen className="publication-abstract__container">
      {({ open }) => (
        <>
          <AccordianSummary open={open}>
            <h2 className="publication-abstract__title">Abstract</h2>
          </AccordianSummary>

          <AccordianDetails>
            <div className="publication-abstract__details-container">
              {abstract.map(tagsAndText => {
                return <span key={tagsAndText.text}>{tagsAndText.text}</span>;
              })}
            </div>
          </AccordianDetails>
        </>
      )}
    </Accordian>
  );
}
