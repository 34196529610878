/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function resultsReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'bubbleVisualizationOptions/fetch': {
        nextState._status = 'loading';
        return nextState;
      }
      case 'bubbleVisualizationOptions/fetchFulfilled': {
        if (action.error) {
          nextState._status = 'failure';
          nextState.errorMessage = action.payload?.message;
          return nextState;
        }
        const { response } = action.payload;
        nextState.data = response.data;
        const status = action.error ? 'failure' : 'success';
        nextState._status = status;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
