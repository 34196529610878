import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '../../../apex';

export default function PainPoints({ id }) {
  const painPoints = useSelector(
    state => state.resultProfile.byId?.[id]?.characteristics
  );

  if (painPoints === undefined) {
    return null;
  }

  const painPointsSorted = [...painPoints].sort(function(a, b) {
    return b.count - a.count;
  });

  const organizationNames = painPointsSorted
    ?.map(function(organization) {
      return organization.name;
    })
    .slice(0, 25);

  const organizationCounts = painPointsSorted
    ?.map(function(organization) {
      return organization.count;
    })
    .slice(0, 25);

  return (
    <>
      <div className="result-profile__bar-chart-title">Pain Points</div>
      <div className="result-profile__bar-chart-subtitle">
        By Number of Mentions
      </div>
      <BarChart
        seriesName="# of publications"
        seriesColorPalette="status3/light"
        xAxisCategories={organizationNames}
        data={organizationCounts}
      />
    </>
  );
}
