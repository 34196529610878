import React from 'react';
import PropTypes from 'prop-types';

export default function ListIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M6.65,6.51H21a.37.37,0,0,0,.38-.37A.38.38,0,0,0,21,5.76H6.65a.38.38,0,0,0-.37.38A.37.37,0,0,0,6.65,6.51Z" />
        <path d="M21,11.63H6.65a.37.37,0,0,0-.37.37.38.38,0,0,0,.37.38H21a.38.38,0,0,0,.38-.38A.37.37,0,0,0,21,11.63Z" />
        <path d="M21,17.49H6.65a.37.37,0,0,0-.37.37.38.38,0,0,0,.37.38H21a.38.38,0,0,0,.38-.38A.37.37,0,0,0,21,17.49Z" />
        <circle cx="3.52" cy="6.14" r="0.5" />
        <circle cx="3.52" cy="17.86" r="0.5" />
        <circle cx="3.52" cy="12" r="0.5" />
      </svg>
    </div>
  );
}

ListIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

ListIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
