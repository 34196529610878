import React from 'react';
import { useSelector } from 'react-redux';

export default function Summary({ id }) {
  const summary = useSelector(state => state.resultProfile.byId?.[id]?.summary);
  return summary ? (
    <div className="result-profile__summary">
      {summary.map(s => (
        <div key={s}>{s}</div>
      ))}
    </div>
  ) : (
    'No summary available at this time.'
  );
}
