import React from 'react';
import moment from 'moment';
import '../css/footerSection.css';

function FooterSection() {
  return (
    <footer className="footer-section__footer">
      {`© Aperture ${moment().format('YYYY')}`}
    </footer>
  );
}

export default FooterSection;
