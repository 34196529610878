import { Tab } from '@headlessui/react';
import React from 'react';

function TabGroup({ children, selectedIndex, onChange, className }) {
  return (
    <Tab.Group
      className={className}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      {children}
    </Tab.Group>
  );
}

export default TabGroup;
