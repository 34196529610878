/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import cloneDeep from 'clone-deep';
import {
  Paper,
  Copy,
  Pagination
} from '@artemisintelligence/aperture-component-library';
import { jsx, useTheme } from '@emotion/react';
import formatNumber from '../../../formatNumber';

export default function ListResultsPaginationHeader({ position, resultsType }) {
  const theme = useTheme();
  const jss = {
    paginationBar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '20px',
      height: '50px',
      [theme.breakPoints[0]]: {
        justifyContent: 'flex-end'
      }
    },
    resultCountLabel: {
      textTransform: 'capitalize',
      margin: 'auto 0px',
      [theme.breakPoints[0]]: {
        display: 'none'
      }
    }
  };

  const { searchId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page'), 10) || 1;

  const total = useSelector(
    state => state.results.bySearch[searchId]?.[resultsType]?.total
  );

  const start = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultsType]?.pages?.[page]?.start
  );

  const pageSize = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultsType]?.pages?.[page]?.size
  );

  const results = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultsType]?.pages?.[page]?.results
  );

  const numberOfResultsDisplayed = results?.length;

  const getTotalLabel = () => {
    const startNumber = parseInt(start, 10);

    let typeLabel;

    switch (resultsType) {
      case 'problems':
        typeLabel = 'Opportunities';
        break;
      case 'painpoints':
        typeLabel = 'Pain Points';
        break;
      case 'applications':
        typeLabel = 'Topics';
        break;
      case 'organizations':
        typeLabel = 'Organizations';
        break;
      default:
        typeLabel = 'Publications';
    }

    if (total === 0 && numberOfResultsDisplayed === 0) {
      return `0 - 0 of 0 ${typeLabel} Found`;
    }

    if (
      !((startNumber || startNumber === 0) && total && numberOfResultsDisplayed)
    ) {
      return null;
    }
    if (total >= 10000) {
      return `${formatNumber(startNumber + 1)} - ${formatNumber(
        startNumber + numberOfResultsDisplayed
      )} of
      ${formatNumber(total)} ${typeLabel} Found`;
    }
    return `${formatNumber(startNumber + 1)} - ${formatNumber(
      startNumber + numberOfResultsDisplayed
    )} of
        ${formatNumber(total)} ${typeLabel} Found`;
  };

  const [componentPage, setComponentPage] = useState(page);

  useEffect(() => {
    setComponentPage(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [componentPage]);

  const totalIntegerResults = parseInt(total, 10);

  const integerSize = parseInt(pageSize, 10);

  const totalPages =
    Math.floor(totalIntegerResults / integerSize) +
    (totalIntegerResults % integerSize ? 1 : 0);

  const setPage = newPage => {
    const newParams = cloneDeep(searchParams);
    newParams.set('page', newPage);
    setSearchParams(newParams);
    setComponentPage(newPage);
  };

  // useEffect(() => {
  //   setPage(1);
  // }, [resultsType]);

  function onNextPageButtonClick() {
    setPage(page + 1);
  }

  function onPreviousPageButtonClick() {
    setPage(page - 1);
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function onPageTextBlur(e) {
    const newPage = e.target.value;
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    } else {
      setComponentPage(page);
    }
  }

  function onPaginationInputChange(e) {
    const newPage = e.target.value;
    setComponentPage(newPage);
  }

  const dataId = `list-results-pagination-bar-${position}`;

  return (
    <Paper
      borderTop={1}
      borderBottom={1}
      css={jss.paginationBar}
      data-id={dataId}
    >
      <Copy
        typographyStyle="S"
        element="span"
        color="copy/light"
        css={jss.resultCountLabel}
      >
        {getTotalLabel()}
      </Copy>
      {totalPages >= 0 ? (
        <Pagination
          total={totalPages}
          inputValue={componentPage}
          onInputKeyDown={onKeyDown}
          onInputChange={onPaginationInputChange}
          onInputBlur={onPageTextBlur}
          onPreviousClick={onPreviousPageButtonClick}
          onNextClick={onNextPageButtonClick}
          nextDisabled={componentPage === totalPages}
          previousDisabled={componentPage === 1}
        />
      ) : null}
    </Paper>
  );
}
