import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchResultProfileEpic = action$ =>
  action$.pipe(
    ofType('resultProfile/fetch'),
    map(({ payload }) => {
      const { id, searchId, resultType } = payload;
      const path = `details/${searchId}/${resultType}/${id}`;

      return apiRequest({
        path,
        fulfilledType: 'resultProfile/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default combineEpics(fetchResultProfileEpic);
