import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, PrintIcon } from '../../../apex';
import '../css/publicationTitle.css';

export default function PublicationTitle({
  id,
  corpus,
  handlePrint,
  hideIcons = false
}) {
  const _document = useSelector(state => {
    return state.documentReducer.byCorpus[corpus]?.[id];
  });

  return (
    <div className="publication-title__container">
      <div className="publication-title__sub-container">
        <div>
          <span className="publication-title__subtitle">
            {_document?.corpus}
          </span>
          <h1 className="publication-title__title">
            {_document?.title?.text?.toLowerCase()}
          </h1>
        </div>
      </div>
      {!hideIcons ? (
        <div>
          <IconButton onClick={handlePrint}>
            <PrintIcon handlePrint={handlePrint} />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
}
