import React from 'react';
import PropTypes from 'prop-types';

export default function BackIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M19.6,11.62H4.83L9.7,6.77a.38.38,0,0,0-.54-.54l-5.5,5.5a.4.4,0,0,0,0,.54l5.5,5.5a.41.41,0,0,0,.54,0,.4.4,0,0,0,0-.54L4.83,12.37H19.6A.37.37,0,0,0,20,12,.38.38,0,0,0,19.6,11.62Z" />
      </svg>
    </div>
  );
}

BackIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

BackIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
