/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function sortReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchPublicationOptions/set': {
        nextState.publicationOptions = action.payload.publicationOptions;
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
