// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import cloneDeep from 'clone-deep';
import { Select, SelectOption, ErrorMessage } from '../../../apex';
import { getBubbleVisualization } from '../../../bubbleVisualization/actions';
import '../css/explore.css';

function ExploreSelects({ resultType }) {
  const { searchId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const bubbleVisualizationStore = useSelector(
    state => state.bubbleVisualizationReducer
  );

  const bubbleVisualizationOptionsStore = useSelector(
    state => state.bubbleVisualizationOptionsReducer
  );

  const status = bubbleVisualizationStore._status;

  const dispatch = useDispatch();

  const queryParams = cloneDeep(searchParams);

  const resultTypeId = resultType?.value;

  const bc = queryParams.get('bubbleCount') || bubbleVisualizationStore.bc;
  let x = queryParams.get('x') || bubbleVisualizationStore.x;
  const y = queryParams.get('y') || bubbleVisualizationStore.y;
  const z = queryParams.get('z') || bubbleVisualizationStore.z;

  useEffect(() => {
    console.log(resultTypeId);
    if (bubbleVisualizationOptionsStore._status === 'success') {
      if (!queryParams.has('x')) {
        if (
          bubbleVisualizationOptionsStore.data.some(
            e => e.id === 'company_fit'
          ) &&
          bubbleVisualizationStore._status === 'initial'
        ) {
          x = 'company_fit';
        }
        queryParams.set('x', x);
      }
      if (!queryParams.has('y')) {
        queryParams.set('y', y);
      }
      if (!queryParams.has('z')) {
        queryParams.set('z', z);
      }

      if (!queryParams.has('bubbleCount')) {
        queryParams.set('bubbleCount', bc);
      }

      queryParams.sort();
      setSearchParams(queryParams);
      dispatch(
        getBubbleVisualization({
          searchId,
          topicId: resultTypeId,
          x,
          y,
          z
        })
      );
    }
  }, [resultTypeId, searchId, bubbleVisualizationOptionsStore._status]);

  const setDropdown = (value, dropDownType) => {
    const newParams = cloneDeep(searchParams);
    switch (dropDownType) {
      // case 'topic_id':
      //   newParams.set('topic_id', value);
      //   newParams.sort();
      //   setSearchParams(newParams);
      //   dispatch(
      //     getBubbleVisualization({
      //       searchId,
      //       topicId: value,
      //       x,
      //       y,
      //       z
      //     })
      //   );
      //   break;
      case 'x':
        newParams.set('x', value);
        newParams.sort();
        setSearchParams(newParams);
        dispatch(
          getBubbleVisualization({
            searchId,
            topicId: resultTypeId,
            x: value,
            y,
            z
          })
        );
        break;
      case 'y':
        newParams.set('y', value);
        newParams.sort();
        setSearchParams(newParams);
        dispatch(
          getBubbleVisualization({
            searchId,
            topicId: resultTypeId,
            x,
            y: value,
            z
          })
        );
        break;
      case 'bubbleCount':
        newParams.set('bubbleCount', value);
        newParams.sort();
        setSearchParams(newParams);
        // setBubbleCount(value);
        break;
      default:
        newParams.set('z', value);
        newParams.sort();
        setSearchParams(newParams);
        dispatch(
          getBubbleVisualization({
            searchId,
            topicId: resultTypeId,
            x,
            y,
            z: value
          })
        );
        break;
    }
  };

  if (status === 'failure') {
    return <ErrorMessage />;
  }

  const axisOptions = bubbleVisualizationOptionsStore.data.map(q => ({
    name: q.humanKey,
    value: q.id
  }));

  const bubbleCountOptions = [
    { name: '25', value: 25 },
    { name: '50', value: 50 },
    { name: '100', value: 100 },
    { name: '1000', value: 1000 }
  ];

  return (
    <>
      <Select
        label="Bubble Count"
        // eslint-disable-next-line
        selected={bubbleCountOptions.find(p => p.value == bc)}
        onChange={e => setDropdown(e, 'bubbleCount')}
      >
        {bubbleCountOptions.map(bco => (
          <SelectOption value={bco.value} key={bco.value}>
            {bco.name}
          </SelectOption>
        ))}
      </Select>
      <Select
        label="Horizontal Axis"
        selected={axisOptions.find(p => p.value === x)}
        onChange={e => setDropdown(e, 'x')}
      >
        {axisOptions.map(xo => (
          <SelectOption value={xo.value} key={xo.value}>
            {xo.name}
          </SelectOption>
        ))}
      </Select>

      <Select
        label="Vertical Axis"
        selected={axisOptions.find(p => p.value === y)}
        onChange={e => setDropdown(e, 'y')}
      >
        {axisOptions.map(yo => (
          <SelectOption value={yo.value} key={yo.value}>
            {yo.name}
          </SelectOption>
        ))}
      </Select>

      <Select
        label="Size"
        selected={axisOptions.find(p => p.value === z)}
        onChange={e => setDropdown(e, 'z')}
      >
        {axisOptions.map(zo => (
          <SelectOption value={zo.value} key={zo.value}>
            {zo.name}
          </SelectOption>
        ))}
      </Select>
    </>
  );
}

export default ExploreSelects;
