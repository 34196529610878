import React from 'react';
import { useSelector } from 'react-redux';
import { AccordianDetails, Accordian, AccordianSummary } from '../../../apex';
import TopicFilterElements from './topicFilterElements';
import '../css/filtersGroup.css';

export default function FiltersGroup({ topicId, topLevelId }) {
  const { humanKey } = useSelector(state => state.topics.byId[topicId]);

  const selectedFilters = useSelector(state => {
    return state.searchFilters?.filters;
  });

  const selectedFiltersCount = selectedFilters.filter(
    element => element.filter_type === topLevelId
  )?.length;

  const countLabel =
    selectedFiltersCount > 0 ? `( ${selectedFiltersCount} )` : null;

  return (
    <div
      className="filters-group"
      borderBottom={1}
      color="background/regular"
      key={humanKey}
    >
      <Accordian defaultOpen={false}>
        {({ open }) => (
          <>
            <AccordianSummary
              open={open}
              className="filters-group__accordian-summary"
            >
              <div className="filters-group__title-count-container">
                <h2 className="filters-group__title">{humanKey}</h2>
                <div className="filters-group__count">{countLabel}</div>
              </div>
            </AccordianSummary>
            <AccordianDetails>
              <div className="filters-group__container">
                <TopicFilterElements
                  topicId={topicId}
                  topLevelId={topLevelId}
                />
              </div>
            </AccordianDetails>
          </>
        )}
      </Accordian>
    </div>
  );
}
