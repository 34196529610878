import React from 'react';
import Header from './organisms/header';
import FooterSection from './organisms/footerSection';
import './css/wrapperTemplate.css';

function WrapperTemplate({ children }) {
  return (
    <div className="wrapper-template__container">
      <Header />
      <section className="wrapper-template__content-section">
        {children}
      </section>
      <FooterSection />
    </div>
  );
}

export default WrapperTemplate;
