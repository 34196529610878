/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function resultProfile(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'resultProfile/fetch': {
        const { id } = action.payload;
        nextState.byId[id] ??= {};
        nextState.byId[id]._status = 'loading';
        return nextState;
      }
      case 'resultProfile/fetchFulfilled': {
        const { id } = action.payload.metadata.sourcePayload;
        nextState.byId[id] ??= {};

        if (action.error) {
          nextState.byId[id]._status = 'failure';
          return nextState;
        }
        nextState.byId[id]._status = 'success';
        const { response } = action.payload;
        nextState.byId[id] = response;
        return nextState;
      }
      default: {
        return nextState;
      }
    }
  });
}
