/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { jsx, useTheme } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chip,
  ShowMoreLess,
  Paper,
  Copy,
  Badge,
  Button
} from '@artemisintelligence/aperture-component-library';
import {
  removeSearchFilter,
  resetSearchFilters
} from '../../../searchFilters/actions';

function AppliedFiltersDisplay() {
  const theme = useTheme();

  const jss = {
    chipContainer: {
      marginTop: '25px'
    },
    chip: {
      marginBottom: '5px',
      marginRight: '5px'
    },
    chipShowMoreLess: {
      paddingTop: '5px'
    },
    badgeAndButton: {
      cursor: 'pointer',
      display: 'flex',
      '&:hover': {
        '& div': {
          color: theme.palette.primary.dark.hover,
          backgroundColor: theme.palette.background.regular.hover,
          border: `0.75px solid ${theme.palette.borders.dark.hover}`
        },
        '& button': {
          color: theme.palette.primary.dark.hover
        }
      },
      '&:active': {
        '& div': {
          color: theme.palette.primary.dark.active,
          backgroundColor: theme.palette.background.regular.active,
          border: `0.75px solid ${theme.palette.borders.dark.active}`
        },
        '& button': {
          color: theme.palette.primary.dark.active
        }
      }
    },
    container: {
      padding: '25px 25px 10px 25px'
    },
    flexContainer: {
      backgroundColor: theme.palette.background.regular.default,
      display: 'flex',
      justifyContent: 'space-between'
    },
    badge: {
      margin: 'auto 0px auto 5px'
    }
  };

  const dispatch = useDispatch();

  const filters = useSelector(state => {
    return state.searchFilters?.filters;
  });

  const topics = useSelector(state => state.topics.byId);

  if (!filters) {
    return null;
  }

  const filtersCount = filters?.length || 0;

  const removeFilter = filterId => {
    dispatch(removeSearchFilter({ data_value: filterId }));
  };

  const chips = filters.slice(0, 6).map(filter => {
    const humanKey = topics?.[filter.id]?.humanKey;
    if (!humanKey) {
      return null;
    }

    return (
      <Chip
        key={filter.id}
        css={jss.chip}
        onDelete={() => removeFilter(filter.id)}
        type="outlined"
        label={humanKey}
      />
    );
  });

  const showMoreLessChips = filters.slice(6).map(filter => {
    const humanKey = topics?.[filter.id]?.humanKey;
    if (!humanKey) {
      return null;
    }

    return (
      <Chip
        key={filter.id}
        css={jss.chip}
        onDelete={() => removeFilter(filter.id)}
        type="outlined"
        label={humanKey}
      />
    );
  });

  if (filtersCount === 0) {
    return (
      <Paper css={jss.container} borderTop={1} color="background/regular">
        <div css={jss.flexContainer}>
          <Copy typographyStyle="MC">Filters</Copy>
        </div>
      </Paper>
    );
  }
  return (
    <Paper css={jss.container} borderTop={1} color="background/regular">
      <div css={jss.flexContainer}>
        <Copy typographyStyle="MC">Filters</Copy>
        <span
          css={jss.badgeAndButton}
          role="button"
          tabIndex={0}
          onClick={() => dispatch(resetSearchFilters())}
          onKeyDown={() => dispatch(resetSearchFilters())}
        >
          <Button
            label="Clear"
            type="text"
            typographyStyle="XXS"
            textColor="primary/regular"
          />
          <Badge
            label={filtersCount}
            css={jss.badge}
            color="background/regular"
            type="outlined"
          />
        </span>
      </div>
      <div css={jss.chipContainer}>
        {chips}
        {filtersCount > 6 ? (
          <ShowMoreLess
            expandedTitle="Show fewer filters"
            collapsedTitle={`Show ${filtersCount - 6} more  ${
              filtersCount - 6 > 1 ? 'filters' : 'filter'
            }`}
            css={jss.chipShowMoreLess}
          >
            {showMoreLessChips}
          </ShowMoreLess>
        ) : null}
      </div>
    </Paper>
  );
}

export default AppliedFiltersDisplay;
