/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function documentReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'document/fetch': {
        const { corpus, id } = action.payload;
        nextState.byCorpus[corpus] = nextState.byCorpus[corpus] ?? {};
        nextState.byCorpus[corpus][id] = nextState.byCorpus[corpus][id] ?? {};
        nextState.byCorpus[corpus][id]._status = 'loading';
        return nextState;
      }
      case 'document/fetchFulfilled': {
        const status = action.error ? 'failure' : 'success';
        const { corpus, id } = action.payload.metadata.sourcePayload;
        const { response } = action.payload;
        nextState.byCorpus[corpus] = nextState.byCorpus[corpus] ?? {};
        nextState.byCorpus[corpus][id] = nextState.byCorpus[corpus][id] ?? {};
        nextState.byCorpus[corpus][id]._status = status;
        Object.assign(nextState.byCorpus[corpus][id], response);
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
