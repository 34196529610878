import React from 'react';
import PropTypes from 'prop-types';

export default function NewTabIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path
          fillRule="evenodd"
          d="M4.429 1.375a3.054 3.054 0 00-3.054 3.054V17.57a3.054 3.054 0 003.054 3.054H16.54a3.054 3.054 0 003.053-3.054V9.333a.375.375 0 01.75 0v8.238c0 2.101-1.702 3.804-3.803 3.804H4.429a3.804 3.804 0 01-3.804-3.804V4.43C.625 2.328 2.328.625 4.429.625h6.53a.375.375 0 010 .75h-6.53z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M16.527.78h3.285c.201 0 .365.172.365.384v3.463a.375.375 0 01-.365.385.375.375 0 01-.365-.385V2.093L7.897 14.27l-.515-.545L18.93 1.55h-2.404a.375.375 0 01-.365-.385c0-.212.164-.385.365-.385z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

NewTabIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

NewTabIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
