import React from 'react';
import PropTypes from 'prop-types';

export default function DownIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <g transform="matrix(2.5514,0,0,2.5514,-18.6168,-18.6108)">
          <path d="M16.6,9.57C16.529,9.498 16.431,9.457 16.33,9.457C16.229,9.457 16.131,9.498 16.06,9.57L12,13.64L7.94,9.57C7.869,9.498 7.771,9.457 7.67,9.457C7.569,9.457 7.471,9.498 7.4,9.57C7.262,9.719 7.262,9.951 7.4,10.1L11.73,14.43C11.88,14.574 12.12,14.574 12.27,14.43L16.6,10.1C16.738,9.951 16.738,9.719 16.6,9.57Z" />
        </g>
      </svg>
    </div>
  );
}

DownIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

DownIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
