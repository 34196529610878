import { ofType, combineEpics } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of, empty } from 'rxjs';

const updateSearchEpic = (action$, state$) =>
  action$.pipe(
    ofType('search/update'),
    mergeMap(() => {
      const searchId = state$.value.searchId.id;
      const search = state$.value.search.bySearchId?.[searchId];

      if (!searchId) {
        throw new Error('Cannot parse searchId for search/update');
      }

      const topics =
        search?.topics?.map(element => ({
          id: element.id,
          condition: element.condition,
          weight: element.weight,
          topic_type: element.topic_type
        })) || [];

      const newFiltersData =
        state$.value.searchFilters?.filters?.map(
          element => element.data_value
        ) || [];

      const filtersData =
        search?.filters.map(element => element.data_value) || [];

      const addedFilters =
        state$.value.searchFilters?.filters?.filter(
          element => !filtersData.includes(element.data_value)
        ) || [];

      const removedFilters =
        search?.filters?.filter(
          element => !newFiltersData.includes(element.data_value)
        ) || [];

      const dateFilters =
        search?.dateFilters?.map(element => ({
          filter_type: element.filter_type,
          condition: element.condition,
          data_value: element.data_value,
          filter_id: element.filter_id
        })) || [];

      const newSpaces =
        state$.value.searchSpaces?.spaces?.map(element => ({
          data_value: element.data_value
        })) || [];

      const newTopics =
        state$.value.searchTopics?.topics?.map(element => ({
          id: element.id,
          condition: element.condition,
          weight: element.weight,
          topic_type: element.topic_type
        })) || [];

      const newDateFilters = state$.value.dateFilters?.dateFilters || [];

      let newProblemGroupOptions = [];
      if (state$.value.searchProblemGroupOptions?.problemGroupOptions) {
        newProblemGroupOptions = Object.keys(
          state$.value.searchProblemGroupOptions?.problemGroupOptions
        ).length
          ? [state$.value.searchProblemGroupOptions?.problemGroupOptions]
          : [];
      }

      let newOrganizationOptions = [];
      if (state$.value.searchOrganizationOptions?.organizationOptions) {
        newOrganizationOptions = Object.keys(
          state$.value.searchOrganizationOptions?.organizationOptions
        ).length
          ? [state$.value.searchOrganizationOptions?.organizationOptions]
          : [];
      }

      let newCharacteristicOptions = [];
      if (state$.value.searchCharacteristicOptions?.characteristicOptions) {
        newCharacteristicOptions = Object.keys(
          state$.value.searchCharacteristicOptions?.characteristicOptions
        ).length
          ? [state$.value.searchCharacteristicOptions?.characteristicOptions]
          : [];
      }

      let newPublicationOptions = [];
      if (state$.value.searchPublicationOptions?.publicationOptions) {
        newPublicationOptions = Object.keys(
          state$.value.searchPublicationOptions?.publicationOptions
        ).length
          ? [state$.value.searchPublicationOptions?.publicationOptions]
          : [];
      }

      let newApplicationOptions = [];
      if (state$.value.searchApplicationOptions?.applicationOptions) {
        newApplicationOptions = Object.keys(
          state$.value.searchApplicationOptions?.applicationOptions
        ).length
          ? [state$.value.searchApplicationOptions?.applicationOptions]
          : [];
      }

      const addedDateFilters = newDateFilters.filter(
        ar =>
          !dateFilters.find(
            rm =>
              rm.data_value === ar.data_value && ar.condition === rm.condition
          )
      );

      const removedDateFilters = dateFilters.filter(
        ar =>
          !newDateFilters.find(
            rm =>
              rm.data_value === ar.data_value && ar.condition === rm.condition
          )
      );
      const removedDateFilterIds = removedDateFilters.map(x => x.filter_id);

      const addedTopics = newTopics.filter(
        e => !topics.find(x => x.id === e.id && x.condition === e.condition)
      );

      const removedTopics = topics.filter(
        e => !newTopics.find(x => x.id === e.id && x.condition === e.condition)
      );

      const staleTextTopics = topics.filter(e =>
        addedTopics.find(x => x.id === e.id && e.topic_type === 'text')
      );

      const executionPlan = [
        {
          value: newSpaces,
          path: 'space',
          op: 'add'
        },
        {
          value: removedDateFilterIds,
          path: 'filters',
          op: 'remove'
        },
        {
          value: addedDateFilters,
          path: 'filters',
          op: 'add'
        },
        {
          value: addedFilters,
          path: 'filters',
          op: 'add'
        },
        {
          value: removedFilters,
          path: 'filters',
          op: 'remove'
        },
        {
          value: addedTopics,
          path: 'topics',
          op: 'add'
        },
        {
          value: removedTopics,
          path: 'topics',
          op: 'remove'
        },
        {
          value: staleTextTopics,
          path: 'topics',
          op: 'remove'
        },
        {
          value: newApplicationOptions,
          path: 'applicationOptions',
          op: 'set'
        },
        {
          value: newProblemGroupOptions,
          path: 'problemGroupOptions',
          op: 'set'
        },
        {
          value: newOrganizationOptions,
          path: 'organizationOptions',
          op: 'set'
        },
        {
          value: newCharacteristicOptions,
          path: 'painpointsOptions',
          op: 'set'
        },
        {
          value: newPublicationOptions,
          path: 'publicationOptions',
          op: 'set'
        }
      ];

      const opsArray = executionPlan.reduce((acc, { value, path, op }) => {
        return value.reduce((innerAcc, innerElement) => {
          innerAcc.push({ op, path, value: innerElement });
          return innerAcc;
        }, acc);
      }, []);

      if (opsArray.length === 0) {
        return empty();
      }

      return of({
        type: 'searchId/update',
        payload: { body: opsArray, searchId }
      });
    })
  );

export default combineEpics(updateSearchEpic);
