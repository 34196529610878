/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import cloneDeep from 'clone-deep';
import {
  getResults,
  updatedResultsViewId
} from '../../../resultsDisplay/actions';
import {
  Spinner,
  ErrorMessage,
  Modal,
  Select,
  SelectOption
  // Menu,
  // MenuItem,
  // Button,
  // DownIcon,
  // Checkbox
} from '../../../apex';
import Results from './results';
import ResultProfileTemplate from '../../resultProfile/resultProfileTemplate';
import ListResultsSort from './listResultsSort';
import ListResultsPaginationBar from './listResultsPaginationBar';
import '../css/listResults.css';
import Explore from './explore';
import ExploreSelects from './exploreSelects';

export default function NonPublicationResults({
  resultType,
  // subTypeOptions,
  defaultResultSubTypeId,
  subTypeIdFilteringEnabled = false
}) {
  const resultTypeId = resultType.value;
  const resultTypeName = resultType.name;

  const viewOptions = [
    { name: 'List', value: 'list' },
    {
      name: 'Chart',
      value: 'chart'
    }
  ];

  const defaultViewId = useSelector(state => state.results.viewId);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = cloneDeep(searchParams);

  // const resultTypeId =
  //   queryParams.get('resultTypeId') ||
  //   resultsStore.nonPublicationSelectedResultTypeId ||
  //   'applications';

  const resultSubTypeId =
    queryParams.get('resultSubTypeId') || defaultResultSubTypeId;

  const viewId = queryParams.get('viewId') || defaultViewId;

  const { searchId } = useParams();
  const dispatch = useDispatch();

  const [resultModalVisible, setResultModalVisible] = useState(false);

  // const [resultSubTypeId, setResultSubTypeId] = useState(
  //   defaultResultSubTypeId
  // );

  const pageId = searchParams.get('page');

  const resultID = searchParams.get('resultId');

  const page = parseInt(pageId, 10) || 1;

  const status = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultTypeId]?.pages?.[page]?._status
  );

  useEffect(() => {
    if (!queryParams.has('resultSubTypeId')) {
      queryParams.set('resultSubTypeId', resultSubTypeId);
    }

    if (!queryParams.has('viewId')) {
      queryParams.set('viewId', viewId);
    }

    // queryParams.set('resultTypeId', resultSubTypeId);
    queryParams.sort();
    setSearchParams(queryParams);
  }, [searchId, resultTypeId]);

  useEffect(() => {
    if (resultID === null) {
      setResultModalVisible(false);
    } else {
      setResultModalVisible(true);
    }
  }, [resultID]);

  let results = useSelector(
    state =>
      state.results.bySearch[searchId]?.[resultTypeId]?.pages?.[page]?.results
  );

  if (subTypeIdFilteringEnabled) {
    results = results?.filter(x => x.categories.includes(resultSubTypeId));
  }

  // const resultsFilterBySubCategory = results?.filter(x =>
  //   x.categories.includes(resultSubTypeId)
  // );

  const openModal = resultId => {
    setResultModalVisible(true);
    const newParams = cloneDeep(searchParams);
    newParams.set('resultId', resultId);
    newParams.set('search', searchId);
    setSearchParams(newParams);
  };

  const closeModal = () => {
    setResultModalVisible(false);
    const newParams = cloneDeep(searchParams);
    newParams.delete('resultId');
    newParams.delete('search');
    setSearchParams(newParams);
  };

  let listOfResults;

  if (status === 'failure') {
    listOfResults = <ErrorMessage />;
  } else if (status === 'loading') {
    listOfResults = <Spinner width={80} />;
  } else {
    listOfResults = (
      <Results
        // filter this results list by the selected subtype filtr
        results={results}
        openModal={openModal}
        profileEndpoint={resultTypeId}
      />
    );
  }

  const modalContent = (
    <ResultProfileTemplate
      profileType={resultTypeName}
      profileEndpoint={resultTypeId}
    />
  );

  // const setResultSubTypeDropdown = value => {
  //   setResultSubTypeId(value);

  //   const newParams = cloneDeep(searchParams);
  //   newParams.set('resultSubTypeId', value);
  //   newParams.set('page', 1);
  //   newParams.sort();
  //   setSearchParams(newParams);
  // };

  const setViewDropDown = value => {
    const newParams = cloneDeep(searchParams);
    newParams.set('viewId', value);
    newParams.set('page', 1);
    newParams.sort();
    dispatch(updatedResultsViewId(value));
    setSearchParams(newParams);
  };

  useEffect(() => {
    if (!status || status === 'initial') {
      dispatch(getResults({ searchId, page, resultsType: resultTypeId }));
    }
  }, [dispatch, status, page, searchId]);

  return (
    <>
      <div className="sort-select-container">
        {/* <Menu
          items={subTypeOptions.map(lt => (
            <MenuItem value={lt.value} key={lt.value}>
              <Checkbox
                label={lt.name}
                checked={lt.value === resultSubTypeId}
                onChange={() => setResultSubTypeDropdown(lt.value)}
              />
            </MenuItem>
          ))}
        >
          <Button type="text">
            <div className="header__user-name">
              {subTypeOptions.find(p => p.value === resultSubTypeId)?.name}
            </div>
            <div className="header__user-button-icons">
              <DownIcon />
            </div>
          </Button>
        </Menu> */}

        <div className="sort-select-container--left">
          {/* {subTypeIdFilteringEnabled && (
            <Select
              label="Result Subtype"
              selected={subTypeOptions.find(p => p.value === resultSubTypeId)}
              onChange={e => setResultSubTypeDropdown(e)}
            >
              {subTypeOptions.map(lt => (
                <SelectOption value={lt.value} key={lt.value}>
                  {lt.name}
                </SelectOption>
              ))}
            </Select>
          )} */}

          <Select
            label="View"
            selected={viewOptions.find(p => p.value === viewId)}
            onChange={e => setViewDropDown(e)}
          >
            {viewOptions.map(lt => (
              <SelectOption value={lt.value} key={lt.value}>
                {lt.name}
              </SelectOption>
            ))}
          </Select>

          {viewId === 'chart' && <ExploreSelects resultType={resultType} />}
        </div>

        {!subTypeIdFilteringEnabled && <div />}

        {viewId === 'list' && <ListResultsSort resultsType={resultTypeId} />}
      </div>
      {viewId === 'list' && (
        <>
          <ListResultsPaginationBar position="top" resultsType={resultTypeId} />
          {listOfResults}
          <ListResultsPaginationBar
            position="bottom"
            resultsType={resultTypeId}
          />
          <Modal
            width="75%"
            visible={resultModalVisible}
            onMaskClick={closeModal}
            onCloseButtonClick={closeModal}
          >
            {modalContent}
          </Modal>
        </>
      )}
      {viewId === 'chart' && (
        <div>
          <Explore resultType={resultType} />
        </div>
      )}
    </>
  );
}
