import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TabGroup,
  TabList,
  Tab,
  PublicationIcon,
  ListIcon
  // BarChartIcon
} from '../../../apex';
import '../css/primaryTabs.css';

function PrimaryTabs() {
  const resultsURL = useParams()['*'];

  let startingActiveTabIndex;

  let topicsParams;
  let organizationsParams;
  let opportunitiesParams;
  let mentionsParams;
  let problemAreaParams;

  if (resultsURL.indexOf('topics') !== -1) {
    startingActiveTabIndex = 0;
    topicsParams = window.location.search;
  } else if (resultsURL.indexOf('painpoints') !== -1) {
    startingActiveTabIndex = 1;
    problemAreaParams = window.location.search;
  } else if (resultsURL.indexOf('organizations') !== -1) {
    startingActiveTabIndex = 2;
    organizationsParams = window.location.search;
  } else if (resultsURL.indexOf('opportunities') !== -1) {
    startingActiveTabIndex = 3;
    opportunitiesParams = window.location.search;
  } else if (resultsURL.indexOf('mentions') !== -1) {
    startingActiveTabIndex = 4;
    mentionsParams = window.location.search;
  } else {
    startingActiveTabIndex = 0;
    topicsParams = window.location.search;
  }

  const [activeTabIndex, setActiveTabIndex] = useState(startingActiveTabIndex);

  const navigate = useNavigate();

  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        navigate({ pathname: 'topics', search: topicsParams });
        break;
      case 1:
        navigate({ pathname: 'painpoints', search: problemAreaParams });
        break;
      case 2:
        navigate({ pathname: 'organizations', search: organizationsParams });
        break;
      case 3:
        navigate({ pathname: 'opportunities', search: opportunitiesParams });
        break;
      case 4:
        navigate({ pathname: 'mentions', search: mentionsParams });
        break;
      default:
        navigate({ pathname: 'topics', search: topicsParams });
        break;
    }
  }, [navigate, activeTabIndex]);

  return (
    <TabGroup
      selectedIndex={activeTabIndex}
      onChange={i => setActiveTabIndex(i)}
      className="primartyTabs_tab-container"
    >
      <TabList className="primaryTabs_tab-list ">
        <Tab label="Topics" icon={<ListIcon />} />
        <Tab label="Pain Points" icon={<ListIcon />} />
        <Tab label="Organizations" icon={<ListIcon />} />
        <Tab label="Opportunities" icon={<ListIcon />} />
        <Tab label="Sources" icon={<PublicationIcon />} />
        {/* <Tab label="Charts" icon={<BarChartIcon />} /> */}
      </TabList>
    </TabGroup>
  );
}

export default PrimaryTabs;
