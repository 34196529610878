import PropTypes from 'prop-types';
import React from 'react';
import apertureIcon from './resources/logo_icon.svg';
import apertureFull from './resources/logo_full.svg';

const Logo = ({ onClick, mode, height, width, className, ...other }) => {
  const src = () => {
    switch (mode) {
      case 'full':
        return apertureFull;
      case 'icon':
        return apertureIcon;
      default:
        return apertureFull;
    }
  };

  return (
    <div
      onClick={onClick}
      role="link"
      tabIndex={0}
      onKeyDown={onClick}
      className={className}
      {...other}
    >
      <img
        src={src()}
        alt="Aperture logo"
        className="logo__icon"
        height={height}
        width={width}
      />
    </div>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func,
  mode: PropTypes.oneOf(['full', 'icon']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

Logo.defaultProps = {
  onClick: undefined,
  mode: 'full',
  height: 'auto',
  width: 'auto',
  className: ''
};

export default Logo;
