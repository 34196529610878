import React from 'react';
import moment from 'moment';
import { Result } from '../../../apex';

export default function Mentions({ results, openModal }) {
  function formatMetadata(result) {
    return (
      <div>
        <span>{moment(result.latest_date).format('MMM YYYY')}</span>
        <span className="results-template__meta-divider" />
        <span>{result.corpus}</span>
        <span className="results-template__meta-divider" />
        <span>
          {result.organizations.slice(0, 3).map((org, i) => {
            return (
              <>
                <span>{org}</span>
                {i !== result.organizations.slice(0, 3).length - 1
                  ? ', '
                  : null}
              </>
            );
          })}
        </span>
      </div>
    );
  }

  const resultElements =
    results?.map(result => (
      <Result
        onClick={() => openModal(result.id)}
        title={result.title}
        metadata={formatMetadata(result)}
        key={result.id}
      >
        <div className="results-template__summary-container">
          {result.summary.map(summary => {
            return (
              <div
                key={summary}
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            );
          })}
        </div>
      </Result>
    )) || null;
  return <>{resultElements}</>;
}
