/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { jsx, useTheme } from '@emotion/react';
import { LoadingIndicator } from '@artemisintelligence/aperture-component-library';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BookmarkDisplay from './bookmarkDisplay';
import {
  fetchPaginatedBookmarks,
  deleteBookmark
} from '../../../bookmarks/actions';
import NoBookmarksDisplay from './noBookmarksDisplay';
import MobileBookmarkDisplay from './mobileBookmarkDisplay';
import { Modal, Button } from '../../../apex';

function BookmarksList() {
  const [modalVisible, setModalVisisble] = useState(false);
  const [currentBookmark, setCurrentBookmark] = useState();

  const theme = useTheme();

  const jss = {
    listContainer: {
      [theme.breakPoints[1]]: {
        margin: '14px 0px 14px 46px'
      },
      margin: '28px 0px 28px 46px'
    },
    mobileBookmarkDisplay: {
      display: 'none',
      [theme.breakPoints[1]]: {
        display: 'unset'
      }
    },
    bookmarkDisplay: {
      [theme.breakPoints[1]]: {
        display: 'none'
      }
    }
  };

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const pageId = searchParams.get('page');
  const page = parseInt(pageId, 10) || 1;

  const documents = useSelector(
    state =>
      state.bookmarksReducer?.savedBookmarksPaginated?.pages?.[page]?.documents
  );

  const status = useSelector(
    state =>
      state.bookmarksReducer?.savedBookmarksPaginated?.pages?.[page]?._status
  );

  const loading = status === undefined || status === 'loading';

  const noBookmarks = documents && documents.length === 0;

  const formattedDocuments = documents?.map(d => ({
    name: d.name,
    any: d.search.data.topics.filter(f => f.condition === 'or'),
    all: d.search.data.topics.filter(f => f.condition === 'and'),
    not: d.search.data.topics.filter(f => f.condition === 'exclude'),
    filters: d.search.data.filters.filter(f => f.condition === 'and'),
    startDate: d.search.data.filters.filter(f => f.condition === 'gte')[0]
      ?.data_value,
    endDate: d.search.data.filters.filter(f => f.condition === 'lte')[0]
      ?.data_value,
    searchId: d.search.data.id,
    bookmarkKey: d._key
  }));

  function onDeleteBookmarkClick(bookmarkId) {
    setCurrentBookmark(bookmarkId);
    setModalVisisble(true);
  }

  function onDeleteBookmarkCancel() {
    setCurrentBookmark();
    setModalVisisble(false);
  }
  function onConfirmDeleteBookmarkClick() {
    dispatch(deleteBookmark({ bookmarkKey: currentBookmark }));
    dispatch(fetchPaginatedBookmarks({ page }));
    setModalVisisble(false);
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (noBookmarks) {
    return <NoBookmarksDisplay />;
  }

  return (
    <div css={jss.listContainer}>
      <Modal
        title="Delete Save"
        width={450}
        visible={modalVisible}
        footer={
          <>
            <Button type="secondary" onClick={onDeleteBookmarkCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onConfirmDeleteBookmarkClick}>
              Delete
            </Button>
          </>
        }
        onMaskClick={() => setModalVisisble(false)}
      >
        Are you sure you want to delete this saved search?
      </Modal>
      {formattedDocuments?.map(fd => (
        <>
          <div css={jss.mobileBookmarkDisplay}>
            <MobileBookmarkDisplay
              page={page}
              name={fd.name}
              all={fd.all}
              any={fd.any}
              not={fd.not}
              filters={fd.filters}
              startDate={fd.startDate}
              endDate={fd.endDate}
              searchId={fd.searchId}
              bookmarkKey={fd.bookmarkKey}
              deleteBookmark={() => onDeleteBookmarkClick(fd.bookmarkKey)}
            />
          </div>
          <div css={jss.bookmarkDisplay}>
            <BookmarkDisplay
              page={page}
              name={fd.name}
              all={fd.all}
              any={fd.any}
              not={fd.not}
              filters={fd.filters}
              startDate={fd.startDate}
              endDate={fd.endDate}
              searchId={fd.searchId}
              bookmarkKey={fd.bookmarkKey}
              deleteBookmark={() => onDeleteBookmarkClick(fd.bookmarkKey)}
            />
          </div>
        </>
      ))}
    </div>
  );
}

export default BookmarksList;
