/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { jsx, useTheme } from '@emotion/react';
import { Button, Copy } from '@artemisintelligence/aperture-component-library';
import { useNavigate } from 'react-router-dom';
import pageNotFound from '../../resources/img/pageNotFound.svg';

function PageNotFoundTemplate() {
  const theme = useTheme();

  const jss = {
    outerContainer: {
      width: '100%'
    },
    innerContainer: {
      flexDirection: 'row',
      margin: '50px 0px',
      justifyContent: 'space-evenly',
      display: 'flex',
      [theme.breakPoints[0]]: {
        margin: '0 17px',
        flexDirection: 'column'
      }
    },
    textContainer: {
      marginTop: '90px',
      [theme.breakPoints[0]]: {
        marginBottom: '60px',
        marginTop: '40px'
      }
    },
    title: {
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '0px',
      color: 'transparent'
    },
    subtitle: {
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '60px',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    button: {
      margin: 'auto'
    }
  };

  const navigate = useNavigate();

  return (
    <div css={jss.outerContainer} style={{ width: '100%' }}>
      <div css={jss.innerContainer}>
        <div css={jss.textContainer}>
          <Copy
            element="h1"
            typographyStyle="XXXL"
            css={jss.title}
            color="inherit"
          >
            404
          </Copy>
          <Copy element="h2" typographyStyle="XL" css={jss.subtitle}>
            Something is missing.
          </Copy>
          <Button
            label="go back to home page"
            css={jss.button}
            onClick={() => navigate('../home')}
          />
        </div>
        <img src={pageNotFound} alt="no results" css={jss.image} />
      </div>
    </div>
  );
}

export default PageNotFoundTemplate;
