import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchDocumentEpic = action$ =>
  action$.pipe(
    ofType('document/fetch'),
    map(({ payload }) => {
      const { corpus, id, searchId } = payload;
      return apiRequest({
        path: `/documents-v2/${corpus}/${id}?search_id=${searchId}`,
        fulfilledType: 'document/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default fetchDocumentEpic;
