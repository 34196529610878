import PropTypes from 'prop-types';
import React from 'react';
import './errorMessage.css';

export default function ErrorMessage({ title, body, className, ...other }) {
  return (
    <div className={`errorMessage ${className}`} {...other}>
      <div>
        <h1 className="errorMessage__title">Unexpected Error</h1>
        <p className="errorMessage__body">
          Sorry, something unexpected happened.
        </p>
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string
};

ErrorMessage.defaultProps = {
  title: 'Unexpected Error',
  body: 'Sorry, something unexpected happened.',
  className: undefined
};
