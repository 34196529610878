import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { authHandleCallback } from './auth/authenticator';
import { tokenResponse, tokenRequest } from './auth/actions';
// import ProblemsGroupProfileTemplate from './interfaces/problemsGroupProfile/problemsGroupProfileTemplate';
import ResultProfileTemplate from './interfaces/resultProfile/resultProfileTemplate';
import './App.css';
import PublicationProfileTemplate from './interfaces/publicationProfile/publicationProfileTemplate';
import SavedSearchesTemplate from './interfaces/savedSearches/savedSearchesTemplate';
import ResultsTemplate from './interfaces/results/resultsTemplate';
import WrapperTemplate from './interfaces/wrapper/wrapperTemplate';
import PageNotFoundTemplate from './interfaces/pageNotFound/pageNotFoundTemplate';
import ScrollToTop from './scrollToTop';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = async () => {
      const token = await authHandleCallback(navigate);
      if (token) {
        dispatch(tokenResponse(token));
      } else {
        dispatch(tokenRequest());
      }
    };
    getToken();
  }, [dispatch]);

  const authLoading = useSelector(state => state.auth.loading);

  if (authLoading) {
    return null;
  }

  return (
    <div>
      <ScrollToTop />
      <WrapperTemplate>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/default/explore" />}
          />
          <Route path="searches" element={<SavedSearchesTemplate />} />
          <Route
            path="documents/:corpus/:id"
            element={<PublicationProfileTemplate />}
          />
          {/* <Route
            path="problems/:id"
            element={<ProblemsGroupProfileTemplate />}
          /> */}
          <Route
            path="problems/:id"
            element={
              <ResultProfileTemplate
                profileType="problem"
                resultType="problems"
              />
            }
          />
          <Route path=":searchId/*" element={<ResultsTemplate />} />
          <Route path="*" element={<PageNotFoundTemplate />} />
        </Routes>
      </WrapperTemplate>
    </div>
  );
}

export default App;
