import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addSearchSpace } from '../../../searchSpaces/actions';
import { Radio, RadioGroup, Spinner } from '../../../apex';
import '../css/spaces.css';

function Spaces() {
  const { searchId } = useParams();
  const dispatch = useDispatch();

  const spaces = useSelector(state => {
    return state.spaces;
  });

  const { availableSpaces, _status } = spaces;

  const currentSpaceId = useSelector(
    state => state.search.bySearchId[searchId]?.space?.id
  );

  const defaultSpaceId = availableSpaces.find(s => s.default === true)?.id;

  const onSpaceChange = e => {
    const spacesItem = {
      data_value: e,
      filter_type: 'space'
    };
    return dispatch(addSearchSpace(spacesItem));
  };

  if (_status === 'loading') {
    return <Spinner width="72px" />;
  }

  return (
    <div className="spaces">
      <div>
        <div className="spaces__title">My Spaces</div>
        <RadioGroup
          className="spaces__options"
          value={currentSpaceId || defaultSpaceId}
          onChange={e => onSpaceChange(e)}
        >
          {availableSpaces?.map(space => (
            <Radio
              key={space.humanKey}
              label={space.humanKey}
              value={space.id}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}

export default Spaces;
