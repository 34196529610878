export function addDateFilters(dateFilters) {
  return {
    type: 'dateFilters/add',
    payload: { dateFilters }
  };
}

export function resetDateFilters() {
  return { type: 'dateFilters/reset' };
}
