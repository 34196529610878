import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowRightIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M15.14,11.73,9.39,6a.39.39,0,0,0-.53,0,.37.37,0,0,0,0,.53L14.34,12,8.86,17.49a.37.37,0,0,0,0,.53.38.38,0,0,0,.26.11A.39.39,0,0,0,9.39,18l5.75-5.75A.38.38,0,0,0,15.14,11.73Z" />
      </svg>
    </div>
  );
}

ArrowRightIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

ArrowRightIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
