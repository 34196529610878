import React from 'react';
import './tag.css';
import CloseIcon from '../Icons/CloseIcon';

const Tag = ({ type, color, onClose, children, className, ...other }) => {
  const classMap = {
    type: {
      filled: 'tag--filled',
      outlined: 'tag--outlined'
    },
    color: {
      green: 'tag--green',
      yellow: 'tag--yellow',
      red: 'tag--red'
    }
  };

  return (
    <div
      className={`tag  ${classMap.type[type]} ${classMap.color[color]} ${className}}`}
      {...other}
    >
      <div className="tag__content">{children}</div>
      {onClose ? (
        <div>
          <CloseIcon size="6px" className="tag__close-icon" onClick={onClose} />
        </div>
      ) : null}
    </div>
  );
};

export default Tag;
