import produce from 'immer';
import initialState from './initialState';

export default function filtersReducer(state = initialState, action) {
  return produce(state, nextState => {
    const createTopicsObject = topicsArray => {
      return topicsArray.reduce((acc, element) => {
        acc[element.id] = nextState.byId[element.id] ?? {};
        if (element.humanKey) {
          acc[element.id].humanKey = element.humanKey;
        }
        return acc;
      }, {});
    };

    if (action.error) {
      return nextState;
    }

    switch (action.type) {
      case 'searchId/fetchFulfilled':
      case 'searchId/updateFulfilled': {
        const filters = action.payload.response.filters || [];
        const topics = action.payload.response.topics || [];
        const topicsArray = filters.concat(topics);
        const topicsObject = topicsArray.reduce((acc, element) => {
          acc[element.id] = nextState.byId[element.id] ?? {};
          Object.assign(acc[element.id], element);
          delete acc[element.id].id;
          return acc;
        }, {});
        Object.assign(nextState.byId, topicsObject);
        return nextState;
      }
      case 'filters/fetchFulfilled': {
        const topicsArray = action.payload.response.documents;
        const topicsObject = createTopicsObject(topicsArray);
        Object.assign(nextState.byId, topicsObject);
        return nextState;
      }
      case 'topics/addTopics': {
        const topicsArray = action.payload.topics;
        const topicsObject = createTopicsObject(topicsArray);
        Object.assign(nextState.byId, topicsObject);
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
