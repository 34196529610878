export function addSearchTopic(topicId) {
  return { type: 'searchTopics/add', payload: { topic: topicId } };
}

export function removeSearchTopic(topicId) {
  return { type: 'searchTopics/remove', payload: { topic: topicId } };
}

export function setSearchTopics(...topicIds) {
  return { type: 'searchTopics/set', payload: { topics: topicIds.flat() } };
}
