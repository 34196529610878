/* eslint-disable consistent-return */
import sha256 from 'crypto-js/sha256';
import base64 from 'crypto-js/enc-base64';

function randomString(length) {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~';

  const ary = crypto.getRandomValues(new Uint8Array(length));

  let string = '';
  ary.forEach(num => {
    string += chars[num % chars.length];
  });

  return string;
}

function makeBase64WebSafe(string) {
  return string
    .replace(/=/g, '')
    .replace(/\//g, '_')
    .replace(/\+/g, '-');
}

let config;
const getWellKnownConfig = async () => {
  if (config) {
    return config;
  }

  const domain = process.env.REACT_APP_AUTH_DOMAIN;

  const response = await fetch(
    `https://${domain}/.well-known/openid-configuration`
  );
  config = await response.json();
  return config;
};

async function authLoginRedirect() {
  const wellKnownConfig = await getWellKnownConfig();
  const stateVerifier = randomString(32);
  const codeVerifier = randomString(56);
  const codeChallenge = makeBase64WebSafe(
    base64.stringify(sha256(codeVerifier))
  );

  sessionStorage.setItem(`authState-${stateVerifier}`, codeVerifier);
  sessionStorage.setItem(
    `authState-${stateVerifier}-redirect`,
    window.location.href
  );

  const authorizationEndpointUrl = new URL(
    wellKnownConfig.authorization_endpoint
  );

  authorizationEndpointUrl.search = new URLSearchParams({
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    redirect_uri: window.location.origin,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    response_type: 'code',
    scope: 'openid profile',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    state: stateVerifier
  });

  window.location.assign(authorizationEndpointUrl);
}

async function authHandleCallback(navigate) {
  const { searchParams } = new URL(window.location.href);
  if (!searchParams.has('code') && !searchParams.has('state')) {
    return;
  }
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const codeVerifier = sessionStorage.getItem(`authState-${state}`);
  const redirect = sessionStorage.getItem(`authState-${state}-redirect`);

  if (!codeVerifier) {
    // eslint-disable-next-line no-console
    console.error('unexpected state parameter');
    return;
  }

  const wellKnownConfig = await getWellKnownConfig();

  const tokens = await (
    await fetch(wellKnownConfig.token_endpoint, {
      method: 'POST',
      body: JSON.stringify({
        code,
        code_verifier: codeVerifier,
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        redirect_uri: window.location.origin,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        grant_type: 'authorization_code'
      }),
      headers: new Headers({
        'Content-type': 'application/json'
      })
    })
  ).json();

  sessionStorage.clear();

  const url = new URL(redirect);
  navigate(`${url.pathname}${url.search}`);

  return tokens;
}

async function authTokenSilently() {
  const wellKnownConfig = await getWellKnownConfig();
  const stateVerifier = randomString(32);
  const codeVerifier = randomString(56);
  const codeChallenge = makeBase64WebSafe(
    sha256(codeVerifier).toString(base64)
  );

  const authorizationEndpointUrl = new URL(
    wellKnownConfig.authorization_endpoint
  );

  authorizationEndpointUrl.search = new URLSearchParams({
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    redirect_uri: window.location.origin,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    response_type: 'code',
    response_mode: 'web_message',
    scope: 'openid profile',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    state: stateVerifier,
    prompt: 'none'
  });

  const code = await new Promise((resolve, reject) => {
    const iframe = window.document.createElement('iframe');
    iframe.style.display = 'none';

    const timeoutSetTimeoutId = window.setTimeout(() => {
      reject(new Error('auth request timed out'));
      window.document.body.removeChild(iframe);
    }, 4500);

    const responseHandler = e => {
      if (
        e.origin !== authorizationEndpointUrl.origin ||
        e.data.type !== 'authorization_response'
      ) {
        return;
      }
      e.source.close();
      clearTimeout(timeoutSetTimeoutId);
      window.removeEventListener('message', responseHandler, false);
      window.document.body.removeChild(iframe);
      const { response } = e.data;
      if (response.error) {
        return reject(response);
      }
      if (response.state !== stateVerifier) {
        return reject(new Error('State does not match.'));
      }
      resolve(response.code);
    };

    window.addEventListener('message', responseHandler);
    window.document.body.appendChild(iframe);
    iframe.setAttribute('src', authorizationEndpointUrl);
  });

  const tokens = await (
    await fetch(wellKnownConfig.token_endpoint, {
      method: 'POST',
      body: JSON.stringify({
        code,
        code_verifier: codeVerifier,
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        redirect_uri: window.location.origin,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        grant_type: 'authorization_code'
      }),
      headers: new Headers({
        'Content-type': 'application/json'
      })
    })
  ).json();

  return tokens;
}

const resetConfig = () => {
  config = undefined;
};

export {
  authLoginRedirect,
  authHandleCallback,
  authTokenSilently,
  resetConfig
};
