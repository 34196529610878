import { Listbox } from '@headlessui/react';
import React from 'react';
import './selectOption.css';
import CheckMarkIcon from '../Icons/CheckMarkIcon';

function SelectOption({ key, value, disabled, className, children }) {
  const getClass = (isDisabled, active) => {
    if (isDisabled) {
      return 'select-option__content select-option__content--disabled';
    }
    if (active) {
      return 'select-option__content select-option__content--active';
    }
    return 'select-option__content select-option__content--inactive';
  };

  return (
    <Listbox.Option
      className={`select-option ${className}`}
      key={key}
      value={value}
      disabled={disabled}
    >
      {({ active, selected }) => (
        <>
          <span className={getClass(disabled, active)}>
            <CheckMarkIcon
              className={
                selected
                  ? 'select-option__icon--active'
                  : 'select-option__icon--inactive'
              }
            />
            {children}
          </span>
        </>
      )}
    </Listbox.Option>
  );
}

export default SelectOption;
