import { Disclosure } from '@headlessui/react';
import React from 'react';

function Accordian({ children, defaultOpen, className }) {
  return (
    <div className={className}>
      <Disclosure defaultOpen={defaultOpen}>{children}</Disclosure>
    </div>
  );
}

export default Accordian;
