import React from 'react';
import { useSelector } from 'react-redux';
import { DateTimeAreaChart } from '../../../apex';

export default function Dates({ id }) {
  const allDates = useSelector(
    state => state.resultProfile.byId?.[id]?.all_dates
  );

  const formattedData = allDates?.map(function(date) {
    return { x: Number(date.name), y: date.count };
  });

  return <DateTimeAreaChart data={formattedData} yLabel="Publications" />;
}
