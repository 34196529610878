import React from 'react';
import PropTypes from 'prop-types';

export default function UserIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M12,1.62A10.38,10.38,0,1,0,22.37,12,10.4,10.4,0,0,0,12,1.62Zm0,20A9.63,9.63,0,1,1,21.62,12,9.63,9.63,0,0,1,12,21.62Z" />
        <path d="M11.92,12a3,3,0,0,0,2.15-.89h0a3.05,3.05,0,0,0,0-4.3,3.12,3.12,0,0,0-4.31,0,3.05,3.05,0,0,0,0,4.3A3,3,0,0,0,11.92,12ZM10.29,7.35a2.29,2.29,0,1,1,0,3.24A2.29,2.29,0,0,1,10.29,7.35Z" />
        <path d="M11.91,13a6.16,6.16,0,0,0-6.08,5.13.38.38,0,0,0,.31.43H6.2a.38.38,0,0,0,.37-.31A5.42,5.42,0,0,1,17.22,18a.38.38,0,0,0,.74-.15A6.2,6.2,0,0,0,11.91,13Z" />
      </svg>
    </div>
  );
}

UserIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

UserIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
