/** @jsxRuntime classic /
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from '@emotion/react';
import {
  Copy,
  Button,
  LoadingIndicator,
  Paper,
  ToggleFlyout,
  YearPicker,
  SelectableDateTimeAreaChart,
  ExpandCollapse
} from '@artemisintelligence/aperture-component-library';
import { ErrorMessage } from '../../../apex';
import { addDateFilters, resetDateFilters } from '../../../dateFilters/actions';
import { getFilters } from '../../../filters/actions';

export default function DateFilter({ topicId }) {
  const earliestYear = 2000;
  const latestYear = new Date().getFullYear();

  const jss = {
    filterExpandCollapseHeader: {
      padding: '10px 16px 10px 15px'
    },
    filterPanel: {
      overflow: 'visible'
    },
    title: {
      marginBottom: '25px'
    },
    outerContainer: {
      margin: '0px 20px 25px 20px'
    },
    flyoutContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    yearPickerAndChartContainer: {
      margin: '20px 0px'
    },
    yearPickerAndChartPaper: {
      margin: '25px 0px'
    }
  };

  const [dropdownValue, setDropDownValue] = useState('allDates');

  const searchId = useSelector(state => state.searchId.id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilters({ searchId, topicId }));
  }, [dispatch, searchId]);

  function _resetDateFilters() {
    dispatch(resetDateFilters());
  }

  function addNewDateRangeFilters(_min, _max) {
    dispatch(
      addDateFilters({
        filter_type: 'date',
        condition: 'gte',
        data_value: _min.toString()
      })
    );

    dispatch(
      addDateFilters({
        filter_type: 'date',
        condition: 'lte',
        data_value: _max.toString()
      })
    );
  }
  const searchStatus = useSelector(
    state => state.search.bySearchId?.[searchId]?._status
  );

  const yearAndCountStatus = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?._status
  );

  const yearAndCountError = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?.error
  );

  const yearsAndCounts = useSelector(
    state => state.filters.bySearchId?.[searchId]?.[topicId]?.topics
  );

  const yearsAndCountsFormatted = yearsAndCounts
    ?.map(f => ({ x: parseInt(f.key_as_string), y: f.doc_count }))
    .filter(q => q.x >= earliestYear && q.x <= latestYear);

  const appliedDateFilters = useSelector(state => {
    return state.dateFilters.dateFilters;
  });

  const pickerRange = new Array(latestYear + 1 - earliestYear)
    .fill()
    .map((d, i) => i + earliestYear);

  let minDateFilterFromReducer = appliedDateFilters?.find(x => {
    return x.condition === 'gte';
  })?.data_value;

  minDateFilterFromReducer = minDateFilterFromReducer || earliestYear;

  let maxDateFilterFromReducer = appliedDateFilters?.find(x => {
    return x.condition === 'lte';
  })?.data_value;

  maxDateFilterFromReducer = maxDateFilterFromReducer || latestYear;

  React.useEffect(() => {
    const maxDateFilterFromReducerAsInt = parseInt(maxDateFilterFromReducer);
    const minDateFilterFromReducerAsInt = parseInt(minDateFilterFromReducer);

    if (
      maxDateFilterFromReducerAsInt === latestYear &&
      minDateFilterFromReducerAsInt === earliestYear
    ) {
      setDropDownValue('allDates');
    } else if (
      maxDateFilterFromReducerAsInt === latestYear &&
      minDateFilterFromReducerAsInt === latestYear - 2
    ) {
      setDropDownValue('last2');
    } else if (
      maxDateFilterFromReducerAsInt === latestYear &&
      minDateFilterFromReducerAsInt === latestYear - 5
    ) {
      setDropDownValue('last5');
    } else if (
      maxDateFilterFromReducerAsInt === latestYear &&
      minDateFilterFromReducerAsInt === latestYear - 10
    ) {
      setDropDownValue('last10');
    } else {
      setDropDownValue('custom');
    }
  }, [minDateFilterFromReducer, maxDateFilterFromReducer]);

  function onDropDownItemClicked(e) {
    let min;
    let max;
    switch (e.value) {
      case 'allDates':
        min = earliestYear;
        max = latestYear;
        break;
      case 'last2':
        min = latestYear - 2;
        max = latestYear;
        break;
      case 'last5':
        min = latestYear - 5;
        max = latestYear;
        break;
      case 'last10':
        min = latestYear - 10;
        max = latestYear;
        break;
      default:
        return;
    }
    _resetDateFilters();
    addNewDateRangeFilters(min, max);
  }

  function onYearRangeSelection(e) {
    if (e.min && e.max) {
      _resetDateFilters();
      addNewDateRangeFilters(e.min, e.max);
    }
  }

  function onXAxisSelection(e) {
    _resetDateFilters();
    addNewDateRangeFilters(Math.round(e.min), Math.round(e.max));
  }

  let content;

  if (searchStatus === 'failure' || yearAndCountError) {
    content = <ErrorMessage />;
  } else if (yearAndCountStatus === 'loading') {
    content = <LoadingIndicator />;
  } else {
    content = (
      <Paper color="background/regular" css={jss.outerContainer}>
        <Copy typographyStyle="M" color="copy/light" css={jss.title}>
          Publication Activity
        </Copy>
        <div css={jss.flyoutContainer}>
          <ToggleFlyout
            onOptionClicked={onDropDownItemClicked}
            options={[
              {
                label: 'All Dates',
                value: 'allDates'
              },
              {
                label: 'Last 2 Years',
                value: 'last2'
              },
              {
                label: 'Last 5 Years',
                value: 'last5'
              },
              {
                label: 'Last 10 Years',
                value: 'last10'
              },
              {
                label: 'Custom',
                value: 'custom'
              }
            ]}
            optionsLabel=""
            value={dropdownValue}
          />
          {dropdownValue !== 'allDates' ? (
            <Button
              label="Reset"
              onClick={_resetDateFilters}
              type="text"
              typographyStyle="XS"
            />
          ) : null}
        </div>
        <div css={jss.yearPickerAndChartContainer}>
          <Paper
            rounded
            borderTop={1}
            borderRight={1}
            borderLeft={1}
            css={jss.yearPickerAndChartPaper}
            color="background/regular"
          >
            <SelectableDateTimeAreaChart
              data={yearsAndCountsFormatted}
              onXAxisSelection={onXAxisSelection}
              yLabel="Publications"
            />
          </Paper>
          <YearPicker
            years={pickerRange}
            startYear={parseInt(minDateFilterFromReducer)}
            endYear={parseInt(maxDateFilterFromReducer)}
            onYearRangeSelection={onYearRangeSelection}
            isCustomMode={dropdownValue === 'custom'}
          />
        </div>
      </Paper>
    );
  }

  return (
    <Paper borderBottom={1} color="background/regular">
      <ExpandCollapse
        panelHeaderStyling={jss.filterExpandCollapseHeader}
        css={jss.filterPanel}
        expandedTitle="Dates"
        collapsedTitle="Dates"
        size="medium"
      >
        {content}
      </ExpandCollapse>
    </Paper>
  );
}
