/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function searchReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchId/updateFulfilled':
      case 'searchId/fetchFulfilled': {
        if (action.error) {
          const id = action.payload?.response?.id;
          nextState.bySearchId[id] = nextState.bySearchId[id] ?? {};
          nextState.bySearchId[id]._status = 'failure';
          nextState.bySearchId[id].error = action.payload;
          return nextState;
        }
        const {
          id,
          topics,
          filters,
          applicationOptions,
          problemGroupOptions,
          publicationOptions,
          space
        } = action.payload.response;
        nextState.bySearchId[id] = nextState.bySearchId[id] ?? {};
        nextState.bySearchId[id]._status = 'success';
        nextState.bySearchId[id].topics = topics;

        nextState.bySearchId[id].filters = filters.filter(function(x) {
          return x.filter_type !== 'date';
        });

        nextState.bySearchId[id].dateFilters = filters.filter(function(x) {
          return x.filter_type === 'date';
        });

        nextState.bySearchId[id].problemGroupOptions = problemGroupOptions;
        nextState.bySearchId[id].applicationOptions = applicationOptions;
        nextState.bySearchId[id].publicationOptions = publicationOptions;
        nextState.bySearchId[id].space = space;

        return nextState;
      }
      default:
        return nextState;
    }
  });
}
