import { Disclosure } from '@headlessui/react';
import React from 'react';
import CirclePlusIcon from '../Icons/CirclePlusIcon';
import CircleMinusIcon from '../Icons/CircleMinusIcon';
import './accordianSummary.css';

function AccordianSummary({ children, className, open }) {
  return (
    <Disclosure.Button className="accordian-summary">
      <div className={`accordian-summary__container ${className}`}>
        {open ? (
          <CircleMinusIcon className="accordian-summary__icon--active" />
        ) : (
          <CirclePlusIcon className="accordian-summary__icon--inactive" />
        )}
        {children}
      </div>
    </Disclosure.Button>
  );
}

export default AccordianSummary;
