import React from 'react';
import { RadioGroup } from '@headlessui/react';
import './radio.css';

function Radio({ label, key, value, disabled, className }) {
  return (
    <RadioGroup.Option
      disabled={disabled}
      key={key}
      value={value}
      as="div"
      className={`radio ${className} ${disabled ? 'radio--disabled' : ''}`}
    >
      {({ checked }) => (
        <>
          <div className="radio__outer-circle">
            <div
              className={`radio__circle ${className} ${
                checked ? 'radio__circle--checked' : ''
              }`}
            />
          </div>
          <RadioGroup.Label as="span">{label}</RadioGroup.Label>
        </>
      )}
    </RadioGroup.Option>
  );
}

export default Radio;
