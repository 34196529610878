import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

function DonutChart({ data }) {
  const options = {
    chart: {
      type: 'pie',
      margin: [0, 20, 80, 20],
      spacingTop: 0,
      spacingBottom: 10,
      spacingLeft: 0,
      spacingRight: 0
    },
    credits: {
      enabled: false
    },
    colors: [
      '#3C6D69',
      '#4B928D',
      '#57A9A2',
      '#64C2B9',
      '#67DAC4',
      '#A6EEE0',
      '#D9F9F3'
    ],

    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      symbolHeight: 12,
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      itemMarginTop: 2.5,
      itemMarginBottom: 2.5,
      itemHoverStyle: {
        cursor: 'default'
      },
      itemStyle: {
        color: '#000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '17px'
      }
    },
    tooltip: {
      useHTML: true,
      zIndex: 20,
      headerFormat: `<div style='padding:8px 10px'>`,
      shadow: true,
      backgroundColor: '#FFFFFF',
      borderRadius: 0,
      borderWidth: 0,
      pointFormat: `
          <div><span style='font:12px Poppins;'>{point.y} {point.name}</span></div>
              `,
      footerFormat: '</div>'
    },
    plotOptions: {
      pie: {
        size: '100%',

        point: {
          events: {
            legendItemClick(e) {
              e.preventDefault();
            }
          }
        },
        states: {
          hover: {
            halo: false,
            brightness: 0.1
          }
        },
        borderWidth: 0,
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        states: {
          inactive: {
            opacity: 1
          }
        },
        innerSize: '67%',
        data
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: '100%' } }}
      options={options}
    />
  );
}

DonutChart.propTypes = {
  data: PropTypes.instanceOf(Array)
};

DonutChart.defaultProps = {
  data: []
};

export default DonutChart;
