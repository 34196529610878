/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TopicAutocomplete from './topicAutocomplete';
import { setSearchTopics } from '../../../searchTopics/actions';
import { setSearchId } from '../../../searchId/actions';
import {
  Logo,
  Menu,
  MenuItem,
  Button,
  UserIcon,
  DownIcon,
  Modal,
  Link
} from '../../../apex';
import '../css/header.css';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTopics = useSelector(state => state.searchTopics?.topics);
  const name = useSelector(state => state.userInfo?.name);

  const [modalVisible, setModalVisible] = useState(false);

  function logout() {
    const returnTo = window.location.origin;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const url = new URL(`http:${process.env.REACT_APP_AUTH_DOMAIN}/v2/logout`);
    url.searchParams.set('returnTo', returnTo);
    url.searchParams.set('client_id', clientId);
    window.location.href = url.toString();
  }

  function goToSavedSearches() {
    navigate('../searches');
  }

  const goToHome = () => {
    if (searchTopics.length > 0) {
      dispatch(setSearchId('default'));
      dispatch(setSearchTopics([]));
    }
    navigate('/');
  };

  return (
    <>
      <Modal
        title="Contact Us"
        width="400px"
        visible={modalVisible}
        onMaskClick={() => setModalVisible(false)}
        onCloseButtonClick={() => setModalVisible(false)}
      >
        <div className="header__modal">
          To get help or submit feedback, please reach out to us at&nbsp;
          <Link href="mailto:info@aperture.ai">info@aperture.ai</Link>.
        </div>
      </Modal>
      <header className="header__header">
        <div>
          <span className="header__desktop-logo">
            <Logo onClick={goToHome} />
          </span>
          <span className="header__mobile-logo">
            <Logo onClick={goToHome} mode="icon" />
          </span>
        </div>
        <div className="header__search-container">
          <TopicAutocomplete />
        </div>
        <div>
          <Menu
            items={
              <>
                <MenuItem onClick={goToSavedSearches}>Saved Searches</MenuItem>
                <MenuItem onClick={logout}>Sign Out</MenuItem>
                <MenuItem onClick={() => setModalVisible(true)}>
                  Contact Us
                </MenuItem>
              </>
            }
          >
            <Button type="text">
              <div className="header__user-button">
                <div className="header__user-name">{name}</div>
                <div className="header__user-button-icons">
                  <UserIcon size="24px" />
                  <DownIcon />
                </div>
              </div>
            </Button>
          </Menu>
        </div>
      </header>
    </>
  );
}

export default Header;
