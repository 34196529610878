export function getSearchId(searchId) {
  return {
    type: 'searchId/fetch',
    payload: { searchId }
  };
}

export function transitionUrlFromSearchId(searchId) {
  return {
    type: 'searchId/transitionUrl',
    payload: { id: searchId }
  };
}

export function updateSearchId({ searchId, op, path, value }) {
  const body = { op, path, value };
  return {
    type: 'searchId/update',
    payload: { searchId, body, method: 'PATCH' }
  };
}

export function setSearchId(searchId) {
  return {
    type: 'searchId/setSearchId',
    payload: { id: searchId }
  };
}

export function resetSearchIdToDefault() {
  return { type: 'searchId/reset' };
}
