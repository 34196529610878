import { combineReducers } from 'redux';
import documentReducer from './documentDisplay/reducer';
import bookmarksReducer from './bookmarks/reducer';
import filters from './filters/reducer';
import results from './resultsDisplay/reducer';
import topicAutoComplete from './topicAutoComplete/reducer';
import search from './search/reducer';
import searchId from './searchId/reducer';
import searchFilters from './searchFilters/reducer';
import searchSpaces from './searchSpaces/reducer';
import dateFilters from './dateFilters/reducer';
import searchTopics from './searchTopics/reducer';
import searchPublicationOptions from './searchPublicationOptions/reducer';
import searchProblemGroupOptions from './searchProblemGroupOptions/reducer';
import searchOrganizationOptions from './searchOrganizationOptions/reducer';
import searchApplicationOptions from './searchApplicationOptions/reducer';
import searchCharacteristicOptions from './searchCharacteristicOptions/reducer';
import topics from './topics/reducer';
import problemGroups from './problemGroups/reducer';
import resultProfile from './resultProfile/reducer';
import bubbleVisualizationReducer from './bubbleVisualization/reducer';
import bubbleVisualizationOptionsReducer from './bubbleVisualizationOptions/reducer';
import auth from './auth/reducer';
import userInfo from './userInfo/reducer';
import spaces from './spaces/reducer';

const rootReducer = combineReducers({
  bookmarksReducer,
  filters,
  documentReducer,
  problemGroups,
  resultProfile,
  results,
  topicAutoComplete,
  search,
  searchId,
  searchFilters,
  dateFilters,
  searchTopics,
  searchPublicationOptions,
  searchProblemGroupOptions,
  searchOrganizationOptions,
  searchApplicationOptions,
  searchCharacteristicOptions,
  topics,
  bubbleVisualizationReducer,
  bubbleVisualizationOptionsReducer,
  auth,
  userInfo,
  spaces,
  searchSpaces
});

export default rootReducer;
