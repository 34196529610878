import PropTypes from 'prop-types';
import React from 'react';
import './result.css';
import NewTabIcon from '../Icons/NewTabIcon';
import IconButton from '../IconButton';
import Button from '../Button';

const Result = ({
  title,
  metadata,
  children,
  onClick,
  onNewTabClick,
  onAddToSearchClick,
  addToSearchButtonText,
  className,
  ...other
}) => {
  function _onAddToSearchClick(e) {
    e.stopPropagation();
    onAddToSearchClick();
  }

  return (
    <div
      className={`result ${className}`}
      {...other}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <div>
        <div>
          <div className="result__title-container">
            <div
              className="result__title"
              dangerouslySetInnerHTML={{ __html: title?.toLowerCase() }}
            />
            {onAddToSearchClick && (
              <Button
                size="small"
                className="result__button"
                onClick={_onAddToSearchClick}
              >
                {addToSearchButtonText}
              </Button>
            )}
          </div>
          <div className="result__metadata">{metadata}</div>
          <div className="result__content">{children}</div>
        </div>
      </div>
      {onNewTabClick && (
        <IconButton onClick={onNewTabClick}>
          <NewTabIcon />
        </IconButton>
      )}
    </div>
  );
};

Result.propTypes = {
  addToSearchButtonText: PropTypes.string,
  onClick: PropTypes.func,
  onNewTabClick: PropTypes.func,
  onAddToSearchClick: PropTypes.func,
  title: PropTypes.node,
  metadata: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string
};

Result.defaultProps = {
  addToSearchButtonText: undefined,
  onClick: undefined,
  onNewTabClick: undefined,
  onAddToSearchClick: undefined,
  title: undefined,
  metadata: undefined,
  children: undefined,
  className: undefined
};

export default Result;
