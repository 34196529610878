import React from 'react';
import PropTypes from 'prop-types';

export default function CirclePlusIcon({ size, fill, ...other }) {
  return (
    <div style={{ fontSize: size }} {...other}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xlmns="http://www.w3.org/2000/svg"
        fill={fill}
      >
        <path d="M12,3.12A8.88,8.88,0,1,0,20.88,12,8.89,8.89,0,0,0,12,3.12Zm0,17A8.13,8.13,0,1,1,20.13,12,8.14,8.14,0,0,1,12,20.13Z" />
        <path d="M16.81,11.62H12.38V7.19A.38.38,0,0,0,12,6.81a.37.37,0,0,0-.37.38v4.43H7.19a.38.38,0,0,0-.38.38.37.37,0,0,0,.38.37h4.44v4.44a.37.37,0,0,0,.37.38.38.38,0,0,0,.38-.38V12.37h4.43a.37.37,0,0,0,.38-.37A.38.38,0,0,0,16.81,11.62Z" />
      </svg>
    </div>
  );
}

CirclePlusIcon.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
};

CirclePlusIcon.defaultProps = {
  size: 'inherit',
  fill: 'currentColor'
};
