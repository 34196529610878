import PropTypes from 'prop-types';
import React from 'react';
import './iconButton.css';

function IconButton({
  children,
  disabled,
  size,
  shape,
  context,
  type,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  className,
  ...other
}) {
  const classMap = {
    size: {
      small: 'icon-button--small',
      medium: 'icon-button--medium',
      large: 'icon-button--large'
    }
  };

  return (
    <button
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`icon-button ${classMap.size[size]} ${className}`}
      {...other}
    >
      {children}
    </button>
  );
}

IconButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string
};

IconButton.defaultProps = {
  children: undefined,
  disabled: false,
  size: 'large',
  type: 'button',
  onClick: undefined,
  onMouseDown: undefined,
  onMouseUp: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  onFocus: undefined,
  onBlur: undefined,
  className: undefined
};

export default IconButton;
