import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '../../../apex';

export default function Topics({ id }) {
  const topics = useSelector(state => state.resultProfile.byId?.[id]?.topics);

  if (topics === undefined) {
    return null;
  }

  const topicsSorted = [...topics].sort(function(a, b) {
    return b.count - a.count;
  });

  const topicNames = topicsSorted
    ?.map(function(organization) {
      return organization.name;
    })
    .slice(0, 25);

  const topicCounts = topicsSorted
    ?.map(function(organization) {
      return organization.count;
    })
    .slice(0, 25);

  return (
    <>
      <div className="result-profile__bar-chart-title">Topics</div>
      <div className="result-profile__bar-chart-subtitle">
        By Number of Mentions
      </div>
      <BarChart
        seriesName="# of publications"
        seriesColorPalette="status3/light"
        xAxisCategories={topicNames}
        data={topicCounts}
      />
    </>
  );
}
