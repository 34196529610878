/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';

export default function filtersReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case 'searchId/fetchFulfilled':
      case 'searchId/updateFulfilled': {
        if (action.error) {
          return nextState;
        }
        const { response } = action.payload;

        const dateFiltersOnly = (response || {}).filters?.filter(function(x) {
          return x.filter_type === 'date';
        });
        nextState.dateFilters = dateFiltersOnly || initialState.dateFilters;
        return nextState;
      }

      case 'dateFilters/add': {
        nextState.dateFilters.push({ ...action.payload.dateFilters });
        return nextState;
      }
      case 'dateFilters/reset': {
        nextState.dateFilters = [];
        return nextState;
      }
      default:
        return nextState;
    }
  });
}
