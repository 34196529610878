export default {
  byId: {
    painpoints: {
      humanKey: 'Pain Points'
    },
    // product_types: {
    //   humanKey: 'Topic Types'
    // },
    // industries: {
    //   humanKey: 'Industries'
    // },
    // microtrends: {
    //   humanKey: 'Themes'
    // },
    // megatrends: {
    //   humanKey: 'Megatrends'
    // },
    applications: {
      humanKey: 'Topics'
    },
    organizations: {
      humanKey: 'Organizations'
    },
    corpus: {
      humanKey: 'Source Types'
    }
  }
};
