import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

export const addBookmarkEpic = action$ =>
  action$.pipe(
    ofType('bookmarks/add'),
    map(({ payload }) => {
      const { searchId, name } = payload;
      return apiRequest({
        path: `/bookmarks`,
        fulfilledType: 'bookmarks/addFulfilled',
        metadata: { sourcePayload: payload },
        method: 'POST',
        body: {
          search_id: searchId,
          name
        }
      });
    })
  );

export const deleteBookmarkEpic = action$ =>
  action$.pipe(
    ofType('bookmarks/delete'),
    map(({ payload }) => {
      const { bookmarkKey } = payload;
      return apiRequest({
        path: `/bookmarks/${bookmarkKey}`,
        fulfilledType: 'bookmarks/deleteFulfilled',
        metadata: { sourcePayload: payload },
        method: 'DELETE'
      });
    })
  );

export const fetchBookmarksEpic = action$ =>
  action$.pipe(
    ofType(
      'bookmarks/fetch',
      'bookmarks/addFulfilled',
      'bookmarks/deleteFulfilled'
    ),
    map(({ payload }) => {
      return apiRequest({
        path: `/bookmarks`,
        fulfilledType: 'bookmarks/fetchFulfilled',
        metadata: { sourcePayload: payload },
        method: 'GET'
      });
    })
  );

export const fetchPaginatedBookmarksEpic = action$ =>
  action$.pipe(
    ofType('bookmarks/fetchPaginatedBookmarks'),
    map(({ payload }) => {
      const { start, size } = payload;
      return apiRequest({
        path: `/bookmarks?start=${start}&size=${size}`,
        fulfilledType: 'bookmarks/fetchPaginatedBookmarksFulfilled',
        metadata: { sourcePayload: payload },
        method: 'GET'
      });
    })
  );
