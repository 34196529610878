export default {
  viewId: 'list',
  bySearch: {
    nonPublicationSelectedResultTypeId: 'problems',
    default: {
      documents: {
        total: undefined,
        pages: {}
      },
      problems: {
        total: undefined,
        pages: {}
      },
      mentions: {
        total: undefined,
        pages: {}
      }
    }
  }
};
