import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { apiRequest } from '../api/actions';

const fetchProblemGroupEpic = action$ =>
  action$.pipe(
    ofType('problemGroups/fetch'),
    map(({ payload }) => {
      const { id, searchId } = payload;
      let path = `/problem-group/${id}`;
      path = searchId ? (path += `?search_id=${searchId}`) : path;
      return apiRequest({
        path,
        fulfilledType: 'problemGroups/fetchFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

const fetchProblemGroupsRelatedProblemsEpic = action$ =>
  action$.pipe(
    ofType('problemGroups/fetchRelatedProblemGroups'),
    map(({ payload }) => {
      const { id } = payload;
      return apiRequest({
        path: `/problem-group/${id}/related`,
        fulfilledType: 'problemGroups/fetchRelatedProblemGroupsFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

const fetchProblemGroupsDocumentsEpic = action$ =>
  action$.pipe(
    ofType('problemGroups/fetchAssociatedDocuments'),
    map(({ payload }) => {
      const { id, searchId, start, size } = payload;
      let path = `/problem-highlights/${id}?start=${start}&size=${size}`;
      path = searchId ? (path += `&search_id=${searchId}`) : path;
      return apiRequest({
        path,
        fulfilledType: 'problemGroups/fetchAssociatedDocumentsFulfilled',
        metadata: { sourcePayload: payload }
      });
    })
  );

export default combineEpics(
  fetchProblemGroupEpic,
  fetchProblemGroupsRelatedProblemsEpic,
  fetchProblemGroupsDocumentsEpic
);
