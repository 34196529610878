import PropTypes from 'prop-types';
import React from 'react';
import gif from './resources/Aperture_Load_Small.gif';
import './spinner.css';

const Spinner = ({ height, width, className, summary, ...other }) => {
  let spinReturn = (
    <div className={className} {...other}>
      <img
        src={gif}
        alt="loading indicator"
        className="logo__icon"
        height={height}
        width={width}
      />
    </div>
  );
  if (summary) {
    spinReturn = (
      <div className={className} {...other}>
        <img
          src={gif}
          alt="loading indicator"
          className="logo__icon"
          height={height}
          width={width}
        />
        <center>Generating Summary</center>
      </div>
    );
  }

  return spinReturn;
};

Spinner.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

Spinner.defaultProps = {
  height: 'auto',
  width: 'auto',
  className: 'spinner'
};

export default Spinner;
