import { Listbox } from '@headlessui/react';
import React from 'react';
import './select.css';
import Button from '../Button';
import DownIcon from '../Icons/DownIcon';

function Select({ selected, onChange, children, label, className }) {
  return (
    <div>
      <Listbox
        className={`select ${className}`}
        value={selected?.value}
        onChange={onChange}
        as="div"
      >
        <div className="select__container">
          <Listbox.Label className="select__label">{label}</Listbox.Label>
          <Listbox.Button className="select__headless-button" as="span">
            <Button type="text" className="select__button">
              <span>{selected?.name}</span>
              <DownIcon aria-hidden="true" className="select__icon" />
            </Button>
          </Listbox.Button>
          <Listbox.Options className="select__options">
            {children}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
}

export default Select;
