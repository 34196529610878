import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

function BarChart({ xAxisCategories, data, seriesName, seriesColorPalette }) {
  const colorMap = {
    'status1/light': '#C6C9F8',
    'status3/light': '#C6C9F8'
  };

  const options = {
    chart: {
      type: 'bar',
      marginTop: 0,
      marginRight: 4,
      spacingLeft: 1,
      spacingTop: 1,
      spacingBottom: 0,
      spacingRight: 2,
      height: data.length === 1 ? 40 : data.length * 30
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      gridLineColor: 'red',

      lineColor: '#B2B1B1',
      lineWidth: 1,
      opposite: false,
      categories: xAxisCategories,
      title: {
        text: null
      },
      labels: {
        enabled: true,
        // x: 31
        style: {
          textOverflow: 'ellipsis',
          width: '100%',
          color: '#000000',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '10px'
        }
      }
    },
    yAxis: {
      // eslint-disable-next-line prefer-spread
      // max: Math.max.apply(Math, data),
      margin: 10,
      lineColor: '#FF0000',
      lineWidth: 0,
      endOnTick: false,
      gridLineColor: 'red',
      gridLineWidth: 0,
      tickAmount: 0,
      tickWidth: 0,
      tickColor: 'transparent',
      title: {
        text: null
      },
      labels: {
        enabled: false
      }
    },
    tooltip: {
      useHTML: true,
      zIndex: 20,
      headerFormat: `<div>`,
      shadow: true,
      backgroundColor: '#FFFFFF',
      borderRadius: 0,
      borderWidth: 0,
      pointFormat: `
      <div><span style='color:#000000; font:12px Poppins'>{point.category}</span>: <span style='color:#80868C; font:12px Poppins'>{point.y}</span></div>
      `,
      footerFormat: '</div>'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        },
        events: {
          legendItemClick() {
            return false;
          }
        }
      },
      allowPointSelect: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        states: {
          hover: {
            enabled: false
          }
        },
        color: colorMap[seriesColorPalette],
        borderWidth: 2,
        borderColor: '#BFBED7',
        name: seriesName,
        data,
        pointWidth: 20
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: '100%' } }}
      options={options}
    />
  );
}

BarChart.propTypes = {
  /**
   * The x-axis categories for the chart
   */
  xAxisCategories: PropTypes.instanceOf(Array),
  /**
   * The data for the chart
   */
  data: PropTypes.instanceOf(Array),
  /**
   * The display name for the series of data being displayed
   */
  seriesName: PropTypes.string,
  /**
   * The color schema of the chart
   */
  seriesColorPalette: PropTypes.oneOf(['status1/light', 'status3/light'])
};

BarChart.defaultProps = {
  seriesName: 'Series 1',
  seriesColorPalette: 'status1/light',
  data: [],
  xAxisCategories: []
};

export default BarChart;
